import { Box, Button, Flex, Text } from 'theme-ui';
import { Icon } from '@tmi/component-library';
import { reducer, useFilterData } from '../../../../providers/filter';
import { filter } from '../../../../helpers/filter/filter';
import { useGetWhiteboard } from '../../../../hooks';

interface FilterCategoryProps {
    title: string;
    options: QueryEntry<string | number>[];
    isOpen: boolean;
    onButtonClick: (category: Category) => void;
    value: QueryEntry<string | number>[];
    onFilterClick: (entry: QueryEntry<string | number>) => void;
    category: Category;
}

export const FilterCategory = ({
    title,
    options,
    isOpen,
    onButtonClick,
    value,
    onFilterClick,
    category,
}: FilterCategoryProps) => {
    const { data } = useGetWhiteboard();
    const { filterState } = useFilterData();

    if (!data) return null;

    return (
        <Box>
            <Button
                onClick={() => onButtonClick(category)}
                variant="invisible"
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: 'textPrimary',
                    p: 4,
                }}
            >
                <Text variant="labelMd">{title}</Text>
                <Box
                    sx={{
                        ...(isOpen
                            ? {
                                  transform: 'rotate(180deg)',
                              }
                            : {}),
                    }}
                >
                    <Icon iconName="chevronDown" fillStyle="solid" size={16} />
                </Box>
            </Button>
            {isOpen && (
                <Flex sx={{ flexWrap: 'wrap', px: 4, pb: 4, gap: 2 }}>
                    {options.map(option => {
                        const allItems = data.items ?? [];

                        const filterInput = reducer(filterState, {
                            type: category as Category,
                            payload: option,
                        });

                        const willYieldResult =
                            filter(allItems, filterInput).length > 0;

                        return (
                            <FilterOption
                                key={option.value}
                                isActive={value.some(
                                    ({ value: innerValue }) =>
                                        innerValue === option.value
                                )}
                                willYieldResult={willYieldResult}
                                onClick={() => onFilterClick(option)}
                            >
                                {option.label}
                            </FilterOption>
                        );
                    })}
                </Flex>
            )}
        </Box>
    );
};

interface FilterOptionProps {
    children: string;
    onClick: () => void;
    isActive: boolean;
    willYieldResult: boolean;
}

const FilterOption = ({
    children,
    onClick,
    isActive,
    willYieldResult,
}: FilterOptionProps) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                px: 2,
                pt: 1,
                pb: '6px',
                borderRadius: 2,
                border: 1,
                cursor: 'pointer',
                ...(isActive
                    ? {
                          bg: 'actionPrimary',
                          borderColor: 'actionPrimary',
                      }
                    : {
                          borderColor: 'bgPrimary',
                          opacity: willYieldResult ? 1 : 0.3,
                      }),
            }}
        >
            <Text
                variant="labelSm"
                sx={{
                    whiteSpace: 'nowrap',
                    color: 'actionTertiaryHighlight',
                    ...(isActive ? { color: 'white' } : {}),
                }}
            >
                {children}
            </Text>
        </Box>
    );
};
