import axios from 'axios';

import {
    API_BASE_URL,
    API_ROUTE_WHITEBOARD,
    API_ROUTE_ITEM,
    API_ROUTE_TEAM,
} from '../constants/api';

export const getWhiteboard = ({
    whiteboardKey,
}: {
    whiteboardKey: string;
}): Promise<IWhiteboard> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_WHITEBOARD}/${whiteboardKey}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getTeamForWhiteboard = ({
    whiteboardKey,
}: {
    whiteboardKey: string;
}): Promise<ITeam> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_WHITEBOARD}/${whiteboardKey}/${API_ROUTE_TEAM}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const createItemInstance = ({
    item,
    whiteboardKey,
}: {
    item: Partial<IItemInstance>;
    whiteboardKey: string;
}): Promise<IItemInstance> => {
    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_ITEM}/instance`, {
            item,
            whiteboardKey,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const deleteConnectionEdge = ({
    key,
    whiteboardKey,
}: {
    key: string;
    whiteboardKey: string;
}): Promise<IEdge> => {
    return axios
        .delete(
            `${API_BASE_URL}/${API_ROUTE_ITEM}/instance/connection/${key}/${whiteboardKey}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};
