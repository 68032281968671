import { Button } from 'theme-ui';
import { Icon, IconName } from '@tmi/component-library';
import Tippy from '@tippyjs/react/headless';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { TooltipLabel } from '../../../components/tooltip-label';
import { useSidebarData } from '../../../providers/sidebar';

interface ToolbarButtonProps {
    icon: IconName;
    onClick: () => void;
    tooltip: string;
    sx?: ThemeUIStyleObject;
    dataTour?: string;
    cardType: CardType;
}

export const ToolbarButton = ({
    icon,
    cardType,
    onClick,
    tooltip,
    sx,
    dataTour,
}: ToolbarButtonProps) => {
    const { currentlyActiveCard } = useSidebarData();

    return (
        <Tippy
            render={attrs => <TooltipLabel {...attrs}>{tooltip}</TooltipLabel>}
        >
            <Button
                variant="icon"
                data-tour={dataTour}
                onClick={onClick}
                sx={{
                    px: 3,
                    py: 1,
                    backgroundColor:
                        currentlyActiveCard &&
                        currentlyActiveCard.type === cardType
                            ? 'actionPrimarySubtle'
                            : 'actionSecondary',
                    ':hover:enabled': {
                        backgroundColor:
                            currentlyActiveCard &&
                            currentlyActiveCard.type === cardType
                                ? 'actionPrimary'
                                : 'actionSecondaryIntense',
                    },
                    ...sx,
                }}
            >
                <Icon iconName={icon} fillStyle="solid" size={32} />
            </Button>
        </Tippy>
    );
};
