import { Theme } from 'theme-ui';

export const statusPickerStyles = (theme: Theme) => ({
    option: base => ({
        ...base,
        padding: `${theme!.space![1]} ${theme!.space![3]}`,
    }),
    container: base => ({
        ...base,
        width: '185px',
    }),
});
