export enum DateOptions {
    OVERDUE = 'overdue',
    TODAY = 'today',
    THIS_WEEK = 'thisWeek',
    THIS_MONTH = 'thisMonth',
    LONG_TERM = 'longTerm',
}

export const filterCategories: { [key: string]: Category } = {
    CARD_TYPE: 'cardType',
    ASSIGNEES: 'assignees',
    DUE_DATE: 'dueDate',
    TAGS: 'tags',
    STATUS: 'status',
};

export const initialState: FilterStateType = {
    cardType: {
        operation: 'and',
        query: [],
    },
    assignees: {
        operation: 'and',
        query: [],
    },
    dueDate: {
        operation: 'and',
        query: [],
    },
    tags: {
        operation: 'and',
        query: [],
    },
    status: {
        operation: 'and',
        query: [],
    },
};

const cardTypes = ['goal', 'result', 'action', 'behaviour', 'value'];

export const dueDateOptions: QueryEntry<DateOptions>[] = [
    {
        label: 'Overdue',
        value: DateOptions.OVERDUE,
    },
    {
        label: 'Today',
        value: DateOptions.TODAY,
    },
    {
        label: 'This week',
        value: DateOptions.THIS_WEEK,
    },
    {
        label: 'This month',
        value: DateOptions.THIS_MONTH,
    },
    {
        label: 'Long term',
        value: DateOptions.LONG_TERM,
    },
];

export const cardTypeOptions: QueryEntry<string>[] = cardTypes.map(
    cardType => ({
        value: cardType,
        label: cardType[0].toUpperCase() + cardType.slice(1),
    })
);
