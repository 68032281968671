import { Icon } from '@tmi/component-library/src';
import React from 'react';
import { Flex, IconButton } from 'theme-ui';
import { Tag } from './tag';

interface TagRowProps {
    tagKey: string;
    tagName: string;
    onDelete: (tagId: string) => void;
}

export const TagRow: React.FC<TagRowProps> = ({
    tagKey,
    tagName,
    onDelete,
}) => {
    const handleDeleteTag = event => {
        event.preventDefault();
        event.stopPropagation();
        onDelete(tagKey);
    };
    return (
        <Flex
            sx={{ position: 'relative', width: '100%', alignItems: 'center' }}
        >
            <Tag tag={tagName} />
            <IconButton
                onClick={handleDeleteTag}
                sx={{
                    ml: 'auto',
                    borderRadius: 1,
                    height: 20,
                    width: 20,
                }}
            >
                <Icon iconName="trash" size={16} />
            </IconButton>
        </Flex>
    );
};
