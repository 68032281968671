import { useDndMonitor, useDraggable } from '@dnd-kit/core';
import { Flex, Box } from '@theme-ui/components';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { useCallback, useEffect, useState, useRef, useContext } from 'react';
import {
    checkInSessionEdit,
    checkInSessionIntro,
    checkInSessionView,
} from '../../../../helpers';
import { useSidebarData } from '../../../../providers/sidebar';
import { MIN_FRAME_SIZE } from '../../constants/general';
import { WhiteboardContext } from '../../containers/whiteboard.container';

interface Props {
    frame: ISessionFrame;
    setFrame: (frame: ISessionFrame) => void;
    scale: number;
    number?: number;
}

const cornerOuterSx: ThemeUIStyleObject = {
    position: 'absolute',
    height: '8px',
    width: '8px',
    bg: 'actionPrimary',
};

const cornerInnerSx: ThemeUIStyleObject = {
    content: "''",
    position: 'absolute',
    bg: 'actionPrimary',
};

const cornerSx: ThemeUIStyleObject = {
    ...cornerOuterSx,
    '::before': {
        ...cornerInnerSx,
        height: '8px',
        width: '16px',
        top: 0,
        left: '50%',
    },
    '::after': {
        ...cornerInnerSx,
        height: '16px',
        width: '8px',
        top: '50%',
        left: 0,
    },
};

export const Frame = ({ frame, setFrame, scale, number }: Props) => {
    const { isSidebarOpen } = useSidebarData();
    const isInViewMode = checkInSessionView(window.location.pathname);
    const isInEditMode = checkInSessionEdit(window.location.pathname);
    const isInIntroMode = checkInSessionIntro(window.location.pathname);

    const { dragDisabled, setPanPinchDisabled } = useContext(WhiteboardContext);

    const [initialMousePosition, setInitialMousePosition] = useState<
        ICoordinates
    >({ x: frame.x, y: frame.y });
    const [directionNormalize, setDirectionNormalize] = useState<any>({
        x: 0,
        y: 0,
    });
    const [tempFrame, setTempFrame] = useState<Optional<
        ISessionFrame,
        'id'
    > | null>(frame);
    const frameZoom = useRef<number>(1);

    const { transform, attributes, listeners, setNodeRef } = useDraggable({
        id: frame.id,
        disabled: !!tempFrame || dragDisabled,
    });

    useEffect(() => {
        const { innerWidth, innerHeight } = window;
        const { width, height } = frame;

        const widthScale = (innerWidth * 2) / 3 / width;
        const heightScale = innerHeight / height;
        const newScale = Math.min(widthScale, heightScale);

        frameZoom.current = newScale;
    }, [frame]);

    useDndMonitor({
        onDragEnd: ({ delta, active }) => {
            if (active.id === frame.id) {
                setFrame({
                    ...frame,
                    x: frame.x + delta.x / scale,
                    y: frame.y + delta.y / scale,
                });

                setTempFrame(null);
            }
        },
    });

    const handleMouseDown = (
        { clientX, clientY },
        currentDirectionNormalize
    ) => {
        setPanPinchDisabled(true);
        setInitialMousePosition({ x: clientX, y: clientY });
        setDirectionNormalize(currentDirectionNormalize);
        setTempFrame(frame);
    };

    const handleMouseMove = useCallback(
        ({ clientX: x, clientY: y }) => {
            if (!tempFrame) return;

            const mouseOffsetX =
                (x - initialMousePosition.x) * directionNormalize.x;
            const mouseOffsetY =
                (y - initialMousePosition.y) * directionNormalize.y;

            const { width, height } = frame;

            const newX =
                directionNormalize.x < 0
                    ? Math.min(
                          frame.x - mouseOffsetX / scale,
                          frame.x + width - MIN_FRAME_SIZE
                      )
                    : frame.x;
            const newY =
                directionNormalize.y < 0
                    ? Math.min(
                          frame.y - mouseOffsetY / scale,
                          frame.y + height - MIN_FRAME_SIZE
                      )
                    : frame.y;

            const newWidth = Math.max(
                width + mouseOffsetX / scale,
                MIN_FRAME_SIZE
            );
            const newHeight = Math.max(
                height + mouseOffsetY / scale,
                MIN_FRAME_SIZE
            );

            setTempFrame({
                x: newX,
                y: newY,
                width: newWidth,
                height: newHeight,
            });
        },
        [
            tempFrame,
            initialMousePosition.x,
            initialMousePosition.y,
            directionNormalize.x,
            directionNormalize.y,
            frame,
            scale,
        ]
    );

    const handleMouseUp = useCallback(() => {
        if (!tempFrame) return;
        setPanPinchDisabled(false);
        setFrame({ ...frame, ...tempFrame });
        setTempFrame(null);
    }, [setFrame, frame, tempFrame, setPanPinchDisabled]);

    useEffect(() => {
        if (tempFrame) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseMove, handleMouseUp, tempFrame]);

    const pos = {
        x: (transform?.x ?? 0) / scale,
        y: (transform?.y ?? 0) / scale,
    };

    const visibleFrameStyle: ThemeUIStyleObject = {
        border: '1px solid',
        borderColor: 'actionPrimary',
        // boxShadow: isActive ? '0 0 0 10000px rgba(0, 0, 0, 0.3)' : 'none',
    };

    const invisibleFrameStyle: ThemeUIStyleObject = {
        pointerEvents: 'none',
    };

    const pointerEventEdit: ThemeUIStyleObject = {
        cursor: 'grab',
    };

    const pointerEvent = isInEditMode ? pointerEventEdit : invisibleFrameStyle;

    const style: ThemeUIStyleObject =
        isInEditMode || isInIntroMode ? visibleFrameStyle : invisibleFrameStyle;

    const handlers = isInEditMode && {
        ...attributes,
        ...listeners,
    };

    return (
        <Box
            {...handlers}
            id={isSidebarOpen ? undefined : frame.id}
            ref={setNodeRef}
            sx={{
                ...pointerEvent,
                ...style,
                position: 'absolute',
                width: tempFrame?.width || frame.width,
                height: tempFrame?.height || frame.height,
                left: tempFrame?.x || frame.x,
                top: tempFrame?.y || frame.y,
                transform: `translate3d(${pos.x}px, ${pos.y}px, 0)`,
            }}
        >
            {!isInViewMode && number && (
                <Flex
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '30px',
                        height: '30px',
                        bg: 'actionPrimary',
                        color: 'white',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: 0,
                        left: '50%',

                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    {number}
                </Flex>
            )}
            {isSidebarOpen ? (
                <Box
                    id={frame.id}
                    sx={{
                        position: 'absolute',
                        width: `calc(${100 / 3 / frameZoom.current}vw + ${
                            frame.width
                        }px)`,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        pointerEvents: 'none',
                    }}
                />
            ) : null}

            {!isInViewMode && (
                <>
                    <Corner
                        directionNormalize={{ x: -1, y: -1 }}
                        handleMouseDown={handleMouseDown}
                        sx={{
                            left: 0,
                            top: 0,
                            transform: 'translate(-50%, -50%)',
                            cursor: 'nw-resize',
                        }}
                    />
                    <Corner
                        directionNormalize={{ x: 1, y: -1 }}
                        handleMouseDown={handleMouseDown}
                        sx={{
                            right: 0,
                            top: 0,
                            transform: 'translate(50%, -50%) rotate(90deg)',
                            cursor: 'ne-resize',
                        }}
                    />
                    <Corner
                        directionNormalize={{ x: -1, y: 1 }}
                        handleMouseDown={handleMouseDown}
                        sx={{
                            left: 0,
                            bottom: 0,
                            transform: 'translate(-50%, 50%) rotate(270deg)',
                            cursor: 'sw-resize',
                        }}
                    />
                    <Corner
                        directionNormalize={{ x: 1, y: 1 }}
                        handleMouseDown={handleMouseDown}
                        sx={{
                            right: 0,
                            bottom: 0,
                            transform: 'translate(50%, 50%) rotate(180deg)',
                            cursor: 'se-resize',
                        }}
                    />
                </>
            )}
        </Box>
    );
};

interface CornerProps {
    sx?: ThemeUIStyleObject;
    handleMouseDown: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        directionNormalize: { x: number; y: number }
    ) => void;
    directionNormalize: { x: number; y: number };
}

const Corner = ({ sx, handleMouseDown, directionNormalize }: CornerProps) => (
    <Box
        onMouseDownCapture={e => {
            handleMouseDown(e, directionNormalize);
        }}
        sx={{ ...cornerSx, ...sx }}
    />
);
