import React, {
    Dispatch,
    useContext,
    useRef,
    useState,
    useCallback,
    useEffect,
} from 'react';
import type { MutableRefObject } from "react";
import { clamp } from 'lodash';
import { useTransformContext } from '@tmi/react-zoom-pan-pinch';
import { Box } from '@theme-ui/components';

import { CARD_META, SCALE_BOUNDARIES } from '../../constants/card';
import {  setScale, setTempScale } from './state/reducer';

import { useConnectionAPI, useConnectionData } from '../../../../providers/connection';

import { ConnectionHandles } from './connection';
import { ResizeHandles } from './resize';
import { useOutsideClick } from 'rooks';
import { CardMode } from '../../../../types/enums';
import { WhiteboardContext } from '../../containers/whiteboard.container';
import { DraggableItem } from '../../../../components';
import { useUpdateItemInstance } from '../../../../api/item';

type DirectionNormalize = { x: number; y: number };
type Coordinates = { x: number; y: number };
const initialCoordinates: Coordinates = { x: 0, y: 0 };

interface EditOutlineProps {
    editOutlineRef:  MutableRefObject<HTMLDivElement>;
    canvasRef: MutableRefObject<HTMLElement | null>;
    children: React.ReactElement;
    cardMode: CardMode;
    handleDoubleClick: () => void;
    handleClickOutside: () => void;
    itemInstance: IItemInstance;
    isLocked: boolean;
    dispatch: Dispatch<any>;
}



/**
 *  useOutsideClick hook
 * Checks if a click happened outside a Ref. Handy for dropdowns, modals and popups etc.
 *
 * @param ref Ref whose outside click needs to be listened to
 * @param handler Callback to fire on outside click
 * @param when A boolean which which activates the hook only when it is true. Useful for conditionally enable the outside click
 */
function useOutsideConstrainedClick(
    ref: MutableRefObject<HTMLDivElement>,
    handler: (e: MouseEvent) => any,
    when: boolean = true,
    constraintRef: MutableRefObject<HTMLElement | null>,
): void {
  const savedHandler = useRef(handler);

  const memoizedCallback = useCallback((e: MouseEvent) => {
    if (ref && ref.current && !ref.current.contains(e.target as Element) && constraintRef && constraintRef.current && constraintRef.current.contains(e.target as Element)) {
      savedHandler.current(e);
    }
  }, [constraintRef, ref]);

  useEffect(() => {
    savedHandler.current = handler;
  },[handler]);

  useEffect(() => {


    if (when) {

        document.addEventListener("mousedown", memoizedCallback, true);
            //   document.addEventListener("ontouchstart", memoizedCallback, true);


      return () => {
        document.removeEventListener("mousedown", memoizedCallback, true);
        // document.removeEventListener("ontouchstart", memoizedCallback, true);
      };
    }
  }, [ref, handler, when, constraintRef, memoizedCallback]);
}

export { useOutsideClick };

export const EditOutline =  ({
    children,
    cardMode,
    handleDoubleClick,
    handleClickOutside,
    itemInstance,
    isLocked,
    dispatch,
    canvasRef,
   editOutlineRef
}: EditOutlineProps) => {
    const isActive = cardMode === CardMode.LAYOUT;
        const {
        state: { scale },
    } = useTransformContext();
    const { setDragDisabled,dragDisabled } = useContext(WhiteboardContext);
    const { setInitialRef } = useConnectionAPI();
    const { initialRef} = useConnectionData();
    const [initialCardScale, setInitialCardScale] = useState<number>(
        itemInstance.cardScale
    );
    const [initialMousePosition, setInitialMousePosition] = useState<
        Coordinates
    >(initialCoordinates);
    const [scalingComponent, setScalingComponent] = useState<string | null>(
        null
    );
    const [directionNormalize, setDirectionNormalize] = useState<
        DirectionNormalize
    >({ x: 0, y: 0 });

    useOutsideConstrainedClick(
        editOutlineRef,
        handleClickOutside,
        cardMode !== CardMode.VIEW,
        canvasRef
    );

    const { mutate } = useUpdateItemInstance({_id:itemInstance._id});

    const handleMouseDown = ({
        clientX: x,
        clientY: y,
        currentDirectionNormalize,
    }) => {
       
        setInitialMousePosition({ x, y });
        setDirectionNormalize(currentDirectionNormalize);
        setScalingComponent(itemInstance._id);
        setInitialCardScale(itemInstance.cardScale);
    };

    const handleMouseMove = useCallback(
        ({ clientX: x, clientY: y }) => {
            if (!scalingComponent) return;

            const mouseOffsetX =
                (x - initialMousePosition.x) * directionNormalize.x;
            const mouseOffsetY =
                (y - initialMousePosition.y) * directionNormalize.y;

            const initialWidth = CARD_META[itemInstance.type].width;
            const initialHeight = CARD_META[itemInstance.type].height;

            const width = initialWidth * initialCardScale;
            const height = initialHeight * initialCardScale;

            const scaleX = (width + mouseOffsetX / scale) / width;
            const scaleY = (height + mouseOffsetY / scale) / height;

            const smallestScaleDelta = Math.min(scaleX, scaleY);
            const newScale = clamp(
                Math.max(initialCardScale * smallestScaleDelta),
                SCALE_BOUNDARIES.min,
                SCALE_BOUNDARIES.max
            );

            const minTranslateX =
                (initialCardScale - SCALE_BOUNDARIES.max) * initialWidth;
            const maxTranslateX =
                (initialCardScale - SCALE_BOUNDARIES.min) * initialWidth;
            const minTranslateY =
                (initialCardScale - SCALE_BOUNDARIES.max) * initialHeight;
            const maxTranslateY =
                (initialCardScale - SCALE_BOUNDARIES.min) * initialHeight;

            const tempTranslate = {
                x:
                    directionNormalize.x < 0
                        ? clamp(
                              -smallestScaleDelta * width + width,
                              minTranslateX,
                              maxTranslateX
                          )
                        : 0,
                y:
                    directionNormalize.y < 0
                        ? clamp(
                              -smallestScaleDelta * height + height,
                              minTranslateY,
                              maxTranslateY
                          )
                        : 0,
            };

            const payload = {
                cardScale: newScale,
                tempTranslate,
            };
           
            dispatch(setTempScale(payload));
        },
        [
            scalingComponent,
            initialMousePosition.x,
            initialMousePosition.y,
            directionNormalize.x,
            directionNormalize.y,
            itemInstance.type,
            initialCardScale,
            dispatch,
            scale,
        ]
    );

    const handleScalingComponentMouseUp = useCallback(() => {
        setInitialMousePosition({ x: 0, y: 0 });

        setScalingComponent(null);
        setDragDisabled(false);


        const updatedCoordinates = {
            x: itemInstance.position.x + (itemInstance?.tempTranslate?.x ?? 0),
            y: itemInstance.position.y + (itemInstance?.tempTranslate?.y ?? 0),
        };

        const payload = {
            cardScale: itemInstance.cardScale,
            position: updatedCoordinates,
        };

           mutate({...payload} )

      

        dispatch(setScale(payload));
    }, [
        dispatch,
        mutate,
        itemInstance.cardScale,
        itemInstance.tempTranslate,
        setDragDisabled,
        itemInstance.position.x,
        itemInstance.position.y,
    ]);

    const handleConnectionMouseUp = useCallback(() => {
        // When releasing the drag on the whiteboard, void the currently drawn connection
        setInitialRef(null);
    }, [setInitialRef]);

    const handleWhiteboardMouseUp = useCallback(() => {
        if (scalingComponent) {
            handleScalingComponentMouseUp();
        }

        if (initialRef) {
            handleConnectionMouseUp();
        }

        return;
    }, [
        scalingComponent,
        initialRef,
        handleScalingComponentMouseUp,
        handleConnectionMouseUp,
    ]);

    useEffect(() => {
        if (!initialRef) {
            setDragDisabled(false);
        }
    }, [initialRef, setDragDisabled]);

    useEffect(() => {
        if (!isActive) return;

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleWhiteboardMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleWhiteboardMouseUp);
        };
    }, [handleWhiteboardMouseUp, handleMouseMove, isActive]);


    const tempX = itemInstance.tempTranslate?.x
    const tempY = itemInstance.tempTranslate?.y


    return (
        <DraggableItem
            dispatch={dispatch}
            state={itemInstance}
            disabled={
                dragDisabled ||
                itemInstance.cardMode === CardMode.LOCKED ||
                itemInstance.cardMode === CardMode.EDIT
            }
        >
        <Box
            sx={
                {
            position: 'relative',
            pointerEvents: isActive && !isLocked ? 'auto' : 'none',
            transform: `translate(${tempX ||
                0}px, ${tempY || 0}px)`,
    }
            }
            onDoubleClick={handleDoubleClick}
        >
            {isActive ? (
                <>
                    <Box
                        sx={{
                            position: 'absolute',
                            left: -2,
                            right: -2,
                            top: -2,
                            bottom: -2,
                            outline: '1px solid',
                            outlineColor: 'actionPrimary',
                        }}
                    >
                        <ResizeHandles
                            handleMouseDown={handleMouseDown}
                            whiteboardsScale={scale}
                        />
                        <ConnectionHandles
                            initialRef={editOutlineRef}
                            scale={itemInstance.cardScale}
                            whiteboardsScale={scale}
                        />
                    </Box>
                </>
            ) : null}
            {children}
        </Box></DraggableItem>
    );
};
