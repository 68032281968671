import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Flex, Box, Input } from '@theme-ui/components';
import { useKey } from 'rooks';
import { DeleteElementModal } from '../../../delete-element-modal';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ThemeUIStyleObject } from 'theme-ui';

interface FrameRowProps {
    frame: ISessionFrame;
    frames: ISessionFrame[];
    index: number;
    onClick: () => void;
    isActive: boolean;
    mutate: any;
    onFocus: boolean;
    setOnFocus: Dispatch<SetStateAction<boolean>>;
    sx?: ThemeUIStyleObject;
}

export const FrameRow = ({
    frame,
    frames,
    index,
    onClick,
    isActive,
    mutate,
    onFocus,
    setOnFocus,
    sx,
}: FrameRowProps) => {
    const [title, setTitle] = useState(frame.title ?? '');
    const [showDeleteItemModal, setShowDelete] = useState(false);

    const handleCloseModal = useCallback(() => {
        setShowDelete(false);
    }, []);

    const handleUpdate = () => {
        const newFrames = [...frames];

        const newFrame = { ...frame };

        newFrame.title = title;

        newFrames[index] = newFrame;

        mutate({
            frames: newFrames,
        });
    };

    const handleDeleteFrame = useCallback(
        () => {
            mutate({
                frames: frames.filter(({ id }) => frame.id !== id),
            });
        },
        [frames, mutate] // eslint-disable-line
    );

    useKey(
        ['Backspace', 'Delete'],
        () => {
            setShowDelete(true);
        },

        {
            when: isActive && !onFocus,
        }
    );

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: frame.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    } as React.CSSProperties;

    return (
        <Flex
            ref={setNodeRef}
            style={style}
            {...attributes}
            sx={{
                border: `1px solid`,
                borderRadius: 2,
                borderColor: isActive ? 'actionPrimary' : 'actionSecondary',
                bg: 'bgSecondary',
                p: 4,
                my: 4,
                ...sx,
            }}
        >
            <Flex
                {...listeners}
                onClick={onClick}
                sx={{
                    cursor: 'pointer',
                    alignItems: 'flex-start',
                    borderRadius: 2,
                    width: '101px',
                    height: '67px',
                    bg: 'bgPrimary',
                    mr: 4,
                }}
            >
                <Box
                    sx={{
                        borderRadius: 1,
                        m: 2,
                        bg: 'actionTertiary',
                        px: 2,
                        py: 1,
                        color: 'white',
                    }}
                >
                    {index + 1}
                </Box>
            </Flex>
            <Input
                onFocus={() => {
                    setOnFocus(true);
                }}
                placeholder="Add a title"
                value={title}
                onChange={({ target }) => {
                    setTitle(target.value);
                }}
                onBlur={() => {
                    handleUpdate();
                    setOnFocus(false);
                }}
                sx={{
                    '&:hover:enabled': { boxShadow: 'none' },
                    '&:focus:enabled': { boxShadow: 'none' },
                    '&:active:enabled': { boxShadow: 'none' },
                }}
            />

            {showDeleteItemModal && (
                <DeleteElementModal
                    handleClose={handleCloseModal}
                    handleDelete={() => {
                        handleCloseModal();
                        handleDeleteFrame();
                    }}
                    type="frame"
                />
            )}
        </Flex>
    );
};
