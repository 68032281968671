import React from 'react';
import { IndividialIconProps } from '../Icon';

export const MenuClose = ({ fillStyle }: IndividialIconProps) => {
    const Fill = (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.6922 4.8008c-.7137 0-1.2923.5372-1.2923 1.2 0 .6627.5786 1.2 1.2923 1.2h15.4154c.7137 0 1.2923-.5373 1.2923-1.2 0-.6628-.5786-1.2-1.2923-1.2H3.6922ZM3.6922 10.8008c-.7137 0-1.2923.5372-1.2923 1.2 0 .6627.5786 1.2 1.2923 1.2h7.0154c.7137 0 1.2923-.5373 1.2923-1.2 0-.6628-.5786-1.2-1.2923-1.2H3.6922ZM3.6922 16.8008c-.7137 0-1.2923.5372-1.2923 1.2 0 .6627.5786 1.2 1.2923 1.2h5.8154c.7137 0 1.2923-.5373 1.2923-1.2 0-.6628-.5786-1.2-1.2923-1.2H3.6922ZM16.2735 10.7891c-.6408-.6569-1.589-.7499-2.1178-.2077-.5289.5422-.4382 1.5144.2025 2.1713l6.2982 6.4572c.6408.657 1.5889.7499 2.1178.2077.5289-.5422.4382-1.5143-.2026-2.1713l-6.2981-6.4572Z" />
            <path d="M22.5722 12.7539c.6408-.6569.7315-1.629.2026-2.1712-.5289-.5423-1.477-.4493-2.1178.2076l-6.2982 6.4573c-.6407.6569-.7314 1.629-.2025 2.1713.5289.5422 1.477.4492 2.1178-.2077l6.2981-6.4573Z" />
        </svg>
    );

    return Fill;
};
