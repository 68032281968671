import { ThemeUIStyleObject } from 'theme-ui';
import { CARD_META } from '../constants/card';

export const getEditOutlineStyle = (
    cardType: CardType,
    isEditing: boolean
): ThemeUIStyleObject =>
    isEditing
        ? {
              '::after': {
                  borderRadius: CARD_META[cardType].outlineRadius,
                  content: '""',
                  position: 'absolute',
                  border: 1,
                  borderColor: 'textSecondary',
                  top: -4,
                  left: -4,
                  right: -4,
                  bottom: -4,
                  pointerEvents: 'none',
                  zIndex: -1,
              },
          }
        : {};

export const getLockedOverlayStyle = (
    cardType: CardType,
    isLocked: boolean
): ThemeUIStyleObject =>
    isLocked
        ? {
              '::before': {
                  borderRadius: CARD_META[cardType].outlineRadius,
                  content: '""',
                  bg: 'bgInverse',
                  opacity: 0.3,
                  position: 'absolute',
                  top: -6,
                  left: -6,
                  right: -6,
                  bottom: -6,
                  zIndex: 30,
              },
          }
        : {};
