import { throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useAppSelector, useWhiteboardParams } from '.';
import { getUserForActiveOrg } from '../redux/reducers';

export const useCursors = () => {
    const user = useAppSelector(getUserForActiveOrg);
    const { whiteboardKey } = useWhiteboardParams();

    const socketUrl = `${
        process.env.REACT_APP_API_SOCKET_URL
    }/cursors?whiteboardId=${whiteboardKey + 'cursors'}&userId=${user._id}`;

    const [cursors, setCursors] = useState<Cursor[]>([]);

    const throttled = useMemo(() => throttle(setCursors, 100), []);

    const { lastJsonMessage, sendJsonMessage } = useWebSocket(socketUrl, {
        share: true,
        shouldReconnect: () => true,
    });

    const handleSendCursors = useCallback(
        (input: ICoordinates) => {
            const userData = {
                fullName: user.fullName,
                profilePicture: user.profilePicture,
                email: user.email,
                _id: user._id,
            };
            sendJsonMessage({
                data: {
                    ...input,
                    ...userData,
                    pathname: window.location.pathname,
                },
            });
        },
        [
            user._id,
            user.email,
            user.fullName,
            user.profilePicture,
            sendJsonMessage,
        ]
    );

    useEffect(() => {
        if (lastJsonMessage !== null) {
            throttled(lastJsonMessage?.data ?? []);
        }
    }, [lastJsonMessage, throttled]);

    return {
        cursors,
        handleSendCursors,
    };
};
