import { MutableRefObject, useContext } from 'react';
import { Box } from '@theme-ui/components';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { useConnectionAPI } from '../../../../providers/connection';
import { WhiteboardContext } from '../../containers/whiteboard.container';

interface ConnectionHandleProps {
    sx?: ThemeUIStyleObject;
    initialRef: MutableRefObject<HTMLDivElement>;
    whiteboardsScale: number;
    translate: string;
}

const ConnectionHandle = ({
    sx,
    initialRef,
    whiteboardsScale,
    translate,
}: ConnectionHandleProps) => {
    const { setInitialRef } = useConnectionAPI();
    const { setDragDisabled } = useContext(WhiteboardContext);

    const disableDragging = () => {
        setDragDisabled(true);
    };

    const smartScale = whiteboardsScale < 1 ? 1 / whiteboardsScale : 1;

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    height: 8,
                    width: 8,
                    borderRadius: 5,
                    bg: 'actionPrimary',
                    cursor: 'pointer',
                    transform: `${translate} scale(${smartScale})`,
                    ...sx,
                }}
                onMouseDownCapture={disableDragging}
                onMouseDown={() => {
                    setInitialRef(initialRef);
                }}
            />
        </>
    );
};

interface ConnectionHandlesProps {
    initialRef: MutableRefObject<HTMLDivElement>;
    scale: number;
    whiteboardsScale: number;
}

const positions = (scale: number) => {
    const distance = 24; //Math.round(24 * scale);
    return [
        {
            key: 'top',
            top: `-${distance}px`,
            left: '50%',
            translate: 'translateX(-50%)',
        },
        {
            key: 'left',
            top: '50%',
            left: `-${distance}px`,
            translate: 'translateY(-50%)',
        },
        {
            key: 'right',
            top: '50%',
            right: `-${distance}px`,
            translate: 'translateY(-50%)',
        },
        {
            key: 'bottom',
            bottom: `-${distance}px`,
            left: '50%',
            translate: 'translateX(-50%)',
        },
    ];
};

export const ConnectionHandles = ({
    initialRef,
    scale,
    whiteboardsScale,
}: ConnectionHandlesProps) => (
    <>
        {positions(scale).map(({ key, translate, ...position }) => (
            <ConnectionHandle
                key={key}
                sx={{ ...position }}
                translate={translate}
                initialRef={initialRef}
                whiteboardsScale={whiteboardsScale}
            />
        ))}
    </>
);
