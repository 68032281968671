import { Button, Flex } from '@theme-ui/components';
import { ConfirmationModal } from '../../../components';
import { useTranslationPrefix } from '../../../hooks';

interface Props {
    handleClose: () => void;
    handleDelete: () => void;
    type: string;
    id?: string;
}

type ModalContentTranslationKey = {
    title: string;
    body: string;
    cancel: string;
    confirm: string;
};

export const DeleteElementModal: React.FC<Props> = ({
    handleClose,
    handleDelete,
    type,
    id,
}) => {
    const { tr } = useTranslationPrefix(
        'whiteboard.modals.confirmation.delete'
    );

    const modalContent: ModalContentTranslationKey = {
        title: tr(`title`, { type }),
        body: tr(`body`),
        cancel: tr('cancelAction'),
        confirm: tr('confirmAction'),
    };

    return (
        <ConfirmationModal
            id={id ?? ''}
            isOpen={true}
            onClose={handleClose}
            heading={modalContent.title}
            body={modalContent.body}
        >
            <Flex sx={{ justifyContent: 'flex-end' }}>
                <Button variant="secondary" onClick={handleClose}>
                    {modalContent.cancel}
                </Button>
                <Button variant="primary" onClick={handleDelete} sx={{ ml: 2 }}>
                    {modalContent.confirm}
                </Button>
            </Flex>
        </ConfirmationModal>
    );
};
