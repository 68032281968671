import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Flex, Button } from 'theme-ui';

interface Props {
    uploadFile: (file: File) => void;
    text?: string;
}

export const Dropzone: React.FC<Props> = ({ uploadFile, text }) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<any>([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
            const file = acceptedFiles[0];

            uploadFile(file);
        },
    });

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                alignItems: 'stretch',
            }}
            {...getRootProps({ className: 'dropzone' })}
        >
            <input {...getInputProps()} />
            <Button variant="secondary">{text}</Button>
        </Flex>
    );
};
