import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useTranslationPrefix = (scope: string = '') => {
    const { t } = useTranslation();
    const tr = useCallback(
        (str: string, options?: any) => {
            return t(`${scope}${scope ? '.' : ''}${str}`, options);
        },
        [t, scope]
    );
    return { tr, t };
};
