import React from 'react';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Flex, Paragraph, Heading, Container, Button } from 'theme-ui';
import { rootQueryKey } from '../../constants';
import { useTranslationPrefix } from '../../hooks';

interface Props {
    axiosError?: AxiosResponse;
    customError?: {
        message?: string;
        status?: number;
    };
}
export const ErrorPage: React.FC<Props> = ({ axiosError, customError }) => {
    const error = customError ? customError : axiosError ?? undefined;
    const status = error?.status ?? 500;
    const history = useHistory();

    const { tr } = useTranslationPrefix('error_pages');

    const header = tr(`${status}.title`);
    const text = tr(`${status}.body`);

    const queryClient = useQueryClient();

    return (
        <Flex
            sx={{
                bg: 'bgPrimary',
                height: 'inherit',
                overflowY: 'auto',
            }}
        >
            <Container sx={{ display: 'flex', flexDirection: 'column' }}>
                <Flex
                    sx={{
                        flexDirection: 'column',
                        width: '668px',
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <Heading
                        as="h1"
                        variant="hxxl"
                        sx={{
                            color: 'textPrimary',
                        }}
                    >
                        {error?.status ?? 500}
                    </Heading>
                    <Heading
                        as="h2"
                        variant="hxxl"
                        sx={{ color: 'textTertiary' }}
                    >
                        {header}
                    </Heading>

                    <Paragraph sx={{ mt: 8 }}>{text}</Paragraph>
                    <Button
                        onClick={() => {
                            //Refetch user data after error
                            queryClient.refetchQueries(rootQueryKey);
                            history.push({
                                pathname: '/',
                                search: '',
                            });
                        }}
                        sx={{ mt: 8, mr: 'auto' }}
                    >
                        {tr('navigateAction')}
                    </Button>
                </Flex>
            </Container>
        </Flex>
    );
};

export default Error;
