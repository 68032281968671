import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Box, Button, Heading, Text } from 'theme-ui';
import { useLocalStorage, useTranslationPrefix } from '../hooks';

export const CookieConsent = () => {
    const { isAuthenticated } = useAuth0();
    const { tr } = useTranslationPrefix('general.cookieConsent');

    const [hasSeenStatement, setHasSeenStatement] = useLocalStorage(
        'kinety_has-seen-cookie-statement',
        false
    );

    if (!isAuthenticated) {
        return null;
    }

    if (hasSeenStatement) {
        return null;
    }

    return (
        <Box
            sx={{
                zIndex: 1001,
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                bg: 'bgSecondary',
                overflow: 'hidden',
            }}
        >
            <Flex
                sx={{
                    mx: 'auto',
                    width: ['100%', '432px', '480px', '656px'],
                    p: 6,
                    py: 8,
                    flexDirection: 'column',
                }}
            >
                <Heading sx={{ color: 'white.100' }}>{tr('title')}</Heading>
                <Text sx={{ mt: 6, color: 'white.100' }}>{tr('body')}</Text>
                <Text
                    sx={{
                        mt: 6,
                        pb: 2,
                        color: 'white.100',
                        textAlign: 'center',
                    }}
                >
                    <a
                        href="/cookiestatement_12112021.pdf"
                        target="_blank"
                        style={{ textDecoration: 'underline' }}
                    >
                        {tr('actions.viewStatement')}
                    </a>
                </Text>
                <Button
                    onClick={() => {
                        setHasSeenStatement(true);
                    }}
                    variant="secondary"
                    sx={{
                        mt: 6,
                    }}
                >
                    {tr('actions.confirm')}
                </Button>
            </Flex>
        </Box>
    );
};
