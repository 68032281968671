import Select, { MenuPlacement } from 'react-select';
import { Flex, Label, ThemeUIStyleObject, useThemeUI, Text } from 'theme-ui';
import { baseStyles } from './select/base.styles';
import { selectFieldStyles } from './select/select-field.styles';

interface SelectFieldOption {
    value: string;
    label: string;
}

interface SelectFieldProps {
    id: string;
    isRequired?: boolean;
    options: SelectFieldOption[];
    value?: string | undefined;
    onChange: (value: string | undefined) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    menuPlacement?: MenuPlacement | undefined;
    sx?: ThemeUIStyleObject;
}

export const SelectField: React.FC<SelectFieldProps> = ({
    id,
    isRequired,
    options,
    onChange,
    value,
    label,
    placeholder = '',
    disabled = false,
    menuPlacement = 'bottom',
    sx,
}) => {
    const { theme } = useThemeUI();

    const visualOptions = options.map(option => ({
        id: option.value,
        value: option.value,
        label: <Text key={option.value}>{option.label}</Text>,
    }));

    const selected = visualOptions.find(option => option.value === value);

    const handleOnChange = (newValue: any, actionMeta: any) => {
        onChange(newValue.value);
    };

    return (
        <Flex sx={{ flexDirection: 'column', position: 'relative', ...sx }}>
            <Label htmlFor={id} sx={{ pb: 4, opacity: !!disabled ? 0.3 : 1 }}>
                {label} {isRequired && '*'}
            </Label>
            <Select
                isDisabled={disabled}
                options={visualOptions}
                onChange={handleOnChange}
                value={selected}
                placeholder={placeholder}
                menuPlacement={menuPlacement}
                styles={{
                    ...baseStyles(theme),
                    ...selectFieldStyles(theme),
                }}
            />
        </Flex>
    );
};
