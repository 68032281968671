import { Dispatch, forwardRef, ReactNode, useCallback } from 'react';
import {
    DraggableSyntheticListeners,
    Translate,
    useDraggable,
    useDndMonitor,
} from '@dnd-kit/core';

import { Box } from 'theme-ui';
import {
    onDragEnd,
    onDragMove,
} from '../../modules/whiteboard/components/card/state/reducer';
import { useTransformContext } from '@tmi/react-zoom-pan-pinch';
import { useUpdateItemInstance } from '../../api/item';

interface DraggableItemProps {
    disabled: boolean;
    children: ReactNode;
    dispatch: Dispatch<any>;
    state: IItemInstance;
}

export const DraggableItem: React.FC<DraggableItemProps> = ({
    disabled,
    state,
    dispatch,
    ...props
}) => {
    const { attributes, listeners, setNodeRef } = useDraggable({
        disabled,
        id: state._id,
    });

    const { mutate } = useUpdateItemInstance({ _id: state._id });

    const { position } = state;

    const handleDragEnd = useCallback(
        (newPosition: ICoordinates) => {
            mutate({ position: newPosition });
            dispatch(onDragEnd({ position: newPosition }));
        },
        [dispatch, mutate]
    );

    const handleDragMove = useCallback(
        (newPosition: ICoordinates) => {
            dispatch(onDragMove({ position: newPosition }));
        },
        [dispatch]
    );

    const {
        state: { scale },
    } = useTransformContext();

    useDndMonitor({
        onDragMove: ({ active, delta }) => {
            if (active.id !== state._id) return;

            const newPos = {
                x: delta.x / scale,
                y: delta.y / scale,
            };

            handleDragMove(newPos);
        },
        onDragEnd: ({ active, delta }) => {
            if (active.id !== state._id) {
                return;
            }

            const newPos = {
                x: position.x + delta.x / scale,
                y: position.y + delta.y / scale,
            };

            /**
             * Send a `translate: null` over the socket when the drag is ending, this tells the component
             * that the translate is done, and the new position from the state will take over
             */

            //dispatch of card state
            handleDragEnd(newPos);
        },
    });

    return (
        <Draggable
            ref={setNodeRef}
            listeners={listeners}
            {...props}
            {...attributes}
        />
    );
};

interface Props {
    dragOverlay?: boolean;
    state?: IItemInstance;
    listeners?: DraggableSyntheticListeners;
    style?: React.CSSProperties;
    translate?: Translate | null;
    disabled?: boolean;
}

const disableOutline = {
    '&:focus': {
        outline: 'none',
    },
};

export const Draggable = forwardRef<HTMLDivElement, Props>(function Draggable(
    { dragOverlay, listeners, translate, children, ...props },
    ref
) {
    return (
        <div
            style={{
                cursor: 'grab',
            }}
        >
            <Box
                {...listeners}
                {...props}
                ref={ref}
                aria-label="Draggable"
                data-cypress="draggable-item"
                tabIndex={-1}
                sx={disableOutline}
            >
                {children}
            </Box>
        </div>
    );
});
