import React, { useRef, useState } from 'react';
import { uniq } from 'lodash';
import { EmailList } from './email-list';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Button } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { Textfield } from '@tmi/component-library';
import { emailRegex } from '../constants';

interface InviteMembersProps {
    label: string;
    placeholder: string;
    buttonLabel: string;
    errorText?: string;
    list: string[];
    appendList: (list: string[]) => void;
    displayList?: boolean;
    sx?: ThemeUIStyleObject;
}

export const InviteMembers = ({
    label,
    placeholder,
    buttonLabel,
    errorText = '',
    list,
    appendList,
    displayList = true,
    sx,
}: InviteMembersProps) => {
    const { t } = useTranslation();
    const [emailInput, setEmailInput] = useState('');
    const [error, setError] = useState(errorText);
    const inputRef = useRef<HTMLInputElement>(null);

    const isEmail = email => emailRegex.test(email);
    const isInList = email => list.includes(email);

    const addToList = () => {
        // Reset errors
        setError('');

        // Get array of all emails
        // Clean whitespace of input
        const emails = emailInput
            .split(';')
            .join(',')
            .split(',')
            .map(email => email.trim());

        // If only 1 email is added, then set error messages
        if (emails.length === 1) {
            if (!isEmail(emails[0])) {
                return setError(t('general.errors.emails.invalid'));
            }

            if (isInList(emails[0])) {
                return setError(t('general.errors.emails.duplicate'));
            }
        }

        // 1. Make sure emails are unique
        // 2. Filter out all non valid emails
        // 3. Filter out duplicate emails from already existing emails in list
        const toAdd = uniq(emails)
            .filter(email => isEmail(email))
            .filter(email => !isInList(email));

        // Add emails to list
        appendList(toAdd);
        // Reset input
        setEmailInput('');
        // Refocus on input
        inputRef.current?.focus();
    };

    // const removeFromList = React.useCallback(
    //     email => {
    //         setList(list.filter(item => item !== email));
    //     },
    //     [list, setList]
    // );

    const handleOnChange = e => {
        if (e.target.value) {
            setError('');
        }

        return setEmailInput(e.target.value);
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            addToList();
        }
    };

    return (
        <Box sx={{ ...sx }}>
            <Flex
                sx={{
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                }}
            >
                <Box sx={{ flex: 1, position: 'relative' }}>
                    <Textfield
                        label={label}
                        placeholder={placeholder}
                        errorMessage={error}
                        name="emailInput"
                        ref={inputRef}
                        id="emailInput"
                        value={emailInput}
                        onChange={handleOnChange}
                        onKeyDown={handleKeyDown}
                    />
                </Box>
                <Button
                    onClick={addToList}
                    variant="secondary"
                    sx={{
                        ml: 6,
                        // Ugly fix, but we couldn't think about better solution at the moment
                        height: '72px',
                    }}
                >
                    {buttonLabel}
                </Button>
            </Flex>

            {displayList && list.length > 0 && (
                <Box sx={{ mt: 16 }}>
                    <EmailList emails={list} />
                </Box>
            )}
        </Box>
    );
};
