import { Box, Divider, Flex, IconButton, Paragraph } from 'theme-ui';
import { Icon } from '@tmi/component-library';
import {
    useDropdown,
    useGetTags,
    useGetTeamMembers,
    useTranslationPrefix,
    useAppSelector,
} from '../../../../hooks';
import { getUserForActiveOrg } from '../../../../redux/reducers';
import { options as statuses } from '../../../../components/status-picker/status-picker';
import { useState, useCallback, useEffect } from 'react';
import {
    cardTypeOptions,
    dueDateOptions,
    filterCategories,
} from './filter.constants';
import { FilterSelection } from './filter-selection';
import { FilterCategory } from './filter-category';
import { useFilterData, useFilterAPI } from '../../../../providers/filter';
import { isFilterActive } from '../../../../helpers/filter/filter';
import { TOGGLE_SIDEBAR } from '../../../../constants/hotkeys';

export const FilterInput = () => {
    const { data: teamMembers } = useGetTeamMembers();
    const { data: tags } = useGetTags();
    const { filterState, selectedCards } = useFilterData();
    const { dispatch } = useFilterAPI();
    const [currentCategory, setCurrentCategory] = useState<Category | null>(
        filterCategories.CARD_TYPE
    );
    const { tr } = useTranslationPrefix('whiteboard.cards.generic.status');

    const {
        Dropdown,
        dropdownProps,
        positionalRef,
        toggleDropdown,
        closeDropdown,
    } = useDropdown({ placement: 'bottom-start', offset: { x: 1, y: 8 } });

    useEffect(() => {
        const listener = e => {
            if (e.key === TOGGLE_SIDEBAR) {
                closeDropdown();
            }
        };

        document.addEventListener('keydown', listener);

        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [closeDropdown]);

    const user = useAppSelector(getUserForActiveOrg);

    const restMembers = teamMembers
        ? teamMembers
              .filter(member => member._id !== user._id)
              .sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
        : [];

    // TODO: move texts for filters to translation file
    const teamMemberOptions: QueryEntry<string>[] = [
        {
            label: 'No assignees',
            value: '',
            uniqueEmpty: true,
        },
        {
            label: `${user.fullName} (me)`,
            value: user._id,
        },
        ...restMembers.map(teamMember => ({
            value: teamMember._id,
            label: teamMember.fullName,
        })),
    ];

    const tagOptions: QueryEntry<string>[] = [
        {
            label: 'No tags',
            value: '',
            uniqueEmpty: true,
        },
        ...(tags ?? []).map(tag => ({
            value: tag._id,
            label: tag.name,
        })),
    ];

    const extendedDueDateOptions: QueryEntry<string>[] = [
        { label: 'No due date', value: '', uniqueEmpty: true },
        ...dueDateOptions,
    ];

    const statusOptions: QueryEntry<string>[] = (statuses ?? []).map(
        status => ({
            value: status.value,
            label: tr(status.key),
        })
    );

    const handleCategoryClick = useCallback(
        category =>
            currentCategory === category
                ? setCurrentCategory(null)
                : setCurrentCategory(category),
        [currentCategory]
    );

    const selection = Object.values(filterCategories)
        .map(category =>
            filterState[category].query.map(option => ({
                entry: option,
                category,
            }))
        )
        .flat();

    const isFiltering = isFilterActive(filterState);

    return (
        <Flex
            ref={positionalRef}
            sx={{
                bg: isFiltering ? 'white' : 'actionPrimary',
                p: 3,
                borderRadius: 3,
                color: 'textPrimary',
                alignItems: 'center',
                boxShadow: 'floatingBar',
                pointerEvents: 'auto',
            }}
        >
            <Box
                sx={{
                    flexShrink: 0,
                    color: isFiltering ? 'textPrimary' : 'white',
                }}
            >
                <Icon iconName="filter" fillStyle="solid" />
            </Box>

            <IconButton
                sx={{
                    flexShrink: 0,
                    height: '24px',
                    width: '24px',
                    ml: 2,
                    color: isFiltering ? 'actionPrimary' : 'white',

                    '&:hover:enabled': {
                        bg: isFiltering ? 'bgPrimary' : 'actionPrimaryIntense',
                    },
                }}
                onClick={toggleDropdown}
            >
                <Icon iconName="chevronDown" fillStyle="solid" />
            </IconButton>

            {isFilterActive(filterState) && (
                <Flex
                    sx={{
                        ml: 2,
                        height: '24px',
                        borderLeft: 1,
                        pl: 4,
                        borderColor: 'bgPrimary',
                    }}
                >
                    <Paragraph variant="labelMd" sx={{ fontWeight: 'medium' }}>
                        {selectedCards.length} result
                        {selectedCards.length !== 1 && 's'}
                    </Paragraph>
                </Flex>
            )}

            <FilterSelection
                selected={selection}
                onRemoveSingleClick={(category, entry) => {
                    dispatch({
                        type: category,
                        payload: entry,
                    });
                }}
                onRemoveAllClick={() => {
                    dispatch({ type: 'RESET' });
                }}
            />

            <Dropdown
                {...dropdownProps}
                sx={{
                    width: '260px',
                    p: 0,
                }}
            >
                <FilterCategory
                    title="Card type"
                    options={cardTypeOptions}
                    value={filterState.cardType.query}
                    category={filterCategories.CARD_TYPE}
                    isOpen={currentCategory === filterCategories.CARD_TYPE}
                    onButtonClick={handleCategoryClick}
                    onFilterClick={entry => {
                        dispatch({
                            type: filterCategories.CARD_TYPE,
                            payload: entry,
                        });
                    }}
                />
                <Divider sx={{ borderColor: 'bgPrimary', m: 0 }} />
                <FilterCategory
                    title="Assignees"
                    options={teamMemberOptions}
                    value={filterState.assignees.query}
                    category={filterCategories.ASSIGNEES}
                    isOpen={currentCategory === filterCategories.ASSIGNEES}
                    onButtonClick={handleCategoryClick}
                    onFilterClick={entry => {
                        dispatch({
                            type: filterCategories.ASSIGNEES,
                            payload: entry,
                        });
                    }}
                />
                <Divider sx={{ borderColor: 'bgPrimary', m: 0 }} />
                <FilterCategory
                    title="Due date"
                    options={extendedDueDateOptions}
                    value={filterState.dueDate.query}
                    category={filterCategories.DUE_DATE}
                    isOpen={currentCategory === filterCategories.DUE_DATE}
                    onButtonClick={handleCategoryClick}
                    onFilterClick={entry => {
                        dispatch({
                            type: filterCategories.DUE_DATE,
                            payload: entry,
                        });
                    }}
                />
                <Divider sx={{ borderColor: 'bgPrimary', m: 0 }} />
                <FilterCategory
                    title="Tags"
                    options={tagOptions}
                    value={filterState.tags.query}
                    category={filterCategories.TAGS}
                    isOpen={currentCategory === filterCategories.TAGS}
                    onButtonClick={handleCategoryClick}
                    onFilterClick={entry => {
                        dispatch({
                            type: filterCategories.TAGS,
                            payload: entry,
                        });
                    }}
                />
                <Divider sx={{ borderColor: 'bgPrimary', m: 0 }} />
                <FilterCategory
                    title="Status"
                    options={statusOptions}
                    value={filterState.status.query}
                    category={filterCategories.STATUS}
                    isOpen={currentCategory === filterCategories.STATUS}
                    onButtonClick={handleCategoryClick}
                    onFilterClick={entry => {
                        dispatch({
                            type: filterCategories.STATUS,
                            payload: entry,
                        });
                    }}
                />
            </Dropdown>
        </Flex>
    );
};
