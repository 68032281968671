import { Icon } from '@tmi/component-library/src';
import { Link } from 'react-router-dom';
import { Box, Button, Flex } from 'theme-ui';
import { SESSION_ROUTE, WHITEBOARD_ROUTE } from '../../../../constants';
import { useWhiteboardParams } from '../../../../hooks';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { useSidebarAPI } from '../../../../providers/sidebar';

const SidebarNavigation = ({
    hasUnreadNotifications,
}: {
    hasUnreadNotifications: boolean;
}) => {
    const { whiteboardKey } = useWhiteboardParams();
    const { clearActiveCard } = useSidebarAPI();

    const baseRoute = `/${WHITEBOARD_ROUTE}/${whiteboardKey}`;
    const sessionsOverviewRoute = `/${WHITEBOARD_ROUTE}/${whiteboardKey}/${SESSION_ROUTE}`;

    return (
        <AnimateSharedLayout>
            <Flex
                sx={{
                    height: '100%',
                    flexShrink: 0,
                    borderRight: 1,
                    borderColor: 'bgPrimary',
                    flexDirection: 'column',
                    gap: 4,
                    pt: 6,
                    px: 4,
                }}
            >
                <Link to={baseRoute} tabIndex={-1} onClick={clearActiveCard}>
                    <NavButton
                        isActive={!window.location.href.includes(SESSION_ROUTE)}
                    >
                        <Icon iconName="viewList" />
                        {hasUnreadNotifications && (
                            <Box
                                sx={{
                                    height: '8px',
                                    width: '8px',
                                    borderRadius: 5,
                                    bg: 'alarming',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                }}
                            />
                        )}
                    </NavButton>
                </Link>
                <Link
                    to={sessionsOverviewRoute}
                    tabIndex={-1}
                    onClick={clearActiveCard}
                >
                    <NavButton
                        isActive={window.location.href.includes(SESSION_ROUTE)}
                    >
                        <Icon iconName="statusOnline" fillStyle="solid" />
                    </NavButton>
                </Link>
            </Flex>
        </AnimateSharedLayout>
    );
};

const MotionBox = motion(Box);
const MotionButton = motion(Button);

const NavButton = ({ children, isActive }) => {
    return (
        <MotionButton
            layout
            variant="icon"
            sx={{
                position: 'relative',
                opacity: isActive ? 1 : 0.3,
            }}
        >
            {children}

            {isActive && (
                <MotionBox
                    initial={false}
                    layoutId="navButtonIndicator"
                    style={{ y: -12 }}
                    sx={{
                        position: 'absolute',
                        right: '-16px',
                        top: '50%',
                        height: '26px',
                        width: '2px',
                        borderTopLeftRadius: 2,
                        borderBottomLeftRadius: 2,
                        bg: 'textPrimary',
                    }}
                />
            )}
        </MotionButton>
    );
};

export default SidebarNavigation;
