import { useContext, useEffect } from 'react';
import { ToastContext } from '../../providers';
import { ToastDisplay } from './toast-display';

export const Toast = () => {
    const [toast, setToast] = useContext(ToastContext);

    useEffect(() => {
        if (!toast) return;

        const timeout = setTimeout(() => {
            setToast(null);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, [toast, setToast]);

    if (!toast) {
        return null;
    }

    return (
        <ToastDisplay
            handleClick={() => setToast(null)}
            type={toast.type}
            message={toast.message}
            sx={{
                position: 'fixed',
                left: 8,
                bottom: 8,
            }}
        />
    );
};
