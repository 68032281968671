import React, { useState, Dispatch, SetStateAction } from 'react';

const ToastContext = React.createContext<
    [Toast | null, Dispatch<SetStateAction<Toast | null>>]
>([null, () => {}]);

type Toast = {
    type: 'success' | 'error';
    message: string;
};

function ToastProvider({ children }) {
    const ToastState = useState<Toast | null>(null);

    return (
        <ToastContext.Provider value={ToastState}>
            {children}
        </ToastContext.Provider>
    );
}
export { ToastProvider, ToastContext };
