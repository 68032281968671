import { useCallback, useState, useEffect } from 'react';
import { Badge, Box, useThemeUI } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { baseStyles } from '../select/base.styles';
import { Popout } from '../popout';
import Select, { ActionMeta } from 'react-select';
import { statusPickerStyles } from './status-picker.styles';
import { useTranslationPrefix } from '../../hooks';

interface StatusPickerProps {
    isEditActive: boolean;
    sx?: ThemeUIStyleObject;
    value: string;
    onChange?: (newValue: string, actionMeta: ActionMeta<string>) => void;
}

export const options = [
    {
        value: '',
        key: 'noState',
        color: '#ffffff',
    },
    {
        value: 'done',
        key: 'done',
        color: '#51F694',
    },
    {
        value: 'onTrack',
        key: 'onTrack',
        color: '#FFEF60',
    },
    {
        value: 'fallingBehind',
        key: 'fallingBehind',
        color: '#FFB151',
    },
    {
        value: 'atRisk',
        key: 'atRisk',
        color: '#FF7F6D',
    },
    {
        value: 'draft',
        key: 'draft',
        color: '#ACB1BF',
    },
    {
        value: 'notStarted',
        key: 'notStarted',
        color: '#AEC9FF',
    },
];

const Label = ({ value, color }) => {
    const { tr } = useTranslationPrefix('whiteboard.cards.generic.status');

    return (
        <Badge key={value} sx={{ bg: color }}>
            {tr(value)}
        </Badge>
    );
};

const optionsWithLabel = options.map(({ value, key, color }) => ({
    value,
    label: <Label value={key} color={color} />,
}));

export const StatusPickerEdit = ({
    sx,
    isEditActive,
    value,
    onChange = () => {},
}: StatusPickerProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme } = useThemeUI();

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const handleOnChange = useCallback(
        (newValue, actionMeta) => {
            onChange(newValue.value, actionMeta);
        },
        [onChange]
    );

    useEffect(() => {
        if (!isEditActive) {
            handleClose();
        }
    }, [handleClose, isEditActive]);

    return (
        <Box sx={{ ...sx, position: 'relative' }}>
            <Popout
                isOpen={isOpen}
                onClose={handleClose}
                menuPosition={{ top: 0, mt: 0 }}
                target={
                    <SetStatusButton value={value} handleOpen={handleOpen} />
                }
            >
                <Select
                    value={optionsWithLabel.find(
                        option => option.value === value
                    )}
                    options={optionsWithLabel}
                    onChange={handleOnChange}
                    menuIsOpen
                    styles={{
                        ...baseStyles(theme),
                        ...statusPickerStyles(theme),
                    }}
                />
            </Popout>
        </Box>
    );
};

interface SetStatusButtonProps {
    value: string;
    handleOpen?: () => void;
}

const SetStatusButton = ({
    value,
    handleOpen = () => {},
}: SetStatusButtonProps) => {
    const { tr } = useTranslationPrefix('whiteboard.cards.generic.status');

    if (!value) {
        return (
            <Badge
                onClick={handleOpen}
                sx={{
                    bg: 'actionPrimary',
                    color: 'textInverse',
                    display: 'block',
                }}
            >
                {tr('placeholder')}
            </Badge>
        );
    }

    const option = options.find(option => option.value === value);

    if (!option) {
        return null;
    }

    return (
        <Box onClick={handleOpen}>
            <Label value={option.key} color={option.color} />
        </Box>
    );
};

export const StatusPicker = (props: StatusPickerProps) => {
    const { isEditActive, value, sx } = props;

    if (!isEditActive && !value)
        return (
            <Box
                sx={{
                    height: '19px',
                    width: '1px',
                    visibility: 'hidden',
                    ...sx,
                }}
            />
        );

    if (!isEditActive) {
        const option = options.find(option => option.value === value);

        if (!option) return null;

        return (
            <Box sx={sx}>
                <Label value={option.key} color={option.color} />
            </Box>
        );
    }

    return <StatusPickerEdit {...props} />;
};
