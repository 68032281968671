import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { getWhiteboard } from '../../api';
import { getWhiteboardQueryKey } from '../../constants';
import { useWhiteboardParams } from '..';

export const useGetWhiteboard = () => {
    const { whiteboardKey } = useWhiteboardParams();

    if (!whiteboardKey)
        throw Error('Trying to fetch whiteboard data, but no key is present');

    return useQuery<IWhiteboard, AxiosError>(
        [getWhiteboardQueryKey, { key: whiteboardKey }],
        () => getWhiteboard({ whiteboardKey }),
        {
            enabled: !!whiteboardKey,
            retry: (failureCount, error) => {
                if (error.response?.status === 403) return false;
                if (failureCount >= 3) return false;
                return true;
            },
        }
    );
};
