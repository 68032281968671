import React from 'react';
import { Flex, Input, Label, Text, ThemeUIStyleObject } from 'theme-ui';

interface TextfieldProps extends React.HTMLProps<HTMLInputElement> {
    id: string;
    label: string;
    isRequired?: boolean;
    sx?: ThemeUIStyleObject;
    errorMessage?: string;
}

export const Textfield = ({
    label,
    isRequired = false,
    placeholder = '',
    errorMessage,
    id,
    as,
    ref,
    sx,
    disabled,
    ...rest
}: TextfieldProps) => (
    <Flex sx={{ flexDirection: 'column', position: 'relative', ...sx }}>
        <Label htmlFor={id} sx={{ pb: 4, opacity: !!disabled ? 0.3 : 1 }}>
            {label} {isRequired && '*'}
        </Label>
        <Input
            placeholder={placeholder}
            {...rest}
            id={id}
            disabled={disabled}
        />
        <Text
            variant="labelXs"
            sx={{
                color: 'alarming',
                position: 'absolute',
                bottom: -6,
            }}
        >
            {errorMessage}
        </Text>
    </Flex>
);
