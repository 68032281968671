import { Box } from '@theme-ui/components';
import {
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
} from 'react';
import { ToastDisplay } from '../../../../components/toast/toast-display';
import { useAppSelector, useTranslationPrefix } from '../../../../hooks';
import { useWhiteboardParams } from '../../../../hooks';
import {
    useGetSession,
    useGetSessionParticipants,
    useJoinSession,
    useLeaveSession,
    useUpdateSession,
} from '../../../../api/session';
import { useUnmount } from '../../../../hooks/use-unmount';
import { WhiteboardContext } from '../../containers/whiteboard.container';
import { Frame } from './frame';
import { useCreateFrameHandler } from './use-create-frame-handler';
import { checkInSpecificSessionView } from '../../../../helpers';
import { getUserForActiveOrg } from '../../../../redux/reducers';

interface Props {
    scale: number;
    canvasRef: RefObject<HTMLDivElement>;
    startCreateFrame: boolean;
    setStartCreateFrame: Dispatch<SetStateAction<boolean>>;
}

export const SessionContainer = ({
    scale,
    canvasRef,
    startCreateFrame,
    setStartCreateFrame,
}: Props) => {
    const { setDragDisabled } = useContext(WhiteboardContext);
    const { sessionKey } = useWhiteboardParams();

    const user = useAppSelector(getUserForActiveOrg);

    const { mutate: leaveSession } = useLeaveSession();

    const isInView = checkInSpecificSessionView(
        window.location.href,
        sessionKey
    );
    const { data, refetch } = useGetSession();

    const {
        data: participants,
        refetch: refetchParticipants,
        isFetching,
        isLoading,
    } = useGetSessionParticipants();

    const leaveSessionHandler = useCallback(async () => {
        if (participants?.find(({ v }) => v._id === user._id)) {
            await leaveSession();
            refetch();
            refetchParticipants();
        }
    }, [leaveSession, refetch, refetchParticipants, participants, user._id]);

    useUnmount(leaveSessionHandler);

    useEffect(() => {
        if (!isInView) {
            leaveSessionHandler();
        }
    }, [isInView, leaveSessionHandler]);

    useEffect(() => {
        if (isInView) {
            refetchParticipants();
        }
    }, [isInView, refetchParticipants]);

    const { mutate: joinSession, isLoading: isJoining } = useJoinSession();
    useEffect(() => {
        if (!participants || isFetching || isLoading || isJoining) {
            return;
        }

        if (isInView) {
            if (!participants.find(({ v }) => v._id === user._id)) {
                joinSession();
            }
        }
    }, [
        joinSession,
        isJoining,
        isInView,
        participants,
        user._id,
        isFetching,
        isLoading,
    ]);

    const { mutate } = useUpdateSession();

    const handleCreate = useCallback(
        (newFrame: ISessionFrame) => {
            mutate({
                frames: [...(data?.frames ?? []), newFrame],
            });

            setDragDisabled(false);

            setStartCreateFrame(false);
        },
        [data?.frames, mutate, setStartCreateFrame] // eslint-disable-line
    );

    const handleUpdate = useCallback(
        newFrame => {
            const newFrames = [...(data?.frames ?? [])];

            const updateFrameIndex = newFrames.findIndex(
                frameTemp => frameTemp.id === newFrame.id
            );

            newFrames[updateFrameIndex] = { ...newFrame };

            mutate({
                frames: newFrames,
            });
        },
        [data?.frames, mutate] // eslint-disable-line
    );

    return (
        <>
            {/* Temp frame */}

            {data &&
                data.frames &&
                data.frames.map((frame, index) => (
                    <Frame
                        key={frame.id}
                        frame={frame}
                        setFrame={handleUpdate}
                        scale={scale}
                        number={index + 1}
                    />
                ))}

            {startCreateFrame && (
                <FrameWrapper
                    scale={scale}
                    canvasRef={canvasRef}
                    startCreateFrame={startCreateFrame}
                    setStartCreateFrame={setStartCreateFrame}
                    handler={handleCreate}
                />
            )}
        </>
    );
};

const FrameWrapper = ({ scale, canvasRef, startCreateFrame, handler }: any) => {
    const { tr } = useTranslationPrefix('sidebar.sessions.edit');
    const {
        frame,
        showElement,
        setFrame,
        delta,
        initialPos,
    } = useCreateFrameHandler({
        scale,
        canvasRef,
        startCreateFrame,
        handler,
    });

    return (
        <>
            {showElement ? (
                <Box
                    sx={{
                        border: '1px solid black',
                        position: 'absolute',
                        left:
                            delta.x < 0 ? initialPos.x + delta.x : initialPos.x,
                        top:
                            delta.y < 0 ? initialPos.y + delta.y : initialPos.y,
                        width: Math.abs(delta.x),
                        height: Math.abs(delta.y),
                    }}
                ></Box>
            ) : (
                <ToastDisplay
                    message={tr('actions.toastMessage')}
                    sx={{
                        position: 'fixed',
                        bottom: 8,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                />
            )}
            {frame && <Frame frame={frame} setFrame={setFrame} scale={scale} />}
        </>
    );
};
