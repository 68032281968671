export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//dimensions for cursor
export const CURSOR_DIMENSION = 16;
export const CURSOR_HALF_DIMEMSION = CURSOR_DIMENSION / 2;

export const WHITEBOARD_SIZE = {
    width: 15000,
    height: 10000,
};

export const SIDEBAR_CARD_CONTENT_ID = 'sidebar-card-content';

export const DELETE_COMMENT_MODAL_ID = 'comments-modal';

export const DELETE_TAG_MODAL_ID = 'tag-modal';

export const ITEM_INSTANCE_ID = 'item_instance';
export const SIDEBAR_ID = 'sidebar';
export const SIDEBAR_TRIGGER_BUTTON_ID = 'sidebarTriggerButton';
export const CONTEXT_MENU_ID = 'context-menu';
export const CONTEXT_MENU_PLACEMENT_ID = 'contextMenuPlacement';
export const TOAST_ID = 'toast';

export const ZOOM_TO_ELEMENT_SCALE = 2;

export const SESSION__ADMIN = 'session__admin';

export const ONBOARDING_DISMISSED = 'onboardingDismissed';
export const FREE_MEMBER_AMOUNT = 3;

export const GROWTH_SUBSCRIPTION_KEY = 'basic';
export const PRO_SUBSCRIPTION_KEY = 'premium';
