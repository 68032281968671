import {
    useCallback,
    Dispatch,
    MutableRefObject,
    useRef,
    useState,
    useMemo,
} from 'react';
import { debounce, pick } from 'lodash';
import { CardMode } from '../../../../types/enums';
import { EditOutline } from './edit-outline';
import { Goal, Action, Behaviour, Value, Result } from './card-types';
import { setCardMode, setItemProperty } from './state/reducer';
import {
    useAppSelector,
    useUpdateCache,
    useWhiteboardParams,
} from '../../../../hooks';
import { checkInSessionEdit, checkInSessionIntro } from '../../../../helpers';
import { useCreateConnectionMutation } from '../../containers/connection.container';
import {
    useConnectionAPI,
    useConnectionData,
} from '../../../../providers/connection';
import { CardTypes, CARD_META } from '../../constants/card';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { useIsInView } from '../../../../hooks/use-is-in-view';
import { CardIcon } from './card-activity';
import { ContextMenu } from './context-menu';
import { useFilterData } from '../../../../providers/filter';
import { isFilterActive } from '../../../../helpers/filter/filter';
import { useSidebarAPI } from '../../../../providers/sidebar';
import { useUpdateItemInstance } from '../../../../api/item';
import { getUserForActiveOrg } from '../../../../redux/reducers';
import { itemQueryKey } from '../../../../constants';

interface CardProps {
    itemInstance: IItemInstance;
    dispatch: Dispatch<any>;
    canvasRef: MutableRefObject<HTMLElement | null>;
    handleDelete?: () => void;
}

export interface BaseCardProps {
    dispatchHandler: DispatchHandler;
    cardMode: CardMode;
    cardScale: number;
    isLocked: boolean;
    isGhost?: boolean;
    isHighlight?: boolean | null;
    sx?: ThemeUIStyleObject;
}

export type DispatchHandler = (key: string) => (value: any) => void;

export const Card = ({
    itemInstance,
    dispatch,
    canvasRef,
    handleDelete = () => {},
}: CardProps) => {
    const { whiteboardKey } = useWhiteboardParams();
    const { mutate: createConnectionMutation } = useCreateConnectionMutation();
    const { setInitialRef } = useConnectionAPI();
    const { initialRef } = useConnectionData();
    const { mutate } = useUpdateItemInstance({
        _id: itemInstance._id,
    });

    const [contextMenuPosition, setContextMenuPosition] = useState<{
        x: number;
        y: number;
    } | null>(null);
    const { filterState, selectedCards } = useFilterData();
    const editOutlineRef = useRef<HTMLDivElement>(null!);

    const { setActiveCard, resetActiveCard, openSidebar } = useSidebarAPI();
    const isInSessionEdit = checkInSessionEdit(window.location.pathname);
    const isInSessionIntro = checkInSessionIntro(window.location.pathname);

    const hasNotifications = itemInstance.notifications?.length > 0;

    const user = useAppSelector(state => getUserForActiveOrg(state));

    const updateCache = useUpdateCache(itemQueryKey);

    const debounced = useMemo(() => debounce(mutate, 500), [mutate]);

    const dispatchHandler = useCallback(
        key => value => {
            debounced({
                [key]: value,
            });
            updateCache({ ...itemInstance, [key]: value }, itemInstance._key);
            dispatch(
                setItemProperty({
                    [key]: value,
                })
            );
        },
        [dispatch, debounced, updateCache, itemInstance]
    );

    const isLocked =
        itemInstance.cardMode === CardMode.LOCKED &&
        itemInstance.currentlyEditedBy !== user._id;

    const baseProps: BaseCardProps = {
        dispatchHandler,
        cardMode: itemInstance.cardMode,
        cardScale: itemInstance.cardScale,
        isHighlight: isFilterActive(filterState)
            ? selectedCards.some(card => card._id === itemInstance._id)
            : null,
        sx:
            initialRef && initialRef !== editOutlineRef
                ? {
                      '&:hover': {
                          outline: '3px solid',
                          outlineColor: 'actionPrimary',
                      },
                  }
                : {},
        isLocked,
    };

    const handleClick = useCallback(() => {
        // TODO we need a new cardmode to enable user to select a locked item

        if (isInSessionEdit || isInSessionIntro) return;

        setActiveCard({ key: itemInstance._key, type: itemInstance.type });

        // This is placed below the session logic to make sure we don't leave the session (edit) when clicking a card
        if (itemInstance.cardMode !== CardMode.VIEW) return;

        mutate({
            cardMode: CardMode.LOCKED,
            currentlyEditedBy: user._id,
        });
        dispatch(
            setCardMode({
                cardMode: CardMode.LAYOUT,
                currentlyEditedBy: user._id,
            })
        );
    }, [
        setActiveCard,
        itemInstance,
        isInSessionEdit,
        isInSessionIntro,
        mutate,
        dispatch,
        user._id,
    ]);

    const handleDoubleClick = useCallback(() => {
        if (
            itemInstance.cardMode === CardMode.LOCKED &&
            itemInstance.currentlyEditedBy !== user._id
        )
            return;

        if (isInSessionEdit || isInSessionIntro) return;

        dispatch(
            setCardMode({
                cardMode: CardMode.EDIT,
                currentlyEditedBy: user._id,
            })
        );
    }, [
        dispatch,
        isInSessionEdit,
        isInSessionIntro,
        itemInstance.cardMode,
        itemInstance.currentlyEditedBy,
        user._id,
    ]);

    const handleClickOutside = useCallback(() => {
        resetActiveCard();
        if (isLocked) return;
        if (itemInstance.cardMode === CardMode.VIEW) return; //if no longer being editted
        mutate({ cardMode: CardMode.VIEW, currentlyEditedBy: '' });
        dispatch(
            setCardMode({ cardMode: CardMode.VIEW, currentlyEditedBy: '' })
        );
    }, [dispatch, isLocked, itemInstance.cardMode, resetActiveCard, mutate]);

    const handleMouseUp = useCallback(() => {
        if (!initialRef) return;
        if (initialRef === editOutlineRef) return;
        if (
            !initialRef.current.id ||
            !editOutlineRef?.current?.id ||
            !whiteboardKey
        )
            return;

        createConnectionMutation({
            startItemId: initialRef.current.id,
            endItemId: editOutlineRef.current.id,
            whiteboardKey,
        });

        setInitialRef(null);
    }, [
        whiteboardKey,
        editOutlineRef,
        createConnectionMutation,
        setInitialRef,
        initialRef,
    ]);

    const closeContextMenu = useCallback(() => {
        setContextMenuPosition(null);
    }, [setContextMenuPosition]);

    const setCardType = (type: CardType): void => {
        dispatchHandler('type')(type);
        closeContextMenu();
    };

    const { width, height } = CARD_META[itemInstance.type];

    const shouldAnimate = itemInstance?.currentlyEditedBy !== user._id;

    return (
        <>
            <Box
                onMouseUp={handleMouseUp}
                onContextMenu={e => {
                    setContextMenuPosition({ x: e.clientX, y: e.clientY });
                }}
                id={itemInstance._id}
                onClick={handleClick}
                ref={editOutlineRef}
                sx={{
                    cursor: 'grab',
                    position: 'absolute',
                    left: itemInstance?.position.x,
                    top: itemInstance.position.y,
                    transform: `translate3d(${itemInstance?.tempPosition?.x ||
                        0}px, ${itemInstance?.tempPosition?.y || 0}px, 0)`,
                    width: `${width * itemInstance.cardScale}px`,
                    transition: shouldAnimate ? '0.3s' : '0s',
                    height: `${height * itemInstance.cardScale}px`,
                }}
            >
                {hasNotifications && <CardIcon />}
                {itemInstance.cardMode !== CardMode.VIEW ? (
                    <EditOutline
                        editOutlineRef={editOutlineRef}
                        canvasRef={canvasRef}
                        dispatch={dispatch}
                        cardMode={itemInstance.cardMode}
                        handleDoubleClick={handleDoubleClick}
                        handleClickOutside={handleClickOutside}
                        itemInstance={itemInstance}
                        isLocked={isLocked}
                    >
                        <CardRender
                            baseProps={baseProps}
                            itemInstance={itemInstance}
                        />
                    </EditOutline>
                ) : (
                    <CardRender
                        baseProps={baseProps}
                        itemInstance={itemInstance}
                    />
                )}
            </Box>

            {contextMenuPosition && (
                <ContextMenu
                    position={contextMenuPosition}
                    onOutsideClick={closeContextMenu}
                >
                    <ContextMenu.Group
                        iconName="switchHorizontal"
                        text="Switch card type"
                    >
                        <ContextMenu.Button
                            iconName="flag"
                            text="Goal"
                            onClick={() => setCardType(CardTypes.GOAL)}
                            isSelected={itemInstance.type === CardTypes.GOAL}
                        />
                        <ContextMenu.Button
                            iconName="star"
                            text="Result"
                            onClick={() => setCardType(CardTypes.RESULT)}
                            isSelected={itemInstance.type === CardTypes.RESULT}
                        />
                        <ContextMenu.Button
                            iconName="lightningBolt"
                            text="Action"
                            onClick={() => setCardType(CardTypes.ACTION)}
                            isSelected={itemInstance.type === CardTypes.ACTION}
                        />
                        <ContextMenu.Button
                            iconName="behaviour"
                            text="Behaviour"
                            onClick={() => setCardType(CardTypes.BEHAVIOUR)}
                            isSelected={
                                itemInstance.type === CardTypes.BEHAVIOUR
                            }
                        />
                        <ContextMenu.Button
                            iconName="heart"
                            text="Value"
                            onClick={() => setCardType(CardTypes.VALUE)}
                            isSelected={itemInstance.type === CardTypes.VALUE}
                        />
                    </ContextMenu.Group>
                    <ContextMenu.Button
                        iconName="pencil"
                        text="Edit"
                        onClick={() => {
                            handleClick();
                            closeContextMenu();
                            dispatch(
                                setCardMode({
                                    cardMode: CardMode.EDIT,
                                    currentlyEditedBy: user._id,
                                })
                            );
                        }}
                    />
                    <ContextMenu.Button
                        iconName="paperAirplane"
                        text="Place comment"
                        onClick={() => {
                            openSidebar();
                            setActiveCard({
                                key: itemInstance._key,
                                type: itemInstance.type,
                            });
                            closeContextMenu();
                        }}
                    />
                    <ContextMenu.Button
                        iconName="trash"
                        text="Delete"
                        kbd="Backspace"
                        onClick={() => {
                            handleDelete();
                            closeContextMenu();
                        }}
                    />
                </ContextMenu>
            )}
        </>
    );
};

const CardRender = ({ baseProps, itemInstance }) => {
    const isInView = useIsInView(itemInstance);

    if (!isInView) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    bg: 'white',
                    borderRadius: CARD_META[itemInstance.type].cardRadius,
                }}
            />
        );
    }

    const cardTypes = {
        goal: (
            <Goal
                {...baseProps}
                data={pick(itemInstance, [
                    'title',
                    'assignees',
                    'tags',
                    'dueDate',
                    'status',
                ])}
            />
        ),
        action: (
            <Action
                {...baseProps}
                data={pick(itemInstance, [
                    'title',
                    'assignees',
                    'tags',
                    'dueDate',
                    'status',
                ])}
            />
        ),
        behaviour: (
            <Behaviour
                {...baseProps}
                data={pick(itemInstance, ['title', 'tags'])}
            />
        ),
        value: (
            <Value
                {...baseProps}
                data={pick(itemInstance, ['title', 'tags'])}
            />
        ),
        result: (
            <Result
                {...baseProps}
                data={pick(itemInstance, [
                    'title',
                    'assignees',
                    'tags',
                    'dueDate',
                    'status',
                ])}
            />
        ),
    };

    return <>{cardTypes[itemInstance.type]}</>;
};
