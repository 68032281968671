import { differenceWith } from 'lodash';

interface assigneeChangesOutput {
    removedAssignees: IOrgUser[];
    addedAssignees: IOrgUser[];
}

export function getAssigneeChanges(
    oldDoc: IItemInstance,
    newDoc: IItemInstance
): assigneeChangesOutput {
    let removedAssignees: IOrgUser[], addedAssignees: IOrgUser[];

    const isEqual = (a: IOrgUser, b: IOrgUser) => a._id === b._id;

    if (!oldDoc) {
        removedAssignees = [];
        addedAssignees = newDoc.assignees;
    } else {
        removedAssignees = differenceWith(
            oldDoc.assignees,
            newDoc.assignees,
            isEqual
        );
        addedAssignees = differenceWith(
            newDoc.assignees,
            oldDoc.assignees,
            isEqual
        );
    }

    return { removedAssignees, addedAssignees };
}
