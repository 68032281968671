import { CURSOR_DIMENSION } from '../../constants';

export const CursorSvg: React.FC<{
    color: string;
}> = ({ color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={CURSOR_DIMENSION}
        height={CURSOR_DIMENSION}
        viewBox={`0 0 27 27`}
        fill="none"
    >
        <path
            d="M0.357275 1.60773C0.119937 0.839686 0.8399 0.119723 1.60794 0.357061L25.5219 7.7469C26.4151 8.02292 26.4749 9.26451 25.6123 9.625L14.373 14.3219C14.1245 14.4258 13.9284 14.6256 13.8291 14.8759L9.60828 25.5181C9.26026 26.3956 8.00201 26.3466 7.7233 25.4446L0.357275 1.60773Z"
            fill={`${color}`}
        />
    </svg>
);
