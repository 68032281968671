import React from 'react';
import { Text, ThemeUIStyleObject } from 'theme-ui';

interface LabelProps {
    tag: string;
    variant?: 'xs' | 'sm';
    sx?: ThemeUIStyleObject;
}

export const Tag = React.forwardRef(
    (
        { tag, variant = 'sm', sx }: LabelProps,
        ref: React.Ref<HTMLDivElement>
    ) => (
        <Text
            ref={ref}
            variant={variant}
            sx={{
                display: 'inline-block',
                bg: 'textPrimary',
                color: 'textInverse',
                borderRadius: '2px',
                px: 1,
                flexShrink: 0,
                ...sx,
            }}
        >
            {tag}
        </Text>
    )
);
