import React, { useEffect, useState } from 'react';
import { ReactZoomPanPinchRef } from '@tmi/react-zoom-pan-pinch';
import { DndContainer } from '../containers/dnd.container';
import { PanPinchContainer } from '../containers/pan-pinch.container';
import { Canvas } from './canvas';
import { WhiteboardControls } from './whiteboard-controls';
import { PendingConnection } from './pending-connection';
import { useWhiteboardParams } from '../../../hooks';
import MouseTrap from 'mousetrap';
import { SidebarRouter } from '../containers/sidebar.container';
import { useSidebarAPI, useSidebarData } from '../../../providers/sidebar';
import { MemoComponent } from './memo-component';
import { TOGGLE_SIDEBAR } from '../../../constants/hotkeys';

interface Props {
    data: IWhiteboard;
}

export const Whiteboard: React.FC<Props> = ({ data }) => {
    const { sessionKey } = useWhiteboardParams();
    const { isSidebarOpen } = useSidebarData();
    const { toggleSidebar } = useSidebarAPI();

    useEffect(() => {
        MouseTrap.bind(TOGGLE_SIDEBAR, toggleSidebar);

        return () => {
            MouseTrap.unbind('s');
        };
    }, [toggleSidebar]);

    const [startCreateFrame, setStartCreateFrame] = useState(false);

    return (
        <PanPinchContainer>
            {(props: ReactZoomPanPinchRef) => {
                return (
                    <MemoComponent
                        data={data}
                        sidebarOpen={isSidebarOpen}
                        startCreateFrame={startCreateFrame}
                        sessionKey={sessionKey}
                    >
                        <>
                            <DndContainer>
                                <Canvas
                                    data={data}
                                    startCreateFrame={startCreateFrame}
                                    setStartCreateFrame={setStartCreateFrame}
                                />

                                <PendingConnection />

                                <WhiteboardControls {...props} />
                            </DndContainer>

                            <SidebarRouter
                                zoomToElement={props.zoomToElement}
                                setStartCreateFrame={setStartCreateFrame}
                                startCreateFrame={startCreateFrame}
                            />
                        </>
                    </MemoComponent>
                );
            }}
        </PanPinchContainer>
    );
};
