import React, { useContext, useMemo, useState, createContext } from 'react';

type InitialRefType = React.MutableRefObject<HTMLDivElement> | null;

type State = { initialRef: InitialRefType };
type API = { setInitialRef: (init: InitialRefType) => void };

const ConnectionDataContext = createContext<State>({} as State);
const ConnectionAPIContext = createContext<API>({} as API);

export const ConnectionProvider = ({ children }) => {
    const [initialRef, setInitialRefInner] = useState<InitialRefType>(null);

    const API = useMemo(
        () => ({
            setInitialRef: (initRef: InitialRefType) =>
                setInitialRefInner(initRef),
        }),
        []
    );

    return (
        <ConnectionAPIContext.Provider value={API}>
            <ConnectionDataContext.Provider value={{ initialRef }}>
                {children}
            </ConnectionDataContext.Provider>
        </ConnectionAPIContext.Provider>
    );
};

export const useConnectionData = () => useContext(ConnectionDataContext);
export const useConnectionAPI = () => useContext(ConnectionAPIContext);
