import { Theme } from 'theme-ui';
import {
    colors,
    space,
    fonts,
    fontWeights,
    fontSizes,
    lineHeights,
    radii,
    shadows,
    borders,
    breakpoints,
} from './primitives';
import { text } from './text';
import { forms } from './forms';
import { buttons } from './buttons';
import { images } from './images';
import { badges } from './badges';
import { links } from './links';
import { styles } from './styles';

const theme: Theme = {
    colors,
    space,
    fonts,
    fontWeights,
    fontSizes,
    lineHeights,
    radii,
    shadows,
    borders,
    breakpoints,
    sizes: {
        container: 1120,
    },
    layout: {
        container: {
            px: [16, 20],
        },
    },
    /// Component styles
    text,
    forms,
    buttons,
    images,
    badges,
    links,
    /// Everything else
    styles,
};

export default theme;
