import React, { Dispatch, SetStateAction, useState } from 'react';

export const ModalContext = React.createContext<{
    settingsModal: [boolean, Dispatch<SetStateAction<boolean>>];
    organisationModal: [boolean, Dispatch<SetStateAction<boolean>>];
}>(null!);

export const ModalProvider: React.FC = ({ children }) => {
    const settingsModal = useState(false);
    const organisationModal = useState(false);

    return (
        <ModalContext.Provider value={{ settingsModal, organisationModal }}>
            {children}
        </ModalContext.Provider>
    );
};
