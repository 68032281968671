import { Flex } from 'theme-ui';
import { getAssigneeChanges } from '../../../helpers/get-assignee-changes';
import { Label } from '../../people-picker/label';

interface Props {
    oldDoc: IItemInstance;
    newDoc: IItemInstance;
}

export const ItemAssigneeUpdate = ({ oldDoc, newDoc }: Props) => {
    if (!oldDoc && !newDoc) return null;

    const { removedAssignees, addedAssignees } = getAssigneeChanges(
        oldDoc,
        newDoc
    );

    return (
        <Flex sx={{ alignItems: 'center', gap: 2, mt: 2 }}>
            {addedAssignees.length > 0 &&
                addedAssignees.map(
                    ({ _id, fullName, email, profilePicture }) => (
                        <Label
                            key={_id}
                            fullName={fullName}
                            email={email}
                            profilePicture={profilePicture}
                            variant="xs"
                        />
                    )
                )}
            {removedAssignees.length > 0 &&
                removedAssignees.map(
                    ({ _id, fullName, email, profilePicture }) => (
                        <Label
                            key={_id}
                            fullName={fullName}
                            email={email}
                            profilePicture={profilePicture}
                            strikeThrough
                            variant="xs"
                        />
                    )
                )}
        </Flex>
    );
};
