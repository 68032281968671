import { useState, useContext, useCallback } from 'react';
import { Box, Button, Flex, useThemeUI } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { baseStyles } from '../select/base.styles';
import { typePickerStyles } from '../select/type-picker.styles';
import { Popout } from '../popout';
import {
    useGetTags,
    useGetWhiteboardTeam,
    useTranslationPrefix,
} from '../../hooks';
import { DeleteElementModal } from '../../modules/whiteboard/components/delete-element-modal';
import { useDeleteTag } from '../../api';
import { WhiteboardContext } from '../../modules/whiteboard/containers/whiteboard.container';
import { Tag } from './tag';
import { TagList } from './tag-list';
import { TagRow } from './tag-row';
import { DELETE_TAG_MODAL_ID } from '../../constants';

interface TagPickerProps {
    sx?: ThemeUIStyleObject;
    taglistSx?: ThemeUIStyleObject;
    onChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
    value: any;
    placeholder?: string;
    isEditActive: boolean;
    variant?: 'xs' | 'sm';
    alignLeft?: boolean;
}

export type TagPickerOption = {
    _id: string;
    _key: string;
    name: string;
};

type TagPickerSelectOption = {
    id: string;
    value: string;
    label: JSX.Element;
};

const enrichWithLabel = (
    values: TagPickerOption[]
): TagPickerSelectOption[] => {
    return values
        .map(el => {
            return {
                id: el._id,
                value: el.name,
                label: <Tag key={el._id} tag={el.name} />,
            };
        })
        .sort((a, b) => (a.value > b.value ? 1 : -1));
};

const generateOptionsWithLabels = (
    options: TagPickerOption[],
    onDeleteTag: (key: string) => void
): TagPickerSelectOption[] => {
    return options
        .map(el => {
            return {
                id: el._id,
                value: el.name,
                label: (
                    <TagRow
                        key={el._key || el.name}
                        tagKey={el._key}
                        tagName={el.name}
                        onDelete={onDeleteTag}
                    />
                ),
            };
        })
        .sort((a, b) => (a.value > b.value ? 1 : -1));
};

const TagPickerEdit = ({
    sx,
    onChange,
    value,
    placeholder,
    variant,
    alignLeft = false,
}: TagPickerProps) => {
    const { theme } = useThemeUI();
    const { setPanPinchDisabled } = useContext(WhiteboardContext);
    const { tr } = useTranslationPrefix('whiteboard.cards.generic.tags');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [tagToDelete, setTagToDelete] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { data: teamData } = useGetWhiteboardTeam();

    const teamKey = teamData?._key ?? '';

    const { data: tagsData } = useGetTags();

    const { mutate: deleteTagFromList } = useDeleteTag();

    const handleOnChange = (newValue: any, actionMeta: any) => {
        const values = newValue.map(el => ({ _id: el.id, name: el.value }));
        onChange(values, actionMeta);
    };

    const handleDelete = () => {
        deleteTagFromList({ tagKey: tagToDelete, teamKey });
        handleCloseModal();
    };

    const handleOnDeleteTag = (tagKey: string) => {
        setTagToDelete(tagKey);
        setShowDeleteModal(true);
    };

    const handleCloseModal = () => {
        setShowDeleteModal(false);
    };

    const enableZoomPanPinch = useCallback(() => {
        setPanPinchDisabled(false);
    }, [setPanPinchDisabled]);

    const disableZoomPanPinch = useCallback(() => {
        setPanPinchDisabled(true);
    }, [setPanPinchDisabled]);

    if (!teamKey || !tagsData) return null;

    const optionsWithHeader = [
        {
            label: tr('optionsHeader'),
            options: generateOptionsWithLabels(tagsData, handleOnDeleteTag),
        },
    ];

    const maxLength = 15;

    return (
        <Flex
            sx={{
                position: 'relative',
                ...sx,
                justifyContent: alignLeft ? 'flex-start' : 'center',
            }}
        >
            <Popout
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                onMenuMouseEnter={disableZoomPanPinch}
                onMenuMouseLeave={enableZoomPanPinch}
                onMenuClose={enableZoomPanPinch}
                onMenuUnmount={enableZoomPanPinch}
                target={
                    <AddTagsButton
                        value={value}
                        handleClick={() => setIsOpen(true)}
                        variant={variant}
                    />
                }
                menuPosition={{ top: 0, mt: 0 }}
            >
                <CreatableSelect
                    autoFocus
                    options={optionsWithHeader}
                    isMulti
                    closeMenuOnSelect={false}
                    styles={{
                        ...baseStyles(theme),
                        ...typePickerStyles(theme),
                    }}
                    placeholder={placeholder || tr('placeholder')}
                    onChange={handleOnChange}
                    value={enrichWithLabel(value)}
                    menuIsOpen={true}
                    onInputChange={inputValue =>
                        inputValue.length <= maxLength
                            ? inputValue
                            : inputValue.substr(0, maxLength)
                    }
                />
            </Popout>
            {showDeleteModal && (
                <DeleteElementModal
                    id={DELETE_TAG_MODAL_ID}
                    handleClose={handleCloseModal}
                    handleDelete={handleDelete}
                    type="tag"
                />
            )}
        </Flex>
    );
};

interface AddTagsButtonProps {
    value: TagPickerOption[];
    handleClick: () => void;
    variant?: 'xs' | 'sm';
}

const AddTagsButton = ({
    value,
    handleClick,
    variant = 'sm',
}: AddTagsButtonProps) => {
    if (value.length === 0)
        return (
            <Button
                onClick={handleClick}
                variant="invisible"
                sx={{
                    opacity: 0.7,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Tag tag="Add tag" variant="xs" sx={{ display: 'block' }} />
            </Button>
        );

    return (
        <Button
            onClick={handleClick}
            variant="invisible"
            sx={{
                display: 'block',
                position: 'relative',
                cursor: 'pointer',
                zIndex: 0,

                '&:hover::after': {
                    position: 'absolute',
                    content: '""',
                    bg: 'bgPrimary',
                    top: -1,
                    left: -1,
                    right: -1,
                    bottom: -1,
                    zIndex: -1,
                    borderRadius: 2,
                },
            }}
        >
            <TagList tags={value} variant={variant} />
        </Button>
    );
};

export const TagPicker = (props: TagPickerProps) => {
    const { isEditActive, value = [], sx, taglistSx, variant = 'sm' } = props;

    if (!isEditActive && value.length === 0)
        return (
            <Box
                sx={{
                    height: '16px',
                    visibility: 'hidden',
                    ...sx,
                }}
            />
        );

    if (!isEditActive) {
        return (
            <Box sx={{ position: 'relative', ...sx }}>
                <TagList tags={value} sx={taglistSx} variant={variant} />
            </Box>
        );
    }

    return <TagPickerEdit {...props} />;
};
