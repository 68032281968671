import { Theme } from 'theme-ui';

export const baseStyles = (theme: Theme) => ({
    dropdownIndicator: base => ({
        ...base,
        display: 'none',
    }),
    indicatorSeparator: base => ({
        ...base,
        display: 'none',
    }),
    menu: base => ({
        ...base,
        margin: 0,
        boxShadow: 'none',
        borderTop: theme!.borders![1],
        borderLeft: theme!.borders![1],
        borderRight: theme!.borders![1],
        borderBottom: theme!.borders![1],
        borderTopColor: 'transparent',
        borderLeftColor: theme!.colors!.bgPrimary,
        borderRightColor: theme!.colors!.bgPrimary,
        borderBottomColor: theme!.colors!.bgPrimary,
        borderRadius: `${theme!.radii![0]} ${theme!.radii![0]} ${
            theme!.radii![2]
        } ${theme!.radii![2]}`,
    }),
    multiValue: base => ({
        ...base,
        background: 'none',
    }),
    control: (base, state) => ({
        ...base,
        borderTop: theme!.borders![1],
        borderLeft: theme!.borders![1],
        borderRight: theme!.borders![1],
        borderBottom: theme!.borders![1],
        borderTopColor: theme!.colors!.bgPrimary,
        borderLeftColor: theme!.colors!.bgPrimary,
        borderRightColor: theme!.colors!.bgPrimary,
        borderBottomColor: state.menuIsOpen
            ? 'transparent'
            : theme!.colors!.bgPrimary,
        boxShadow: 'none',
        borderRadius: state.menuIsOpen
            ? `${theme!.radii![2]} ${theme!.radii![2]} ${theme!.radii![0]} ${
                  theme!.radii![0]
              }`
            : theme!.radii![2],
    }),
    group: base => ({
        ...base,
        padding: 0,
    }),
});
