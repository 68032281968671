import { useQuery } from 'react-query';
import { getTags } from '../../api';
import { tagsQueryKey } from '../../constants';
import { useGetWhiteboardTeam } from '.';

export const useGetTags = () => {
    const { data } = useGetWhiteboardTeam();
    const teamKey = data?._key || '';

    return useQuery<ITag[], Error>(
        [tagsQueryKey, { key: teamKey }],
        () => getTags(teamKey),
        {
            enabled: !!teamKey,
        }
    );
};
