import { useQuery } from 'react-query';
import { getTeamMembers } from '../../api';
import { getTeamMembersQueryKey } from '../../constants';
import { useGetWhiteboardTeam } from '.';

export const useGetTeamMembers = () => {
    const { data } = useGetWhiteboardTeam();
    const teamKey = data?._key ?? '';

    return useQuery<IOrgUser[], Error>(
        [getTeamMembersQueryKey, { key: teamKey }],
        () => getTeamMembers(teamKey),
        {
            enabled: !!teamKey,
        }
    );
};
