import {
    API_BASE_URL,
    API_ROUTE_MEDIA,
    API_ROUTE_UPLOAD,
    API_BUCKET_DEFAULT,
} from './../constants/api';
import axios from 'axios';

interface IUploadResponse {
    url: string;
}

export const uploadFile = (file: File): Promise<IUploadResponse> => {
    const formData = new FormData();
    formData.append('file', file);

    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_MEDIA}/${API_ROUTE_UPLOAD}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const deleteFile = (url: string): Promise<any> => {
    const UPLOAD_STRING = 'upload';
    const domainRemoved = url.split(UPLOAD_STRING)[1];

    const normalizedUrl = `${UPLOAD_STRING}${domainRemoved}`;

    return axios
        .delete(
            `${API_BASE_URL}/${API_ROUTE_MEDIA}/${API_BUCKET_DEFAULT}/${normalizedUrl}`
        )
        .then(res => res)
        .catch(err => {
            throw err;
        });
};
