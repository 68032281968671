import React from 'react';
import { Flex } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';

interface DropdownProps {
    children: React.ReactElement | React.ReactElement[];
    sx?: ThemeUIStyleObject;
}

export const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
    ({ children, sx}, ref) => (
        <Flex
            ref={ref}
            sx={{
                p: 2,
                borderRadius: 3,
                backgroundColor: 'bgSecondary',
                boxShadow: 'floatingBar',
                flexDirection: 'column',
                ...sx
            }}
        >
            <Flex sx={{flexDirection: 'column' }}>{children}</Flex>
        </Flex>
    )
);
