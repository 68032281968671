import axios, { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { commentsQueryKey } from '../constants';

import { API_BASE_URL, API_ROUTE_COMMENT } from '../constants/api';
import { createQueryKey } from '../helpers';
import {
    useAppSelector,
    useToast,
    useUpdateCache,
    useWhiteboardParams,
} from '../hooks';
import { selectActiveOrg } from '../redux/reducers';

const getCommentsForItem = ({
    _key,
    organisationKey,
}: {
    _key: string;
    organisationKey: string;
}): Promise<IComment[]> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_COMMENT}/all/${_key}/${organisationKey}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const createComment = (
    input: Pick<IComment, 'body'> & {
        itemInstanceId: string;
    },
    whiteboardKey: string
): Promise<IComment> => {
    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_COMMENT}/${whiteboardKey}`, {
            ...input,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const deleteComment = (input: {
    commentKey: string;
    itemKey: string;
}): Promise<IComment> => {
    return axios
        .delete(
            `${API_BASE_URL}/${API_ROUTE_COMMENT}/${input.commentKey}/${input.itemKey}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const updateComment = (
    input: Pick<IComment, '_key' | '_id' | 'body'>,
    whiteboardKey: string,
    itemKey: string
): Promise<IComment> => {
    const { _key } = input;
    console.log(input);
    return axios
        .put(
            `${API_BASE_URL}/${API_ROUTE_COMMENT}/${_key}/${whiteboardKey}/${itemKey}`,
            {
                body: input.body,
                _id: input._id,
            }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const useGetCommentsForItem = ({ _key }: { _key: string }) => {
    const org = useAppSelector(selectActiveOrg);
    return useQuery<IComment[], AxiosError>(
        createQueryKey(commentsQueryKey, _key),
        () => getCommentsForItem({ _key, organisationKey: org._key }),
        {
            enabled: !!_key,
        }
    );
};

export const useCreateComment = (itemKey: string) => {
    const showToast = useToast();
    const { whiteboardKey } = useWhiteboardParams();

    const { t } = useTranslation();

    const addToCache = useUpdateCache<IComment[]>(commentsQueryKey);

    const updater = useCallback(
        (input: Pick<IComment, 'body'> & { itemInstanceId: string }) => {
            return createComment(input, whiteboardKey);
        },
        [whiteboardKey]
    );

    return useMutation(updater, {
        onSuccess: async res => {
            addToCache(res, itemKey);
            return null;
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};

export const useDeleteComment = (itemKey: string) => {
    const queryClient = useQueryClient();
    const showToast = useToast();

    const { t } = useTranslation();

    const qk = createQueryKey(commentsQueryKey, itemKey);

    return useMutation(deleteComment, {
        onSuccess: async res => {
            queryClient.invalidateQueries(qk);
            return null;
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};

export const useUpdateComment = (itemKey: string, cb: () => void) => {
    const queryClient = useQueryClient();
    const showToast = useToast();
    const { whiteboardKey } = useWhiteboardParams();

    const { t } = useTranslation();

    const qk = createQueryKey(commentsQueryKey, itemKey);

    const updater = useCallback(
        (input: Pick<IComment, 'body' | '_key' | '_id'>) => {
            return updateComment(input, whiteboardKey, itemKey);
        },
        [whiteboardKey, itemKey]
    );

    return useMutation(updater, {
        onMutate: res => {
            queryClient.setQueryData(qk, previousCache => {
                if (!previousCache) {
                    return res;
                }

                if (Array.isArray(previousCache)) {
                    const commentToUpdate = previousCache.findIndex(
                        c => c._id === res._id
                    );

                    if (commentToUpdate > -1) {
                        const newCache = [...previousCache];
                        newCache[commentToUpdate] = {
                            ...newCache[commentToUpdate],
                            body: res.body,
                        };
                        return newCache;
                    }

                    return previousCache;
                }
            });
            cb();
        },
        onSettled: async res => {
            queryClient.invalidateQueries(qk);
            return null;
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};
