export enum ORGANISATION_TYPE {
    PERSONAL = 'personal',
    ORGANISATION = 'organisation',
}

export enum RoleType {
    ADMIN = 'Admin',
    MEMBER = 'Member',
}

export enum CardMode {
    EDIT = 'edit',
    VIEW = 'view',
    LAYOUT = 'layout',
    LOCKED = 'locked',
}

export enum ConnectionMode {
    EDIT = 'edit',
    VIEW = 'view',
}
