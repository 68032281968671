import { Flex, Text, IconButton, Button } from '@theme-ui/components';
import { Icon } from '@tmi/component-library/src';
import { useHistory } from 'react-router';
import { WHITEBOARD_ROUTE } from '../../../../constants';
import {
    useDropdown,
    useGetUserWhiteboards,
    useWhiteboardParams,
} from '../../../../hooks';

export const OrganisationSwitcher = () => {
    const { data } = useGetUserWhiteboards();
    const { whiteboardKey } = useWhiteboardParams();
    const history = useHistory();
    const {
        Dropdown,
        dropdownProps,
        positionalRef,
        toggleDropdown,
    } = useDropdown({ placement: 'bottom-start', offset: { x: 0, y: 8 } });

    if (!data) return null;

    const whiteboardName = data.find(
        whiteboard => whiteboard._key === whiteboardKey
    )?.name;

    const handleWhiteboardSwitch = whiteboard => {
        history.push(`/${WHITEBOARD_ROUTE}/${whiteboard._key}`);
    };

    return (
        <>
            <Flex
                sx={{
                    mt: 6,
                    px: 4,
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Text variant="labelSm" sx={{ color: 'textSecondary' }}>
                    {whiteboardName}
                </Text>
                <IconButton
                    onClick={toggleDropdown}
                    ref={positionalRef}
                    sx={{ color: 'textSecondary', ml: 1 }}
                >
                    <Icon iconName="chevronDown" size={16} />
                </IconButton>
            </Flex>

            <Dropdown {...dropdownProps} sx={{}}>
                {data?.map(whiteboard => (
                    <Button
                        onClick={() => handleWhiteboardSwitch(whiteboard)}
                        key={whiteboard._id}
                        variant="dropdown"
                    >
                        {whiteboard.name}
                    </Button>
                ))}
            </Dropdown>
        </>
    );
};
