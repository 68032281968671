import { CARD_META } from '../../modules/whiteboard/constants/card';

const getLeftBound = (items: IItemInstance[]): number => {
    const mostLeftItem = items.reduce((prev, cur) =>
        prev.position.x > cur.position.x ? cur : prev
    );

    return mostLeftItem.position.x;
};

const getRightBound = (items: IItemInstance[]): number => {
    const mostRightItem = items.reduce((prev, cur) =>
        prev.position.x + CARD_META[prev.type].width * prev.cardScale <
        cur.position.x + CARD_META[cur.type].width * cur.cardScale
            ? cur
            : prev
    );
    return (
        mostRightItem.position.x +
        CARD_META[mostRightItem.type].width * mostRightItem.cardScale
    );
};

const getTopBound = (items: IItemInstance[]): number => {
    const mostTopItem = items.reduce((prev, cur) =>
        prev.position.y > cur.position.y ? cur : prev
    );

    return mostTopItem.position.y;
};

const getBottomBound = (items: IItemInstance[]): number => {
    const mostBottomItem = items.reduce((prev, cur) =>
        prev.position.y + CARD_META[prev.type].height * prev.cardScale <
        cur.position.y + CARD_META[cur.type].height * cur.cardScale
            ? cur
            : prev
    );

    return (
        mostBottomItem.position.y +
        CARD_META[mostBottomItem.type].height * mostBottomItem.cardScale
    );
};

const getBounds = (items: IItemInstance[]): BoundsType | null => {
    if (items.length === 0) {
        return null;
    }

    return {
        left: getLeftBound(items),
        right: getRightBound(items),
        top: getTopBound(items),
        bottom: getBottomBound(items),
    };
};

export { getLeftBound, getRightBound, getTopBound, getBottomBound, getBounds };
