import { getConsistentRandomValueFromArray } from './general';

const colorSpectrum = ['accent'];

export const getUserColor = (emailAddress: string) =>
    getConsistentRandomValueFromArray(emailAddress, colorSpectrum);

const black = '#0A0B0D';
const white = '#FFFFFF';

const cursorColors = [
    { bg: '#AD094E', text: white },
    { bg: '#FF6A7C', text: black },
    { bg: '#AAEE74', text: black },
    { bg: '#95E5CD', text: black },
    { bg: '#A3F4FF', text: black },
    { bg: '#AEC9FF', text: black },
    { bg: '#5724E5', text: white },
    { bg: '#B59BFF', text: black },
    { bg: '#FFDD9B', text: black },
    { bg: '#FFF1A3', text: black },
    { bg: '#FF7F6D', text: black },
    { bg: '#FF7324', text: black },
    { bg: '#9B2279', text: white },
    { bg: '#FFA5E6', text: black },
];

export const getCursorColor = (emailAddress: string) =>
    getConsistentRandomValueFromArray(emailAddress, cursorColors);
