import axios, { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { activityQueryKey } from '../constants';

import { API_BASE_URL, API_ROUTE_ACTIVITY } from '../constants/api';
import { createQueryKey } from '../helpers';
import {
    useGetWhiteboardTeam,
    useToast,
    useTranslationPrefix,
    useWhiteboardParams,
} from '../hooks';

const getActivityForUser = ({
    teamKey,
    whiteboardKey,
}: {
    teamKey: string;
    whiteboardKey: string;
}): Promise<IActivityData[]> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_ACTIVITY}/${teamKey}/${whiteboardKey}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const markAsRead = ({
    notificationId,
}: {
    notificationId: string;
}): Promise<IActivityData[]> => {
    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_ACTIVITY}`, {
            _id: notificationId,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const markAllAsRead = ({
    notificationIds,
}: {
    notificationIds: string[];
}): Promise<IActivityData[]> => {
    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_ACTIVITY}/all`, {
            _ids: notificationIds,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const useGetActivity = () => {
    const { whiteboardKey } = useWhiteboardParams();
    const { data } = useGetWhiteboardTeam();

    const teamKey = data?._key ?? '';
    return useQuery<IActivityData[], AxiosError>(
        createQueryKey(activityQueryKey, whiteboardKey),
        () => getActivityForUser({ teamKey, whiteboardKey }),
        {
            enabled: !!teamKey && !!whiteboardKey,
        }
    );
};

export const useMarkAsRead = () => {
    const showToast = useToast();

    const queryClient = useQueryClient();
    const { t } = useTranslationPrefix();
    const { whiteboardKey } = useWhiteboardParams();

    const queryKey = createQueryKey(activityQueryKey, whiteboardKey);

    const updateCache = useCallback(
        (notificationId: string) => {
            queryClient.setQueryData<IActivityData[] | undefined>(
                queryKey,
                previousCache => {
                    if (!previousCache) {
                        return [];
                    }

                    const toUpdateNotification = previousCache.findIndex(
                        notification => notification._id === notificationId
                    );
                    if (toUpdateNotification > -1) {
                        previousCache[
                            toUpdateNotification
                        ].activityMeta.hasRead = true;
                    }

                    return [...previousCache];
                }
            );
        },
        [queryClient, queryKey]
    );

    return useMutation(markAsRead, {
        onMutate: async res => {
            updateCache(res.notificationId);
        },

        onError: err => {
            queryClient.invalidateQueries(queryKey);

            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};

export const useMarkAllAsRead = () => {
    const showToast = useToast();

    const queryClient = useQueryClient();
    const { t } = useTranslationPrefix();
    const { whiteboardKey } = useWhiteboardParams();

    const queryKey = createQueryKey(activityQueryKey, whiteboardKey);

    return useMutation(markAllAsRead, {
        onSuccess: async res => {
            queryClient.invalidateQueries(queryKey);
            return null;
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};
