import { useContext } from 'react';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { WhiteboardButtonWrapper } from './whiteboard-button-wrapper';
import { ToolbarButton } from './toolbar-button';
import { useTranslation } from 'react-i18next';
import { WhiteboardContext } from '../containers/whiteboard.container';

interface ToolbarProps {
    sx?: ThemeUIStyleObject;
}

export const Toolbar = ({ sx }: ToolbarProps) => {
    const { initiateAddCard } = useContext(WhiteboardContext);
    const { t } = useTranslation();

    // TODO: map over the base component
    return (
        <WhiteboardButtonWrapper
            sx={{
                alignItems: 'center',
                gap: 2,
                py: 4,
                px: 6,
                ...sx,
            }}
        >
            <ToolbarButton
                icon="flag"
                cardType="goal"
                onClick={() => initiateAddCard('goal')}
                dataTour="button-goal"
                tooltip={t('whiteboard.toolbar.tooltips.goal')}
                sx={{ p: 3, borderRadius: 5 }}
            />
            <ToolbarButton
                icon="checkCircle"
                cardType="result"
                onClick={() => initiateAddCard('result')}
                dataTour="button-result"
                tooltip={t('whiteboard.toolbar.tooltips.result')}
                sx={{ p: 2, borderRadius: 2 }}
            />
            <ToolbarButton
                icon="lightningBolt"
                cardType="action"
                onClick={() => initiateAddCard('action')}
                dataTour="button-action"
                tooltip={t('whiteboard.toolbar.tooltips.action')}
                sx={{ py: 1, px: 4, borderRadius: 2 }}
            />
            <ToolbarButton
                icon="behaviour"
                cardType="behaviour"
                onClick={() => initiateAddCard('behaviour')}
                dataTour="button-behaviour"
                tooltip={t('whiteboard.toolbar.tooltips.behaviour')}
                sx={{ py: 1, px: 4, borderRadius: 5 }}
            />
            <ToolbarButton
                icon="heart"
                cardType="value"
                onClick={() => initiateAddCard('value')}
                dataTour="button-value"
                tooltip={t('whiteboard.toolbar.tooltips.value')}
                sx={{ py: 1, px: 3, borderRadius: 1 }}
            />
        </WhiteboardButtonWrapper>
    );
};
