import React, {
    useCallback,
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
    RefObject,
} from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';

import { Whiteboard } from '../components/whiteboard';
import { selectActiveOrg } from '../../../redux/reducers';
import { createItemInstance } from '../../../api';
import { EVENT_ADD_CARD, getWhiteboardQueryKey } from '../../../constants';
import {
    useAppSelector,
    useToast,
    useKeyPress,
    useTranslationPrefix,
    useGetWhiteboard,
    useHandleDiffOrg,
    useRefetchTrigger,
} from '../../../hooks';
import { ErrorPage } from '../../../components/error';
import { Loader } from '../../../components';
import { CARD_META } from '../constants/card';
import { getAdjustedCoordinates } from '../../../helpers';
import { useFilterAPI, useFilterData } from '../../../providers/filter';
import { filter } from '../../../helpers/filter/filter';
import { CardMode } from '../../../types/enums';

interface IWhiteboardContext {
    dragDisabled: boolean;
    setDragDisabled: Dispatch<SetStateAction<boolean>>;
    panPinchDisabled: boolean;
    setPanPinchDisabled: Dispatch<SetStateAction<boolean>>;
    initiateAddCard: (cardType: CardType) => void;
    handleAddCard: (
        event: React.MouseEvent<HTMLElement>,
        ref: RefObject<HTMLDivElement>,
        scale: number
    ) => void;
    pendingCard: CardType | null;
}

export const WhiteboardContext = React.createContext<IWhiteboardContext>({
    dragDisabled: false,
    setDragDisabled: state => state,
    panPinchDisabled: false,
    setPanPinchDisabled: state => state,
    initiateAddCard: () => {},
    handleAddCard: () => {},
    pendingCard: null,
});

type WhiteboardContainerProps = {
    whiteboardKey: string;
};

export type HandleItemUpdateType = (item: IItemInstance) => void;
export type HandleAddConnectionType = (
    startItemId: string,
    endItemId: string,
    whiteboardKey: string
) => void;

export type HandeItemDeleteType = (itemId: string) => void;

export const WhiteboardContainer: React.FC<WhiteboardContainerProps> = ({
    whiteboardKey,
}) => {
    const { t } = useTranslationPrefix('whiteboard.modals.confirmation');

    useHandleDiffOrg();
    const showToast = useToast();
    const { data, error, isLoading, refetch } = useGetWhiteboard();
    const activeOrg = useAppSelector(selectActiveOrg);
    const escapeKey = useKeyPress('Escape');

    const { filterState } = useFilterData();
    const { setSelectedCards } = useFilterAPI();

    useRefetchTrigger({ key: whiteboardKey, scope: 'whiteboard', refetch });

    const [dragDisabled, setDragDisabled] = useState(false);
    const [panPinchDisabled, setPanPinchDisabled] = useState(false);
    const [pendingCard, setPendingCard] = useState<CardType | null>(null);

    useEffect(() => {
        if (pendingCard && escapeKey) {
            setPendingCard(null);
        }
    }, [pendingCard, escapeKey]);

    useEffect(() => {
        if (isLoading) return;
        setSelectedCards(filter(data?.items ?? [], filterState));
    }, [data, filterState, setSelectedCards, isLoading]);

    const queryClient = useQueryClient();

    const addResponseToCache = useCallback(
        (res: Partial<IWhiteboard>) => {
            queryClient.setQueryData<IWhiteboard | undefined>(
                [getWhiteboardQueryKey, { key: whiteboardKey }],
                prev => {
                    if (!prev) return;
                    return {
                        ...prev,
                        items: [...(prev.items ?? []), ...(res.items ?? [])],
                        connections: [
                            ...(prev.connections ?? []),
                            ...(res.connections ?? []),
                        ],
                    };
                }
            );
        },
        [queryClient, whiteboardKey]
    );

    const { mutate: createComponentMutation } = useMutation(
        createItemInstance,
        {
            onSuccess: res => {
                // Update local cache manually, no refetch required
                addResponseToCache({
                    items: [{ ...res, cardMode: CardMode.EDIT }],
                });
            },
            onError: err => {
                console.error(err);
                showToast({
                    type: 'error',
                    message: t('general.toasts.createCardFail'),
                });
            },
            onSettled: () => {
                setPendingCard(null);
                setPanPinchDisabled(false);
            },
        }
    );

    // TODO: Use setPanPinchDisaled explicitly
    useEffect(() => {
        setPanPinchDisabled(dragDisabled);
    }, [setPanPinchDisabled, dragDisabled]);

    const handleAddCard = useCallback(
        (
            event: React.MouseEvent<HTMLElement>,
            ref: RefObject<HTMLDivElement>,
            scale: number
        ) => {
            const { clientX, clientY } = event;

            if (!!pendingCard) {
                const { x, y } = getAdjustedCoordinates(
                    clientX,
                    clientY,
                    scale,
                    ref
                );

                const left = x - CARD_META[pendingCard].width / 2;
                const top = y - CARD_META[pendingCard].height / 2;

                const newCard: INewInstance = {
                    position: { x: left, y: top },
                    type: pendingCard,
                    cardScale: 1,
                    title: '',
                    dueDate: '',
                    status: '',
                };

                window.analytics.track(EVENT_ADD_CARD, {
                    type: newCard.type,
                    whiteboardKey,
                });

                createComponentMutation({ item: newCard, whiteboardKey });
            }
        },
        [createComponentMutation, pendingCard, whiteboardKey]
    );

    const initiateAddCard = useCallback((cardType: CardType) => {
        setPanPinchDisabled(true);
        setPendingCard(cardType);
    }, []);

    if (error) {
        return <ErrorPage axiosError={error.response} />;
    }

    if (isLoading) {
        return <Loader />;
    }
    if (!whiteboardKey || !data) return null;
    if (!data || !activeOrg) {
        return null;
    }

    const value: IWhiteboardContext = {
        dragDisabled,
        setDragDisabled,
        panPinchDisabled,
        setPanPinchDisabled,
        handleAddCard,
        initiateAddCard,
        pendingCard,
    };

    return (
        <WhiteboardContext.Provider value={value}>
            <Helmet>
                <title>{data?.whiteboard.name}</title>
            </Helmet>
            <Whiteboard data={data} />
        </WhiteboardContext.Provider>
    );
};

export default WhiteboardContainer;
