import React from 'react';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { Textarea } from 'theme-ui';

interface TitleFieldProps
    extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'ref'> {
    sx?: ThemeUIStyleObject;
    variant?: string;
    onBlur?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
    onChange: (event: React.FormEvent<HTMLTextAreaElement>) => void;
    value: string;
    placeholder?: string;
}

export const TitleField = React.forwardRef<
    HTMLTextAreaElement,
    TitleFieldProps
>(({ as, onBlur, onChange, value, placeholder, sx, ...props }, ref) => {
    return (
        <Textarea
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            sx={{
                overflow: 'hidden',
                hyphens: 'auto',
                lineHeight: '130%',
                ...sx,
            }}
            {...props}
        />
    );
});
