import {
    Flex,
    Box,
    Button,
    Text,
    Textarea,
    Paragraph,
    IconButton,
} from '@theme-ui/components';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { Icon } from '@tmi/component-library/src';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useGetActivity,
    useMarkAllAsRead,
    useMarkAsRead,
} from '../../../../api';
import {
    useCreateComment,
    useDeleteComment,
    useGetCommentsForItem,
    useUpdateComment,
} from '../../../../api/comment';
import { Avatar } from '../../../../components';
import { NotificationIndicator } from '../../../../components/notification-indicator';
import { DELETE_COMMENT_MODAL_ID } from '../../../../constants';
import { useAppSelector, useDropdown } from '../../../../hooks';
import { getUserForActiveOrg } from '../../../../redux/reducers';
import { DeleteElementModal } from '../delete-element-modal';

const getNotification = (
    commentId: string,
    notifications?: IActivityData[]
) => {
    if (!notifications || notifications.length === 0) {
        return;
    }
    return notifications.find(({ _id }) => _id === commentId);
};
interface Props {
    itemKey: string;
    itemInstanceId: string;
    sx?: ThemeUIStyleObject;
}
export const CommentContainer = ({ itemKey, itemInstanceId, sx }: Props) => {
    const [comment, setComment] = useState('');
    const { t } = useTranslation();

    const { data } = useGetCommentsForItem({ _key: itemKey });
    const { data: notifications } = useGetActivity();

    const notificationIds = notifications
        ?.filter(
            ({ activityMeta }) =>
                !activityMeta.hasRead && activityMeta.parent === itemInstanceId
        )
        .map(({ _id }) => _id);

    const { mutate: markAsRead } = useMarkAsRead();
    const { mutate: markAll } = useMarkAllAsRead();

    const handleMarkAll = useCallback(() => {
        if (notificationIds && notificationIds.length > 0) {
            markAll({ notificationIds });
        }
    }, [markAll, notificationIds]);

    const hasUnreadNotifications = (notificationIds ?? []).length > 0;

    const handleMarkAsRead = useCallback(
        (notificationId: string | undefined) => {
            if (!notificationId) return;
            markAsRead({ notificationId });
        },
        [markAsRead]
    );

    const { mutate } = useCreateComment(itemKey);

    if (!data) return null;

    return (
        <Flex
            sx={{
                position: 'relative',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'auto',
                ...sx,
            }}
        >
            <Flex
                sx={{
                    px: 4,
                    py: 3,
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: 'bgPrimary',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Paragraph variant="labelSm">
                    {t('sidebar.card.comments.title')}
                </Paragraph>

                {hasUnreadNotifications && (
                    <Button onClick={handleMarkAll} variant="subtle">
                        {t('sidebar.activity.actions.markAsRead')}
                    </Button>
                )}
            </Flex>

            <Flex
                sx={{
                    overflowY: 'auto',
                    flex: 1,
                    flexDirection: 'column',
                }}
            >
                <Box>
                    {data.map(comment => {
                        //@TODO temp fix when member is removed
                        if (!comment?.user) return null;
                        const notification = getNotification(
                            comment._id,
                            notifications
                        );

                        const hasRead =
                            notification && !notification.activityMeta.hasRead
                                ? false
                                : true;

                        return (
                            <Comment
                                onClick={() => {
                                    handleMarkAsRead(notification?._id);
                                }}
                                hasRead={hasRead}
                                comment={comment}
                                key={comment._key}
                                itemKey={itemKey}
                            />
                        );
                    })}
                </Box>
            </Flex>

            <Flex
                sx={{
                    alignItems: 'center',
                    bg: 'bgPrimary',
                    py: 4,
                    px: 6,
                }}
            >
                <Textarea
                    sx={{
                        bg: 'white',
                        p: 2,
                    }}
                    value={comment}
                    onChange={e => {
                        setComment(e.currentTarget.value);
                    }}
                />
                <IconButton
                    sx={{
                        ml: 4,
                        bg: 'actionPrimary',
                        height: '40px',
                        width: '40px',
                        borderRadius: '100%',
                        color: 'textInverse',
                        flexShrink: 0,
                    }}
                    onClick={() => {
                        mutate({ body: comment, itemInstanceId });

                        setComment('');
                    }}
                >
                    <Box
                        sx={{
                            flexShrink: 0,
                            pb: 1,
                            pl: 1,
                        }}
                    >
                        <Icon iconName="paperAirplane" fillStyle="solid" />
                    </Box>
                </IconButton>
            </Flex>
        </Flex>
    );
};

interface CommentProps {
    onClick: () => void;
    hasRead: boolean;
    comment: IComment;
    itemKey: string;
}

const Comment = ({ comment, itemKey, hasRead, onClick }: CommentProps) => {
    const { _id } = useAppSelector(getUserForActiveOrg);

    const showActions = comment.user._id === _id;
    const [edit, setEdit] = useState(comment.body);
    const [startEdit, setStartEdit] = useState(false);
    const [showDeleteItemModal, setShowDelete] = useState(false);

    const handleCloseModal = () => {
        setShowDelete(false);
    };

    const { user, createdAt, body } = comment;
    const { profilePicture, email, fullName } = user;

    const {
        Dropdown,
        dropdownProps,
        positionalRef,
        toggleDropdown,
    } = useDropdown({ placement: 'bottom-end', offset: { x: 8, y: 16 } });

    const handleUpdated = () => setStartEdit(false);

    const { mutate } = useDeleteComment(itemKey);
    const { mutate: updateComment } = useUpdateComment(itemKey, handleUpdated);

    const menuActions = [
        {
            name: 'Edit',
            icon: 'pencilAlt',
            onClick: () => {
                setStartEdit(true);
            },
        },

        {
            name: 'Delete',
            icon: 'trash',
            onClick: () => {
                setShowDelete(true);
            },
        },
    ];

    return (
        <Flex
            sx={{
                borderBottom: 1,
                borderColor: 'bgPrimary',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            {!hasRead && (
                <NotificationIndicator
                    onClick={onClick}
                    sx={{ position: 'absolute', top: 3, right: 5 }}
                />
            )}

            <Box
                sx={{
                    px: 6,
                    py: 3,
                    flex: 1,
                }}
            >
                <Box>
                    <Flex sx={{ alignItems: 'center' }}>
                        <Avatar
                            pictureUrl={profilePicture}
                            email={email}
                            fullName={fullName}
                        />
                        <Text
                            variant="labelXs"
                            sx={{ ml: 2, color: 'textSecondary' }}
                        >
                            {fullName}
                        </Text>
                        <Text
                            variant="labelXs"
                            sx={{
                                ml: 1,
                                color: 'textSecondary',
                                fontWeight: 'regular',
                            }}
                        >
                            {format(new Date(createdAt), 'dd MMMM HH:mm')}
                        </Text>
                    </Flex>
                </Box>

                {!startEdit && (
                    <Paragraph variant="xs" sx={{ mt: 2 }}>
                        {body}
                    </Paragraph>
                )}
                {startEdit && (
                    <Box>
                        <Textarea
                            sx={{
                                bg: 'white',
                                p: 2,
                                mb: 2,
                            }}
                            value={edit}
                            onChange={e => {
                                setEdit(e.currentTarget.value);
                            }}
                        />

                        <Button
                            onClick={() => {
                                updateComment({
                                    _id: comment._id,
                                    _key: comment._key,
                                    body: edit,
                                });
                            }}
                            sx={{
                                mr: 2,
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => setStartEdit(false)}
                        >
                            Cancel
                        </Button>
                    </Box>
                )}
            </Box>
            {showActions && (
                <>
                    <IconButton
                        ref={positionalRef}
                        onClick={toggleDropdown}
                        sx={{
                            mr: 4,
                            borderRadius: 1,
                            p: 1,
                            width: '16px',
                            height: '16px',
                        }}
                    >
                        <Icon iconName="dotsVertical" size={16} />
                    </IconButton>
                    <Dropdown
                        {...dropdownProps}
                        sx={{ minWidth: '150px', mr: 2 }}
                    >
                        <ActionDropdownContent actions={menuActions} />
                    </Dropdown>
                </>
            )}
            {showDeleteItemModal && (
                <DeleteElementModal
                    id={DELETE_COMMENT_MODAL_ID}
                    handleClose={handleCloseModal}
                    handleDelete={() => {
                        mutate({ commentKey: comment._key, itemKey });
                        handleCloseModal();
                    }}
                    type="comment"
                />
            )}
        </Flex>
    );
};

const ActionDropdownContent = ({ actions }) => {
    return (
        <>
            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }}
            >
                {actions &&
                    actions?.map(({ icon, name, onClick, show = true }) => (
                        <div key={name}>
                            {show && (
                                <Flex
                                    onClick={onClick}
                                    sx={{
                                        alignItems: 'center',
                                        color: 'textPrimary',
                                        p: 2,

                                        borderRadius: 1,
                                        ':hover': {
                                            bg: 'bgPrimary',
                                        },
                                    }}
                                >
                                    {icon && (
                                        <Icon
                                            iconName={icon}
                                            fillStyle="solid"
                                            size={16}
                                        />
                                    )}
                                    <Text variant="sm" sx={{ ml: 2 }}>
                                        {name}
                                    </Text>
                                </Flex>
                            )}
                        </div>
                    ))}
            </Flex>
        </>
    );
};
