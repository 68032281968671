import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const devConfig = {
    defaultOptions: {
        queries: { refetchOnWindowFocus: false },
    },
};

const prodConfig = {};

const config = process.env.NODE_ENV === 'development' ? devConfig : prodConfig;

const queryClient = new QueryClient(config);

export const QueryProvider: React.FC = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
};
