import { Flex, Paragraph, IconButton } from '@theme-ui/components';
import { Icon } from '@tmi/component-library/src';
import { ThemeUIStyleObject } from 'theme-ui';

interface Props {
    isHost: boolean;
    nextDisabled: boolean;
    prevDisabled: boolean;
    handlePrev: () => void;
    handleNext: () => void;
    text: string;
    sx?: ThemeUIStyleObject;
}

const iconButtonSx = {
    color: 'textInverse',
    borderRadius: 5,

    '&:hover:enabled': {
        bg: 'bgInverse',
    },
};

export const SessionNavigate = ({
    handlePrev,
    handleNext,
    isHost,
    nextDisabled,
    prevDisabled,
    text,
    sx,
}: Props) => {
    return (
        <Flex
            sx={{
                ...sx,
                alignItems: 'center',
                bg: 'bgInverse',
                borderRadius: 5,
            }}
        >
            {isHost && (
                <IconButton
                    sx={iconButtonSx}
                    onClick={handlePrev}
                    disabled={prevDisabled}
                >
                    <Icon iconName="chevronLeft" fillStyle="solid" />
                </IconButton>
            )}
            <Paragraph
                variant="labelSm"
                sx={{ color: 'textInverse', mx: isHost ? 1 : 4 }}
            >
                {text}
            </Paragraph>
            {isHost && (
                <IconButton
                    sx={iconButtonSx}
                    onClick={handleNext}
                    disabled={nextDisabled}
                >
                    <Icon iconName="chevronRight" fillStyle="solid" />
                </IconButton>
            )}
        </Flex>
    );
};
