import { useEffect } from 'react';
import { useAppSelector } from '.';
import { getUserForActiveOrg } from '../redux/reducers';
import { useHandleUpdateWhiteboard } from './websocket/use-ws-whiteboard';

type UseRefetchTriggerProps = {
    refetch: () => void;
    scope: string;
    key: string;
};

export const useRefetchTrigger = ({
    key,
    scope,
    refetch,
}: UseRefetchTriggerProps) => {
    const { lastMessage } = useHandleUpdateWhiteboard({
        scope,
        key,
    });

    const { _id } = useAppSelector(getUserForActiveOrg);

    useEffect(() => {
        if (lastMessage === null) return;

        refetch();
    }, [key, lastMessage, refetch, _id, scope]);
};
