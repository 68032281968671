import { useCallback, useEffect, useMemo } from 'react';
import {
    useGetSession,
    useGetSessionParticipants,
    useUpdateSession,
} from '../../../../api/session';
import { SESSION__ADMIN } from '../../../../constants';
import {
    useAppSelector,
    useRefetchTrigger,
    useWhiteboardParams,
} from '../../../../hooks';
import { getUserForActiveOrg } from '../../../../redux/reducers';

import { FrameTimeline } from './frame-timeline';
import { SessionNavigate } from './session-navigate';

const getCurrentIndex = (frames: ISessionFrame[], currentFrame?: string) => {
    if (!currentFrame) return 0;

    const currentIndex = frames.findIndex(frame => frame.id === currentFrame);

    if (currentIndex === -1) return 0;

    return currentIndex;
};

const getCurrentFrame = (frames?: ISessionFrame[], currentFrame?: string) => {
    if (!frames) return '';
    if (!currentFrame) return frames[0].id;

    const activeFrame = frames.find(frame => frame.id === currentFrame);

    if (!activeFrame) return frames[0].id;

    return activeFrame.id;
};

export const SessionNavigationContainer = ({ zoomToElement }) => {
    const user = useAppSelector(getUserForActiveOrg);

    const { sessionKey } = useWhiteboardParams();
    const { data, refetch } = useGetSession();
    const { mutate } = useUpdateSession();
    const {
        data: sessionParticipants = [],
        refetch: refetchParticipants,
    } = useGetSessionParticipants();

    const handleRefetch = useCallback(() => {
        refetch();
        refetchParticipants();
    }, [refetch, refetchParticipants]);

    useRefetchTrigger({
        key: sessionKey,
        scope: 'session',
        refetch: handleRefetch,
    });

    const host = sessionParticipants.find(
        participant => participant.e.type === SESSION__ADMIN
    );

    const frames = useMemo(() => data?.frames ?? [], [data]);
    const currentFrame = data?.currentFrame;
    const activeId = getCurrentFrame(data?.frames, currentFrame);
    const currentIndex = getCurrentIndex(frames, currentFrame);

    const isSessionLeader = host?.v._id === user._id || false;
    const focusOnFrame = useCallback(
        frameId => {
            zoomToElement(frameId);
        },
        [zoomToElement]
    );

    const updateFrameHandler = useCallback(
        frameId => {
            mutate(
                { currentFrame: frameId },
                {
                    onSuccess: () => refetch(),
                }
            );
        },
        [mutate, refetch]
    );

    // zoom when the frame has changed
    useEffect(() => {
        if (currentFrame) {
            focusOnFrame(currentFrame);
        }
    }, [currentFrame, focusOnFrame]);

    useEffect(() => {
        if (!frames || frames.length === 0) return;
        if (currentFrame) return;

        const id = frames[0].id;

        mutate({ currentFrame: id });
        focusOnFrame(id);
    }, [frames, currentFrame, focusOnFrame, mutate]);

    const handleGoToFrame = useCallback(
        frameId => {
            if (isSessionLeader) {
                updateFrameHandler(frameId);
            }
            focusOnFrame(frameId);
        },
        [updateFrameHandler, focusOnFrame, isSessionLeader]
    );

    const handleNext = useCallback(() => {
        if (!frames) return;

        if (currentIndex >= 0) {
            const nextIndex = currentIndex + 1;

            if (nextIndex > frames.length - 1) {
                return;
            }

            const newId = frames[nextIndex].id;
            handleGoToFrame(newId);
        }
    }, [frames, currentIndex, handleGoToFrame]);

    const handlePrev = useCallback(() => {
        if (!frames) return;

        if (currentIndex >= 0) {
            const nextIndex = currentIndex - 1;

            if (nextIndex < 0) {
                return;
            }

            const newId = frames[nextIndex].id;
            handleGoToFrame(newId);
        }
    }, [frames, currentIndex, handleGoToFrame]);

    return (
        <>
            <FrameTimeline
                sx={{
                    flex: 1,
                }}
                isHost={isSessionLeader}
                activeFrame={activeId}
                handleGoToFrame={handleGoToFrame}
            />
            <SessionNavigate
                isHost={isSessionLeader}
                prevDisabled={currentIndex <= 0}
                nextDisabled={currentIndex + 1 >= frames.length}
                handlePrev={handlePrev}
                handleNext={handleNext}
                sx={{ ml: 2 }}
                text={`${currentIndex + 1}/${frames?.length}`}
            />
        </>
    );
};
