import {
    EDIT_ROUTE,
    INTRO_ROUTE,
    SESSION_ROUTE,
    VIEW_ROUTE,
} from '../constants';

export const checkInSessionView = (pathname: string) =>
    pathname.includes(SESSION_ROUTE) && pathname.includes(VIEW_ROUTE);

export const checkInSpecificSessionView = (
    pathname: string,
    sessionKey: string
) =>
    pathname.includes(SESSION_ROUTE) &&
    pathname.includes(`${sessionKey}/${VIEW_ROUTE}`);

export const checkInSessionEdit = (pathname: string) =>
    pathname.includes(SESSION_ROUTE) && pathname.includes(EDIT_ROUTE);

export const checkInSessionIntro = (pathname: string) =>
    pathname.includes(SESSION_ROUTE) && pathname.includes(INTRO_ROUTE);
