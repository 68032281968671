import React from 'react';
import Markdown from 'markdown-to-jsx';
import { Heading, Paragraph, Text, Box, ThemeUIStyleObject } from 'theme-ui';

interface MarkdownProps {
    source: string;
    sx?: ThemeUIStyleObject;
}

export const MarkdownWrapper: React.FC<MarkdownProps> = ({ source, sx }) => {
    // TODO: base margins on font size so that we can scale the whole text
    const basicStyle = {
        mt: 6,
        '&:first-child': {
            mt: 0,
        },
    };
    return (
        <Markdown
            options={{
                wrapper: ({ children }) => <Box sx={sx}>{children}</Box>,
                // TODO: add other overrides, style them
                overrides: {
                    p: {
                        component: Paragraph,
                        props: {
                            sx: basicStyle,
                        },
                    },
                    h3: {
                        component: Heading,
                        props: {
                            as: 'h3',
                            variant: 'hsm',
                            sx: basicStyle,
                        },
                    },
                    span: Text,
                    li: {
                        component: Text,
                        props: {
                            variant: 'li',
                        },
                    },
                },
            }}
        >
            {source}
        </Markdown>
    );
};
