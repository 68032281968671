import { Flex, Paragraph, Button } from '@theme-ui/components';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useDeleteSession } from '../../../../../api/session';
import { EDIT_ROUTE, VIEW_ROUTE } from '../../../../../constants';
import { useTranslationPrefix } from '../../../../../hooks';
import { useSidebarAPI } from '../../../../../providers/sidebar';
import { DeleteElementModal } from '../../delete-element-modal';

export const Intro = ({ isSessionStarted }) => {
    const [showDeleteItemModal, setShowDelete] = useState(false);
    const { mutate: deleteMutation } = useDeleteSession();
    const { closeSidebar } = useSidebarAPI();
    const handleCloseModal = useCallback(() => {
        setShowDelete(false);
    }, []);

    const history = useHistory();
    const { tr } = useTranslationPrefix('sidebar.sessions.intro');

    return (
        <>
            <Button
                sx={{
                    mt: 6,
                    mx: 6,
                }}
                onClick={() => {
                    history.push(VIEW_ROUTE);
                    closeSidebar();
                }}
            >
                {isSessionStarted ? tr('actions.join') : tr('actions.start')}
            </Button>
            {!isSessionStarted && (
                <>
                    <Flex
                        sx={{
                            mt: 10,
                            px: 6,
                            py: 2,
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '1px solid',
                            borderBottom: 1,
                            borderColor: 'bgPrimary',
                        }}
                    >
                        <Paragraph variant="labelSm">
                            {tr('editLabel')}
                        </Paragraph>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                history.push(EDIT_ROUTE);
                            }}
                        >
                            {tr('actions.edit')}
                        </Button>
                    </Flex>
                    <Button
                        variant="tertiary"
                        onClick={() => {
                            setShowDelete(true);
                        }}
                        sx={{
                            mt: 'auto',
                            alignSelf: 'center',
                            color: 'alarming',

                            '&::after': {
                                bg: 'alarming',
                            },
                        }}
                    >
                        {tr('actions.delete')}
                    </Button>
                </>
            )}
            {showDeleteItemModal && (
                <DeleteElementModal
                    handleClose={handleCloseModal}
                    handleDelete={() => {
                        handleCloseModal();
                        deleteMutation();
                    }}
                    type="session"
                />
            )}
        </>
    );
};
