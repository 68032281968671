import {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useState,
} from 'react';
import {
    Flex,
    Box,
    Button,
    Textarea,
    Paragraph,
    Text,
    Label,
} from '@theme-ui/components';
import { Icon } from '@tmi/component-library/src';
import { ReactZoomPanPinchRef } from '@tmi/react-zoom-pan-pinch';
import { useUpdateSession } from '../../../../../../api/session';
import { DatePicker } from '../../../../../../components/date-picker';
import { useTranslationPrefix } from '../../../../../../hooks';
import { WhiteboardContext } from '../../../../containers/whiteboard.container';
import { arrayMove } from '@dnd-kit/sortable';

import { FrameRow } from './frame-row';
import { SortableList } from './sortable-list';
import { DragOverlay } from '@dnd-kit/core';
import { FrameRowOverlay } from './frame-row-overlay';

interface EditProps extends Pick<ReactZoomPanPinchRef, 'zoomToElement'> {
    data: ISession;
    startCreateFrame: boolean;
    setStartCreateFrame: Dispatch<SetStateAction<boolean>>;
    handleBack: () => void;
}

export const Edit = ({
    data,
    startCreateFrame,
    setStartCreateFrame,
    zoomToElement,
    handleBack,
}: EditProps) => {
    const [activeFrame, setActiveFrame] = useState('');

    const { setDragDisabled } = useContext(WhiteboardContext);
    const [onFocus, setOnFocus] = useState(false);
    const [activeDragId, setActiveDragId] = useState(null);

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [showDetails, setShowDetails] = useState(true);

    const { tr } = useTranslationPrefix('sidebar.sessions.edit');

    const { mutate } = useUpdateSession();

    const [title, setTitle] = useState(data?.title ?? '');
    const [description, setDescription] = useState(data?.description ?? '');

    const handleDragEnd = useCallback(
        event => {
            if (!data || !data.frames) return;

            const { active, over } = event;

            if (active.id !== over.id) {
                const oldIndex = data.frames.findIndex(
                    frame => frame.id === active.id
                );
                const newIndex = data.frames.findIndex(
                    frame => frame.id === over.id
                );

                const newOrder = arrayMove(data.frames, oldIndex, newIndex);

                mutate({ frames: newOrder });
            }
            setActiveDragId(null);
        },
        [data, mutate]
    );

    const handleMutate = (key, value) => {
        mutate({
            [key]: value,
        });
    };

    const tempFrame: ISessionFrame = {
        id: 'temp',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };

    const toShowFrames = [...(data.frames ?? [])];

    if (startCreateFrame) {
        toShowFrames.push(tempFrame);
    }
    return (
        <Flex sx={{ width: '100%', flexDirection: 'column' }}>
            <Box p={6}>
                <Button
                    onClick={handleBack}
                    variant="invisible"
                    sx={{
                        display: 'flex',
                        color: 'textPrimary',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <Icon iconName="arrowLeft" />
                    <Text variant="labelSm">{tr('actions.back')}</Text>
                </Button>

                <Textarea
                    variant="textareaHsm"
                    sx={{ mt: 6 }}
                    placeholder={tr('title.placeholder')}
                    value={title}
                    onChange={({ target }) => {
                        setTitle(target.value);
                    }}
                    onFocus={() => {
                        setOnFocus(true);
                    }}
                    onBlur={() => {
                        handleMutate('title', title);
                        setOnFocus(false);
                    }}
                />
                {showDetails && (
                    <>
                        <Label variant="labelXs" sx={{ mt: 5 }}>
                            {tr('date.label')}
                        </Label>
                        <DatePicker
                            placeholder={tr('date.placeholder')}
                            sx={{ mt: 2 }}
                            value={
                                data?.scheduldedAt
                                    ? new Date(data.scheduldedAt)
                                    : tomorrow
                            }
                            onChange={e => {
                                handleMutate('scheduldedAt', e);
                            }}
                        />

                        <Label
                            variant="labelXs"
                            htmlFor="description"
                            sx={{ mt: 6 }}
                        >
                            {tr('description.label')}
                        </Label>
                        <Textarea
                            id="description"
                            placeholder={tr('description.placeholder')}
                            value={description}
                            onChange={({ target }) => {
                                setDescription(target.value);
                            }}
                            onFocus={() => {
                                setOnFocus(true);
                            }}
                            onBlur={() => {
                                handleMutate('description', description);
                                setOnFocus(false);
                            }}
                            rows={3}
                            sx={{
                                mt: 2,
                                p: 2,
                                bg: 'bgPrimary',
                                borderRadius: 2,
                                fontSize: 3,
                                lineHeight: 0,
                                color: 'textPrimary',

                                '::placeholder': {
                                    color: 'textSecondary',
                                },
                            }}
                        />
                    </>
                )}
                <Button
                    onClick={() => setShowDetails(prev => !prev)}
                    variant="invisible"
                    sx={{ alignSelf: 'flex-start', mt: 6 }}
                >
                    <Text variant="labelSm" sx={{ color: 'textSecondary' }}>
                        {showDetails ? 'Hide details' : 'Show details'}
                    </Text>
                </Button>
            </Box>

            <Flex
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minHeight: 0,
                }}
            >
                <Flex
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 6,
                        py: 4,
                        borderTop: 1,
                        borderBottom: 1,
                        borderColor: 'bgPrimary',
                    }}
                >
                    <Paragraph variant="labelSm">{tr('frame.label')}</Paragraph>
                    <Button
                        disabled={startCreateFrame}
                        onClick={() => {
                            setDragDisabled(true);
                            setStartCreateFrame(true);
                        }}
                    >
                        {tr('actions.addFrame')}
                    </Button>
                </Flex>

                {toShowFrames.length > 0 ? (
                    <>
                        <SortableList
                            items={toShowFrames.map(frame => frame.id)}
                            onDragStart={e => {
                                setActiveDragId(e.active.id);
                            }}
                            onDragEnd={handleDragEnd}
                            dragOverlay={
                                <DragOverlay
                                    style={{
                                        pointerEvents: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {activeDragId ? (
                                        <FrameRowOverlay
                                            index={
                                                toShowFrames.findIndex(
                                                    frame =>
                                                        frame.id ===
                                                        activeDragId
                                                ) + 1
                                            }
                                            title={
                                                toShowFrames.find(
                                                    frame =>
                                                        frame.id ===
                                                        activeDragId
                                                )?.title ?? ''
                                            }
                                        />
                                    ) : null}
                                </DragOverlay>
                            }
                        >
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    px: 4,
                                    pt: 2,
                                    overflowY: 'auto',
                                }}
                            >
                                {toShowFrames.map((frame, index) => (
                                    <FrameRow
                                        isActive={activeFrame === frame.id}
                                        onClick={() => {
                                            zoomToElement(frame.id);
                                            setActiveFrame(frame.id);
                                        }}
                                        frames={toShowFrames ?? []}
                                        mutate={mutate}
                                        frame={frame}
                                        key={frame.id}
                                        index={index}
                                        onFocus={onFocus}
                                        setOnFocus={setOnFocus}
                                        sx={{
                                            opacity:
                                                activeDragId === frame.id
                                                    ? 0
                                                    : 1,
                                        }}
                                    />
                                ))}
                            </Flex>
                        </SortableList>
                    </>
                ) : (
                    <Paragraph sx={{ mt: 20, px: 12, textAlign: 'center' }}>
                        {tr('empty')}
                    </Paragraph>
                )}
            </Flex>
        </Flex>
    );
};
