import { ThemeUIStyleObject } from 'theme-ui';
import { DateOptions } from '../../modules/whiteboard/components/filter/filter.constants';
import {
    parseISO,
    isPast,
    isFuture,
    isToday,
    addWeeks,
    addMonths,
    isBefore,
    isAfter,
} from 'date-fns';

const itemHasUniqueFilter = (
    item: IItemInstance,
    filter: Filter,
    prop: 'type' | 'status'
): boolean => filter.query.some(({ value }) => value === item[prop]);

const itemHasAll = (
    item: IItemInstance,
    filter: Filter,
    prop: 'assignees' | 'tags'
): boolean => {
    if (item[prop].length === 0) {
        if (filter.query.some(({ uniqueEmpty }) => uniqueEmpty)) {
            return true;
        } else {
            return false;
        }
    }
    return filter.query.every(query =>
        item[prop].some(elem => {
            return elem._id === query.value;
        })
    );
};

const itemHasAny = (
    item: IItemInstance,
    filter: Filter,
    prop: 'assignees' | 'tags'
): boolean =>
    item[prop].some(elem =>
        filter.query.some(query => query.value === elem._id)
    );

const itemIsWithinTimeframe = (
    item: IItemInstance,
    dueDate: Filter
): boolean => {
    if (!item.dueDate) {
        if (dueDate.query.some(({ uniqueEmpty }) => uniqueEmpty)) {
            return true;
        } else {
            return false;
        }
    }

    const parsedDate = parseISO(item.dueDate);

    switch (dueDate.query[0].value) {
        case DateOptions.OVERDUE:
            return isPast(parsedDate) && !isToday(parsedDate);
        case DateOptions.TODAY:
            return isToday(parsedDate);
        case DateOptions.THIS_WEEK:
            return (
                (isFuture(parsedDate) || isToday(parsedDate)) &&
                isBefore(parsedDate, addWeeks(new Date(), 1))
            );
        case DateOptions.THIS_MONTH:
            return (
                (isFuture(parsedDate) || isToday(parsedDate)) &&
                isBefore(parsedDate, addMonths(new Date(), 1))
            );
        case DateOptions.LONG_TERM:
            return isAfter(parsedDate, addMonths(new Date(), 1));
        default:
            return false;
    }
};

export const filter = (items: IItemInstance[], filters: FilterStateType) => {
    return items.filter(item => {
        if (!isFilterActive(filters)) {
            return false;
        }

        if (filters.cardType?.query.length > 0) {
            if (!itemHasUniqueFilter(item, filters.cardType, 'type')) {
                return false;
            }
        }

        if (filters.assignees?.query.length > 0) {
            if (filters.assignees.operation === 'and') {
                if (!itemHasAll(item, filters.assignees, 'assignees')) {
                    return false;
                }
            } else {
                if (!itemHasAny(item, filters.assignees, 'assignees')) {
                    return false;
                }
            }
        }

        if (filters.dueDate?.query.length > 0) {
            if (!itemIsWithinTimeframe(item, filters.dueDate)) {
                return false;
            }
        }

        if (filters.tags?.query.length > 0) {
            if (filters.tags.operation === 'and') {
                if (!itemHasAll(item, filters.tags, 'tags')) {
                    return false;
                }
            } else {
                if (!itemHasAny(item, filters.tags, 'tags')) {
                    return false;
                }
            }
        }

        if (filters.status?.query.length > 0) {
            if (!itemHasUniqueFilter(item, filters.status, 'status')) {
                return false;
            }
        }

        return true;
    });
};

export const isFilterActive = (state: FilterStateType): boolean =>
    Object.values(state).some(({ query }) => query.length > 0);

export const getHighlightStyle = (
    isHighlight: boolean | null | undefined
): ThemeUIStyleObject => {
    if (typeof isHighlight !== 'boolean') {
        return {};
    }

    if (isHighlight) {
        return {
            outline: '3px solid',
            outlineColor: 'actionPrimary',
        };
    }

    return {
        opacity: 0.3,
    };
};
