import { useContext } from 'react';
import { Box } from '@theme-ui/components';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { WhiteboardContext } from '../../containers/whiteboard.container';

interface ResizeHandlesProps {
    handleMouseDown: (e: any) => void;
    whiteboardsScale: number;
}
interface ResizeHandleProps {
    sx?: ThemeUIStyleObject;
    onMouseDown: (e: any) => void;
    currentDirectionNormalize: { x: number; y: number };
    whiteboardsScale: number;
}

const ResizeHandle = ({
    sx,
    onMouseDown,
    currentDirectionNormalize,
    whiteboardsScale,
}: ResizeHandleProps) => {
    const { setDragDisabled } = useContext(WhiteboardContext);

    const disableDragging = () => {
        setDragDisabled(true);
    };

    const smartScale = whiteboardsScale < 1 ? 1 / whiteboardsScale : 1;

    return (
        <Box
            onMouseDownCapture={disableDragging}
            onMouseDown={({ clientX, clientY }) =>
                onMouseDown({ clientX, clientY, currentDirectionNormalize })
            }
            sx={{
                position: 'absolute',
                height: 9,
                width: 9,
                borderRadius: 1,
                border: 1,
                borderColor: 'actionPrimary',
                bg: 'bgPrimary',
                cursor: 'pointer',
                transform: `scale(${smartScale})`,
                ...sx,
            }}
        />
    );
};

const positions = [
    {
        key: 'ne',
        top: '-5px',
        left: '-5px',
        cursor: 'nwse-resize',
        currentDirectionNormalize: { x: -1, y: -1 },
    },
    {
        key: 'nw',
        top: '-5px',
        right: '-5px',
        cursor: 'nesw-resize',
        currentDirectionNormalize: { x: 1, y: -1 },
    },
    {
        key: 'se',
        bottom: '-5px',
        left: '-5px',
        cursor: 'nesw-resize',
        currentDirectionNormalize: { x: -1, y: 1 },
    },
    {
        key: 'sw',
        bottom: '-5px',
        right: '-5px',
        cursor: 'nwse-resize',
        currentDirectionNormalize: { x: 1, y: 1 },
    },
];

export const ResizeHandles: React.FC<ResizeHandlesProps> = ({
    handleMouseDown,
    whiteboardsScale,
}) => (
    <>
        {positions.map(({ key, currentDirectionNormalize, ...rest }) => (
            <ResizeHandle
                key={key}
                currentDirectionNormalize={currentDirectionNormalize}
                onMouseDown={handleMouseDown}
                whiteboardsScale={whiteboardsScale}
                sx={{ ...rest }}
            />
        ))}
    </>
);
