import { useLayoutEffect, useRef, useState } from 'react';
import { Flex, ThemeUIStyleObject } from 'theme-ui';
import { Tag } from './tag';
import { TagPickerOption } from './tag-picker';

interface TagListProps {
    tags: TagPickerOption[];
    sx?: ThemeUIStyleObject;
    disableOverload?: boolean;
    variant?: 'xs' | 'sm';
}

export const TagList = ({
    tags,
    sx,
    variant = 'sm',
    disableOverload = false,
}: TagListProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const [overload, setOverload] = useState(0);

    useLayoutEffect(() => {
        if (wrapperRef) {
            const wrapperWidth = wrapperRef?.current?.clientWidth || 0;
            const scrollWidth = innerRef?.current?.scrollWidth || 0;

            /**
             * Failsafe to not enter an infinite loop,
             * the overload can not be bigger than the length of the tags array
             */
            if (overload >= tags.length) {
                return;
            }

            if (scrollWidth <= wrapperWidth) {
                return;
            }

            setOverload(prev => prev + 1);
        }
    }, [overload, tags]);

    if (disableOverload) {
        return (
            <Flex sx={{ gap: 1, minWidth: 'auto', flexWrap: 'wrap', ...sx }}>
                {tags.map(tag => (
                    <Tag key={tag._id} tag={tag.name} variant={variant} />
                ))}
            </Flex>
        );
    }

    return (
        <Flex
            sx={{
                width: '100%',
                justifyContent: 'center',
                ...sx,
            }}
            ref={wrapperRef}
        >
            <Flex ref={innerRef} sx={{ gap: 1, minWidth: 'auto' }}>
                {tags.slice(0, tags.length - overload).map(tag => (
                    <Tag
                        key={tag._id || tag.name}
                        tag={tag.name}
                        variant={variant}
                    />
                ))}
                {overload > 0 ? (
                    <Tag
                        key="overload_tag_id"
                        tag={`+${overload}`}
                        variant={variant}
                    />
                ) : null}
            </Flex>
        </Flex>
    );
};
