import { useCallback, useEffect, useState } from 'react';
import { getAdjustedCoordinates } from '../../../../helpers';
import { v4 as uuid } from 'uuid';
import { MIN_FRAME_SIZE } from '../../constants/general';

interface Listener {
    event: string;
    eventHandler: (e) => void;
}

const calcTempDelta = (
    current: number,
    initial: number,
    min: number
): number => {
    const delta = current - initial;
    const isNegative = delta < 0;

    const minDelta = Math.max(Math.abs(delta), min);
    const normalized = isNegative ? minDelta * -1 : minDelta;

    return normalized;
};

const handleEventListeners = (
    listeners: Listener[],
    action: 'addEventListener' | 'removeEventListener'
) => {
    listeners.forEach(listener => {
        document[action](listener.event, listener.eventHandler);
    });
};

export const useCreateFrameHandler = ({
    scale,
    canvasRef,
    startCreateFrame,
    handler,
}) => {
    const [initialPos, setInitialPos] = useState({ x: 0, y: 0 }); // null
    const [delta, setDelta] = useState({
        x: MIN_FRAME_SIZE,
        y: MIN_FRAME_SIZE,
    });
    const [showElement, setShowElement] = useState(false);
    const [frame, setFrame] = useState<ISessionFrame | null>(null);

    const handleMouseDown = useCallback(
        e => {
            setShowElement(true);
            setInitialPos(
                getAdjustedCoordinates(e.clientX, e.clientY, scale, canvasRef)
            );
        },
        [scale, canvasRef]
    );

    const handleMouseMove = useCallback(
        e => {
            if (!showElement) return;

            const currentPos = getAdjustedCoordinates(
                e.clientX,
                e.clientY,
                scale,
                canvasRef
            );

            const tempDelta = {
                x: calcTempDelta(currentPos.x, initialPos.x, MIN_FRAME_SIZE),
                y: calcTempDelta(currentPos.y, initialPos.y, MIN_FRAME_SIZE),
            };
            setDelta(tempDelta);
        },
        [showElement, scale, canvasRef, initialPos.x, initialPos.y]
    );

    const handleMouseUp = useCallback(
        e => {
            setShowElement(false);

            const payload = {
                id: uuid(),
                x: delta.x < 0 ? initialPos.x + delta.x : initialPos.x,
                y: delta.y < 0 ? initialPos.y + delta.y : initialPos.y,
                width: Math.abs(delta.x),
                height: Math.abs(delta.y),
            };

            if (!frame) {
                setFrame(payload);
            }
            handler(payload);
        },
        [delta, frame, initialPos, handler]
    );

    useEffect(() => {
        const listeners: Listener[] = [
            { event: 'mousedown', eventHandler: handleMouseDown },
            { event: 'mousemove', eventHandler: handleMouseMove },
            { event: 'mouseup', eventHandler: handleMouseUp },
        ];
        if (startCreateFrame) {
            handleEventListeners(listeners, 'addEventListener');
        } else {
            handleEventListeners(listeners, 'removeEventListener');
        }

        return () => {
            handleEventListeners(listeners, 'removeEventListener');
        };
    }, [startCreateFrame, handleMouseDown, handleMouseMove, handleMouseUp]);

    return {
        frame,
        showElement,
        setFrame,
        delta,
        initialPos,
    };
};
