import { ThemeUIStyleObject } from 'theme-ui';

export const styles: Record<string, ThemeUIStyleObject> = {
    spinner: {
        color: 'primary',
        strokeLinecap: 'round',
        strokeWidth: 2,
        height: 112,
        width: 112,
    },
    a: {
        variant: 'text.baseParagraph',
        textDecoration: 'underline',
        color: 'inherit',
    },
};
