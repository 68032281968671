import { Box, Flex, Text, Paragraph } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { Avatar } from './avatar';
import Tippy from '@tippyjs/react';

interface AvatarListProps {
    users: Partial<IUser>[];
    maxAvatars?: number;
    avatarSize?: number;
    sx?: ThemeUIStyleObject;
    disableOverload?: boolean;
}

export const AvatarList = ({
    users,
    maxAvatars = 3,
    avatarSize = 32,
    sx,
    disableOverload = false,
}: AvatarListProps) => {
    if (disableOverload) {
        return (
            <Flex sx={{ ...sx, pl: 1, flexWrap: 'wrap', ...sx }}>
                {users.map(user => {
                    //@TODO temporary fix for member delete
                    if (!user) return null;
                    const { fullName, profilePicture, email } = user;

                    return (
                        <Avatar
                            key={email}
                            fullName={fullName!}
                            pictureUrl={profilePicture}
                            email={email!}
                            size={avatarSize}
                            outlineWidth={2}
                            outlineColor="bgSecondary"
                            sx={{
                                ml: -1,
                            }}
                        />
                    );
                })}
            </Flex>
        );
    }

    if (!users) return null;

    const overloadAmount =
        users.length <= maxAvatars ? 0 : users.length - maxAvatars + 1;
    const userAvatarsVisible = overloadAmount > 0 ? maxAvatars - 1 : maxAvatars;

    return (
        <Flex sx={{ ...sx, pl: 1 }}>
            {users.slice(0, userAvatarsVisible).map(user => {
                //@TODO temporary fix for member delete

                if (!user) return null;
                const { fullName, profilePicture, email } = user;
                return (
                    <Avatar
                        key={email}
                        fullName={fullName!}
                        pictureUrl={profilePicture}
                        email={email!}
                        size={avatarSize}
                        outlineWidth={2}
                        outlineColor="bgSecondary"
                        sx={{
                            ml: -1,
                        }}
                    />
                );
            })}
            {overloadAmount ? (
                <OverloadAvatar
                    overloadedUsers={users.slice(userAvatarsVisible)}
                    size={avatarSize}
                    outlineWidth={2}
                    outlineColor="bgSecondary"
                />
            ) : null}
        </Flex>
    );
};

interface OverloadAvatarProps {
    overloadedUsers: Partial<IUser>[];
    size: number;
    outlineWidth?: number;
    outlineColor?: string;
}

const OverloadAvatar = ({
    overloadedUsers,
    size,
    outlineWidth,
    outlineColor,
}: OverloadAvatarProps) => (
    <Tippy
        placement="bottom"
        animation={false}
        render={attrs => (
            <OverloadAvatarList {...attrs} overloadedUsers={overloadedUsers} />
        )}
    >
        <Box
            sx={{
                flexShrink: 0,
                borderRadius: 5,
                height: size,
                width: size,
                transform: 'translateZ(0)',
                position: 'relative',
                ml: -1,

                ...(outlineWidth
                    ? {
                          '&::after': {
                              content: '""',
                              position: 'absolute',
                              top: `-${outlineWidth}px`,
                              left: `-${outlineWidth}px`,
                              right: `-${outlineWidth}px`,
                              bottom: `-${outlineWidth}px`,
                              bg: outlineColor,
                              zIndex: -1,
                              borderRadius: 5,
                          },
                      }
                    : null),
            }}
        >
            <Flex
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: size,
                    width: size,
                    borderRadius: 5,
                    bg: 'textPrimary',
                }}
            >
                <Text variant="labelSm" sx={{ color: 'textInverse' }}>
                    +{overloadedUsers.length}
                </Text>
            </Flex>
        </Box>
    </Tippy>
);

interface OverloadAvatarListProps {
    overloadedUsers: Partial<IUser>[];
}

const OverloadAvatarList = ({ overloadedUsers }: OverloadAvatarListProps) => {
    return (
        <Box
            sx={{
                mt: 1,
                backgroundColor: 'bgInverse',
                borderRadius: 2,
                p: 1,
                boxShadow: 'tooltipShadow',
                position: 'relative',
            }}
        >
            {overloadedUsers.map(user => {
                //@TODO temporary fix for member delete
                if (!user) return null;
                return (
                    <Flex
                        key={user.email}
                        sx={{
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        <Avatar
                            pictureUrl={user.profilePicture}
                            email={user.email ?? ''}
                            fullName={user.fullName ?? ''}
                            outlineWidth={2}
                            outlineColor="bgSecondary"
                        />
                        <Paragraph
                            variant="xs"
                            sx={{
                                color: 'textInverse',
                                ml: 2,
                            }}
                        >
                            {user.fullName}
                        </Paragraph>
                    </Flex>
                );
            })}
            <Box
                data-popper-arrow=""
                sx={{
                    width: 0,
                    height: 0,
                    position: 'absolute',
                    borderLeft: '16px solid transparent',
                    borderRight: '16px solid transparent',
                    borderBottom: '16px solid',
                    borderBottomColor: 'bgInverse',
                    left: '50%',
                    top: '-8px',
                }}
            />
        </Box>
    );
};
