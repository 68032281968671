import {
    createContext,
    useContext,
    useState,
    useMemo,
    useCallback,
} from 'react';

type ActiveCard = {
    key: string;
    type: CardType;
};

type State = {
    isSidebarOpen: boolean;
    currentlyActiveCard: ActiveCard | null;
    previouslyActiveCard: ActiveCard | null;
};

type API = {
    toggleSidebar: () => void;
    openSidebar: () => void;
    closeSidebar: () => void;
    setActiveCard: (cardData: ActiveCard) => void;
    resetActiveCard: () => void;
    clearActiveCard: () => void;
};

const SidebarDataContext = createContext<State>({} as State);
const SidebarAPIContext = createContext<API>({} as API);

export const SidebarProvider = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    /**
     * The prevActiveCard is used to keep track of the card that was last active. This logic cannot only be captured
     * in the activeCard because of the way the sidebar should behave. The expected behaviour for the sidebar is:
     *      * When a card is selected, opening the sidebar shows that card content (activeCard)
     *      * When a card was selected, it should still show in the sidebar but closing and reopening the sidebar
     *          will reset the sidebar content back to activity
     */
    const [activeCard, setActiveCardState] = useState<ActiveCard | null>(null);
    const [
        prevActiveCard,
        setPrevActiveCardState,
    ] = useState<ActiveCard | null>(null);

    // reset will move the activeCard to prevActiveCard, and then empty out the activeCard
    const resetActiveCard = useCallback(() => {
        setActiveCardState(prev => {
            setPrevActiveCardState(prev);
            return null;
        });
    }, []);

    const API = useMemo(
        () => ({
            toggleSidebar: () => {
                setIsSidebarOpen(wasOpen => {
                    if (wasOpen) {
                        setPrevActiveCardState(null);
                        return false;
                    } else {
                        return true;
                    }
                });
            },
            openSidebar: () => {
                setIsSidebarOpen(true);
            },
            closeSidebar: () => {
                setIsSidebarOpen(false);
                resetActiveCard();
            },
            setActiveCard: (cardData: ActiveCard) => {
                setActiveCardState(cardData);
                setPrevActiveCardState(null);
            },
            // clearActiveCard will hard clear both the activeCard and the prevActiveCard
            clearActiveCard: () => {
                setActiveCardState(null);
                setPrevActiveCardState(null);
            },
            resetActiveCard,
        }),
        [resetActiveCard]
    );

    return (
        <SidebarAPIContext.Provider value={API}>
            <SidebarDataContext.Provider
                value={{
                    isSidebarOpen,
                    currentlyActiveCard: activeCard,
                    previouslyActiveCard: prevActiveCard,
                }}
            >
                {children}
            </SidebarDataContext.Provider>
        </SidebarAPIContext.Provider>
    );
};

export const useSidebarData = () => useContext(SidebarDataContext);
export const useSidebarAPI = () => useContext(SidebarAPIContext);
