import React, { useContext } from 'react';
import {
    DndContext,
    useSensor,
    MouseSensor,
    TouchSensor,
    useSensors,
} from '@dnd-kit/core';
import { WhiteboardContext } from './whiteboard.container';

interface Props {}

export const DndContainer: React.FC<Props> = ({ children }) => {
    const { setPanPinchDisabled } = useContext(WhiteboardContext);

    const constraint = {
        activationConstraint: {
            distance: 1,
        },
    };
    const mouseSensor = useSensor(MouseSensor, constraint);
    const touchSensor = useSensor(TouchSensor, constraint);
    const sensors = useSensors(mouseSensor, touchSensor);

    return (
        <DndContext
            autoScroll={{
                threshold: {
                    x: 0.1,
                    y: 0.1,
                },
                interval: 30,
            }}
            sensors={sensors}
            onDragStart={() => {
                setPanPinchDisabled(true);
            }}
            onDragEnd={() => {
                setPanPinchDisabled(false);
            }}
        >
            {children}
        </DndContext>
    );
};
