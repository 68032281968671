import React from 'react';
import ReactModal from 'react-modal';
import { Flex, ThemeUIStyleObject, useThemeUI } from 'theme-ui';

interface ModalProps {
    children: React.ReactElement;
    isOpen: boolean;
    onClose: () => void;
    id?: string;
    width?: string;
    sx?: ThemeUIStyleObject;
}

export const Modal = ({
    children,
    isOpen,
    onClose,
    id,
    width,
    sx,
}: ModalProps) => {
    const { theme } = useThemeUI();
    return (
        <ReactModal
            id={id ?? ''}
            isOpen={isOpen}
            onRequestClose={onClose}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            style={{
                content: {
                    position: 'relative',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    outline: 'none',
                    width: width ?? '700px',
                    top: '50%',
                    left: '50%',
                    borderRadius: theme!.space![6] as string,
                    transform: 'translate(-50%, -50%)',
                    padding: 0,
                },
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                },
            }}
        >
            <Flex
                sx={{
                    bg: 'bgSecondary',
                    width: '100%',
                    maxHeight: 'calc(100vh - 24px)',
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    px: 11,
                    pt: 10,
                    pb: 9,
                    justifyContent: 'center',
                    ...sx,
                }}
            >
                {children}
            </Flex>
        </ReactModal>
    );
};
