import {
    Flex,
    Box,
    Heading,
    Button,
    Text,
    Paragraph,
} from '@theme-ui/components';
import { Icon } from '@tmi/component-library/src';
import { format } from 'date-fns';

import { Link } from 'react-router-dom';
import {
    useCreateSession,
    useGetAllSessions,
} from '../../../../../api/session';
import {
    SESSION_ROUTE,
    WHITEBOARD_ROUTE,
    INTRO_ROUTE,
} from '../../../../../constants';
import {
    useAppSelector,
    useTranslationPrefix,
    useWhiteboardParams,
} from '../../../../../hooks';
import { getUserForActiveOrg } from '../../../../../redux/reducers';
import { OrganisationSwitcher } from '../../sidebar/organisation-switcher';

export const Overview = () => {
    const { whiteboardKey } = useWhiteboardParams();
    const { mutate: createSession } = useCreateSession({ whiteboardKey });
    const { data } = useGetAllSessions({ whiteboardKey });

    const { fullName } = useAppSelector(getUserForActiveOrg);
    const { tr } = useTranslationPrefix('sidebar.sessions.overview');

    return (
        <Flex sx={{ width: '100%', flexDirection: 'column' }}>
            <OrganisationSwitcher />
            <Box
                sx={{
                    px: 4,
                    py: 6,
                }}
            >
                <Heading variant="hmd" as="h1">
                    {tr('title', {
                        name: fullName,
                    })}
                </Heading>
            </Box>

            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderTop: `1px solid`,
                    borderBottom: `1px solid`,
                    borderColor: 'actionSecondary',
                    px: 4,
                    py: 2,
                }}
            >
                <Paragraph variant="labelSm">{tr('listHeader')}</Paragraph>
                <Button
                    onClick={() =>
                        createSession({
                            whiteboardId: `whiteboards/${whiteboardKey}`,
                        })
                    }
                >
                    {tr('addSession')}
                </Button>
            </Flex>

            <Box
                sx={{
                    overflowY: 'auto',
                }}
            >
                {data && data.length > 0 ? (
                    data.map((session, index) => (
                        <Row
                            key={session._id}
                            session={session}
                            index={index}
                            whiteboardKey={whiteboardKey}
                        />
                    ))
                ) : (
                    <Paragraph sx={{ mt: 20, px: 12, textAlign: 'center' }}>
                        {tr('empty')}
                    </Paragraph>
                )}
            </Box>
        </Flex>
    );
};

interface RowProps {
    session: ISession;
    index: number;
    whiteboardKey: string;
}

const Row = ({ session, index, whiteboardKey }: RowProps) => {
    const { tr } = useTranslationPrefix('sidebar.sessions.overview');

    return (
        <Link
            to={`/${WHITEBOARD_ROUTE}/${whiteboardKey}/${SESSION_ROUTE}/${session._key}/${INTRO_ROUTE}`}
        >
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: `1px solid`,
                    borderColor: 'actionSecondary',
                    p: 4,
                }}
            >
                <Flex
                    sx={{
                        flexDirection: 'column',
                    }}
                >
                    <Text
                        variant="labelMd"
                        sx={{
                            mb: 1,
                        }}
                    >
                        {session?.title
                            ? session?.title
                            : tr('titlePlaceholder', {
                                  index: index + 1,
                              })}
                    </Text>
                    <Text
                        variant="labelSm"
                        sx={{
                            color: 'textSecondary',
                            opacity: session?.scheduldedAt ? 1 : 0.6,
                        }}
                    >
                        {session?.scheduldedAt
                            ? format(
                                  new Date(session?.scheduldedAt),
                                  'dd-MM-yyyy'
                              )
                            : 'No date set'}
                    </Text>
                </Flex>
                <Icon iconName="arrowRight" />
            </Flex>
        </Link>
    );
};
