import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { getWhiteboardsForUser } from '../../api';
import { getWhiteboardsForUserQueryKey } from '../../constants';
import { getUserForActiveOrg } from '../../redux/reducers';
import { useAppSelector } from '..';

export const useGetUserWhiteboards = () => {
    const user = useAppSelector(getUserForActiveOrg);
    return useQuery<IWhiteboardInstance[], AxiosError>(
        [getWhiteboardsForUserQueryKey, { id: user._id }],
        getWhiteboardsForUser
    );
};
