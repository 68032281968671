import { useEffect, useRef } from 'react';
import { usePreviousImmediate } from 'rooks';
import { Flex, Box } from 'theme-ui';
import { BaseCardProps } from '..';
import {
    ACTION_TITLE_MAX_LENGTH,
    CardTypes,
    CARD_META,
} from '../../../constants/card';
import { useTranslationPrefix } from '../../../../../hooks';
import {
    TagPicker,
    DueDatePicker,
    PeoplePicker,
    TitleField,
} from '../../../../../components';
import { CardMode } from '../../../../../types/enums';
import { alphabeticalTagSort } from '../../../../../helpers/alphabetical-sort';
import { StatusPicker } from '../../../../../components/status-picker';
import { getHighlightStyle } from '../../../../../helpers/filter/filter';
import { getEditOutlineStyle, getLockedOverlayStyle } from '../../../helpers';

interface ActionProps extends BaseCardProps {
    data: ActionData;
}

export const Action = ({
    data,
    dispatchHandler,
    cardMode,
    cardScale,
    isLocked,
    isGhost,
    isHighlight,
    sx,
}: ActionProps) => {
    const { tr } = useTranslationPrefix('whiteboard.cards.action');
    const { title, assignees, tags, dueDate, status } = data;
    const isEditing = cardMode === CardMode.EDIT;
    const titleRef = useRef<HTMLTextAreaElement>(null);
    const { width, height } = CARD_META['action'];
    const previousCardMode = usePreviousImmediate<CardMode>(cardMode);
    const hasLeftEditMode =
        previousCardMode === CardMode.EDIT && cardMode !== CardMode.EDIT;

    useEffect(() => {
        if (hasLeftEditMode) {
            titleRef?.current?.blur();
        }
    }, [isEditing, hasLeftEditMode]);

    return (
        <Box
            sx={{
                width: `${width * cardScale}px`,
                height: `${height * cardScale}px`,
            }}
        >
            <Flex
                sx={{
                    position: 'relative',
                    p: 4,
                    borderRadius: 3,
                    bg: 'bgSecondary',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: `${width}px`,
                    height: `${height}px`,
                    transformOrigin: 'top left',
                    transform: `scale(${cardScale})`,
                    boxShadow: 'cardShadow',
                    pointerEvents: isLocked || isGhost ? 'none' : 'auto',
                    ...getEditOutlineStyle(CardTypes.ACTION, isEditing),
                    ...getLockedOverlayStyle(CardTypes.ACTION, isLocked),
                    ...getHighlightStyle(isHighlight),
                    ...sx,
                }}
            >
                <Flex sx={{ justifyContent: 'space-between' }}>
                    <DueDatePicker
                        value={dueDate}
                        onChange={dispatchHandler('dueDate')}
                        isEditActive={isEditing}
                    />
                    <PeoplePicker
                        value={assignees}
                        onChange={dispatchHandler('assignees')}
                        isEditActive={isEditing}
                        avatarSize={20}
                    />
                </Flex>

                <TitleField
                    ref={titleRef}
                    value={title}
                    placeholder={tr('title.placeholder')}
                    disabled={!isEditing}
                    onChange={({ currentTarget }) =>
                        dispatchHandler('title')(currentTarget.value)
                    }
                    variant="textareaSm"
                    sx={{ height: '54px' }}
                    maxLength={ACTION_TITLE_MAX_LENGTH}
                />

                <Flex
                    sx={{
                        // justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <TagPicker
                        value={[...tags].sort(alphabeticalTagSort)}
                        onChange={dispatchHandler('tags')}
                        isEditActive={isEditing}
                        sx={{ flex: 1, mr: 2 }}
                        taglistSx={{ justifyContent: 'flex-start' }}
                        variant="xs"
                        alignLeft
                    />
                    <StatusPicker
                        value={status ?? ''}
                        onChange={dispatchHandler('status')}
                        isEditActive={isEditing}
                    />
                </Flex>
            </Flex>
        </Box>
    );
};
