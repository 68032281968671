import { Box } from 'theme-ui';

const commentIcon = (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.08346 4.38397C9.51246 0.880474 15.072 0.880474 18.5009 4.38397C21.9299 7.88746 21.9299 13.5678 18.5009 17.0712L12.2922 23.4149L6.08346 17.0712C2.65447 13.5678 2.65447 7.88746 6.08346 4.38397Z"
            fill="#F35239"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9772 10.5375C16.9772 12.8005 14.8806 14.6351 12.2943 14.6351C11.4211 14.6351 10.6038 14.426 9.90407 14.0619L7.61133 14.6351L8.39472 12.8071C7.8998 12.1574 7.61133 11.3769 7.61133 10.5375C7.61133 8.27448 9.70795 6.43994 12.2943 6.43994C14.8806 6.43994 16.9772 8.27448 16.9772 10.5375ZM10.5382 9.95214H9.36743V11.1229H10.5382V9.95214ZM15.2211 9.95214H14.0504V11.1229H15.2211V9.95214ZM11.7089 9.95214H12.8796V11.1229H11.7089V9.95214Z"
            fill="white"
        />
    </svg>
);

export const CardIcon = () => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '-20px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
            }}
        >
            {commentIcon}
        </Box>
    );
};
