import {
    memo,
    MutableRefObject,
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';

import { useKey } from 'rooks';

import { Box } from '@theme-ui/components';

import { useDeleteItem, useGetItemInstance } from '../../../../../api/item';

import { useAppSelector, useRefetchTrigger } from '../../../../../hooks';

import { Card } from '../card';

import { DeleteElementModal } from '../../delete-element-modal';
import { cardReducer, setCard } from './reducer';
import { CardMode } from '../../../../../types/enums';
import { getUserForActiveOrg } from '../../../../../redux/reducers';

interface Props {
    canvasRef: MutableRefObject<HTMLElement | null>;
    itemInstance: IItemInstance;
}

export const CardContainerInner: React.FC<Props> = ({
    canvasRef,
    itemInstance,
}) => {
    const { data, refetch, isFetched } = useGetItemInstance({
        itemKey: itemInstance._key,
        disableInitialFetch: true,
        initialData: itemInstance,
    });

    const { mutate: deleteItemMutation } = useDeleteItem({
        key: itemInstance._key,
    });

    useRefetchTrigger({ key: itemInstance._key, scope: 'item', refetch });

    const [state, dispatch] = useReducer(cardReducer, {
        ...itemInstance,
    });

    const user = useAppSelector(getUserForActiveOrg);

    useEffect(() => {
        if (!isFetched || !data) return;

        if (user._id === data.currentlyEditedBy) return;

        dispatch(setCard({ ...data }));
    }, [data, isFetched, user._id]);

    const [showDeleteItemModal, setShowDelete] = useState(false);
    const containerRef = useRef(null);

    const handleDelete = useCallback(() => {
        if (state.cardMode !== CardMode.VIEW) {
            setShowDelete(true);
        }
    }, [state.cardMode]);

    const handleDeleteContextMenu = useCallback(() => {
        setShowDelete(true);
    }, []);

    useKey(
        ['Backspace', 'Delete'],
        () => {
            handleDelete();
        },
        {
            when: state.cardMode === CardMode.LAYOUT,
            target: containerRef,
        }
    );

    const handleCloseModal = () => {
        setShowDelete(false);
    };

    const isInEditMode = state.cardMode === CardMode.EDIT;
    const isInLayoutMode = state.cardMode === CardMode.LAYOUT;

    return (
        <Box
            tabIndex={0}
            ref={containerRef}
            sx={{
                position: 'relative',
                zIndex: isInEditMode || isInLayoutMode ? 1 : 'auto',
            }}
        >
            <Card
                canvasRef={canvasRef}
                itemInstance={state}
                dispatch={dispatch}
                handleDelete={handleDeleteContextMenu}
            />

            {showDeleteItemModal && (
                <DeleteElementModal
                    handleClose={handleCloseModal}
                    handleDelete={() => {
                        handleCloseModal();
                        deleteItemMutation();
                    }}
                    type="card"
                />
            )}
        </Box>
    );
};

export const CardContainer = memo(CardContainerInner);
