import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { inviteExistsQueryKey } from '../constants';
import {
    API_BASE_URL,
    API_ROUTE_PUBLIC,
    API_ROUTE_INVITE_EXISTS,
} from '../constants/api';
import { createQueryKey } from '../helpers';

const getDoesInviteExist = (inviteId: string): Promise<boolean> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_PUBLIC}/${API_ROUTE_INVITE_EXISTS}/${inviteId}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const useGetDoesInviteExist = (inviteId: string) => {
    return useQuery<boolean, AxiosError>(
        createQueryKey(inviteExistsQueryKey, inviteId),
        () => getDoesInviteExist(inviteId),
        {
            enabled: !!inviteId,
            cacheTime: 0,
        }
    );
};
