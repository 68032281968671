import React, {
    useContext,
    useRef,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
    useCallback,
} from 'react';
import { Box } from '@theme-ui/components';

import { CursorHandler } from '../../../components';
import { WhiteboardContext } from '../containers/whiteboard.container';

import whiteboardBgPattern from '../../../assets/images/whiteboard_bg_pattern_dot.svg';
import {
    TransformComponent,
    useTransformContext,
} from '@tmi/react-zoom-pan-pinch';
import { CardContainer } from './card/state/card.container';
import { WHITEBOARD_SIZE } from '../../../constants';
import { useWhiteboardParams } from '../../../hooks';
import { GhostCard } from './card/ghost-card';
import { ConnectionContainer } from '../containers/connection.container';
import { SessionContainer } from './session/session.container';
import { useFilterData } from '../../../providers/filter';
import { getBounds } from '../../../helpers/get-bounds/get-bounds';
import MouseTrap from 'mousetrap';
import { ZOOM_IN, ZOOM_OUT, ZOOM_RESET } from '../../../constants/hotkeys';

const boundsPadding = 400;
const ZOOM_BOUNDS = 'zoomBounds';

interface Props {
    data: IWhiteboard;
    startCreateFrame: boolean;
    setStartCreateFrame: Dispatch<SetStateAction<boolean>>;
}

const canvasStyle = {
    maxWidth: '100%',
    maxHeight: '100vh',
};
export const Canvas: React.FC<Props> = ({
    startCreateFrame,
    setStartCreateFrame,
    data,
}) => {
    const { sessionKey } = useWhiteboardParams();
    const { selectedCards } = useFilterData();
    const { handleAddCard } = useContext(WhiteboardContext);
    const {
        state: { scale },
        zoomToElement,
        zoomIn,
        zoomOut,
        resetTransform,
    } = useTransformContext();
    const [bounds, setBounds] = useState<BoundsType | null>(null);

    const memoizedZoomIn = useCallback(
        () => zoomIn(0.5, 300, 'easeInOutCubic'),
        [] // eslint-disable-line
    );
    const memoizedZoomOut = useCallback(
        () => zoomOut(0.5, 300, 'easeInOutCubic'),
        [] // eslint-disable-line
    );
    const memoizedResetTransform = useCallback(
        () => resetTransform(300, 'easeInOutCubic'),
        [] // eslint-disable-line
    );

    useEffect(() => {
        MouseTrap.bind(ZOOM_OUT, memoizedZoomOut);
        MouseTrap.bind(ZOOM_IN, memoizedZoomIn);
        MouseTrap.bind(ZOOM_RESET, memoizedResetTransform);

        return () => {
            MouseTrap.unbind(ZOOM_OUT);
            MouseTrap.unbind(ZOOM_IN);
            MouseTrap.unbind(ZOOM_RESET);
        };
    }, [memoizedResetTransform, memoizedZoomIn, memoizedZoomOut]);

    const improvedZoomToElement = useCallback(
        id => zoomToElement(id),
        [] // eslint-disable-line
    );

    useEffect(() => {
        if (selectedCards) {
            setBounds(getBounds(selectedCards));
        } else {
            setBounds(null);
        }
    }, [selectedCards]);

    useEffect(() => {
        if (bounds) {
            improvedZoomToElement(ZOOM_BOUNDS);
        }
    }, [bounds, improvedZoomToElement]);

    const canvasRef = useRef(null);

    return (
        <TransformComponent wrapperStyle={canvasStyle}>
            <Box
                ref={canvasRef}
                sx={{
                    width: `${WHITEBOARD_SIZE.width}px`,
                    height: `${WHITEBOARD_SIZE.height}px`,
                    backgroundColor: 'bgPrimary',
                    backgroundImage: `url(${whiteboardBgPattern})`,
                    ...(startCreateFrame && {
                        cursor: 'crosshair',
                        div: {
                            cursor: 'crosshair',
                        },
                    }),
                }}
                onClick={event => handleAddCard(event, canvasRef, scale)}
            >
                {bounds && (
                    <Box
                        id={ZOOM_BOUNDS}
                        sx={{
                            position: 'absolute',
                            left: bounds ? bounds.left - boundsPadding : 0,
                            top: bounds ? bounds.top - boundsPadding : 0,
                            width: bounds
                                ? `${bounds.right -
                                      bounds.left +
                                      boundsPadding * 2}px`
                                : 0,
                            height: bounds
                                ? `${bounds.bottom -
                                      bounds.top +
                                      boundsPadding * 2}px`
                                : 0,
                        }}
                    />
                )}

                {/* Cancelling the scaling for the ConnectionContainer */}
                <div
                    style={{
                        transform: `scale(${Math.pow(scale, -1)})`,
                    }}
                >
                    <ConnectionContainer connections={data?.connections} />
                </div>

                {data &&
                    data?.items?.map(item => {
                        return (
                            <CardContainer
                                canvasRef={canvasRef}
                                key={item._id}
                                itemInstance={item}
                            />
                        );
                    })}

                <GhostCard canvasRef={canvasRef} scale={scale} />

                <CursorHandler canvasRef={canvasRef} scale={scale} />

                {sessionKey && (
                    <SessionContainer
                        scale={scale}
                        canvasRef={canvasRef}
                        setStartCreateFrame={setStartCreateFrame}
                        startCreateFrame={startCreateFrame}
                    />
                )}
            </Box>
        </TransformComponent>
    );
};
