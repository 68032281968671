import { getTeamForWhiteboardQueryKey } from './../constants/query-keys';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { itemQueryKey, tagsQueryKey } from '../constants';

import {
    API_BASE_URL,
    API_ROUTE_TEAMS,
    API_ROUTE_MEMBERS,
    API_ROUTE_TAGS,
} from '../constants/api';
import { createQueryKey } from '../helpers';
import { useToast } from '../hooks';

const getTeam = (id: string): Promise<ITeam> =>
    axios
        .get(`${API_BASE_URL}/${API_ROUTE_TEAMS}/${id}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

interface ICreateTeam {
    name: string;
    type?: string;
}

export const createTeam = (organisation: ICreateTeam): Promise<ITeam> => {
    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_TEAMS}`, organisation)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updateTeam = (
    organisation: Pick<ITeam, '_key'> & Partial<ITeam>
): Promise<ITeam> => {
    const { _key, ...rest } = organisation;

    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_TEAMS}/${_key}`, {
            ...rest,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getTeamMembers = (key: string): Promise<IOrgUser[]> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_TEAMS}/${key}/${API_ROUTE_MEMBERS}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getTags = (key: string): Promise<ITag[]> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_TEAMS}/${key}/${API_ROUTE_TAGS}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const deleteTeamMember = ({
    userKey,
    teamId,
}: {
    userKey: string;
    teamId: string;
}): Promise<IUser[]> => {
    return axios
        .delete(
            `${API_BASE_URL}/${API_ROUTE_TEAMS}/${API_ROUTE_MEMBERS}/${userKey}/${teamId}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const deleteTag = ({
    tagKey,
    teamKey,
}: {
    tagKey: string;
    teamKey: string;
}): Promise<{
    itemKeys: string[];
}> => {
    return axios
        .delete(
            `${API_BASE_URL}/${API_ROUTE_TEAMS}/${API_ROUTE_TAGS}/${tagKey}/${teamKey}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const useDeleteTag = () => {
    const queryClient = useQueryClient();
    const showToast = useToast();

    const { t } = useTranslation();

    return useMutation(deleteTag, {
        onSuccess: async res => {
            res.itemKeys.forEach(key => {
                const qk = createQueryKey(itemQueryKey, key);
                queryClient.invalidateQueries(qk);
            });

            queryClient.invalidateQueries(tagsQueryKey);
            return res;
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};

export const useGetTeam = (teamId: string) => {
    return useQuery(createQueryKey(getTeamForWhiteboardQueryKey, teamId), () =>
        getTeam(teamId)
    );
};
