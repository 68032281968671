import type { MutableRefObject } from "react";
import { useEffect, useRef, useCallback } from "react";

function useOutsideMouseDown(
  ref: MutableRefObject<HTMLElement | null>,
  handler: (e: MouseEvent) => any,
  when: boolean = true
): void {
  const savedHandler = useRef(handler);

  const memoizedCallback = useCallback((e: MouseEvent) => {
    if (ref && ref.current && !ref.current.contains(e.target as Element)) {
      savedHandler.current(e);
    }
  }, [ref]);

  useEffect(() => {
    savedHandler.current = handler;
  });

  useEffect(() => {
    if (when) {
      document.addEventListener("mousedown", memoizedCallback, true);
      document.addEventListener("contextmenu", memoizedCallback, true);
      // document.addEventListener("ontouchstart", memoizedCallback, true);

      return () => {
        document.removeEventListener("mousedown", memoizedCallback, true);
        document.removeEventListener("contextmenu", memoizedCallback, true);
        // document.removeEventListener("ontouchstart", memoizedCallback, true);
      };
    }
  }, [ref, handler, when, memoizedCallback]);
}

export { useOutsideMouseDown };