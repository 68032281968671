export const API_BASE_URL = process.env.REACT_APP_API_HOST_API_URL;

// main routes
export const API_ROUTE_ACTIVITY = 'activity';
export const API_ROUTE_ORGANISATIONS = 'organisations';
export const API_ROUTE_TEAMS = 'teams';
export const API_ROUTE_USERS = 'users';
export const API_ROUTE_MEDIA = 'media';
export const API_ROUTE_COMMENT = 'comments';
export const API_ROUTE_SESSION = 'sessions';
export const API_ROUTE_PUBLIC = 'public';

// sub routes
export const API_ROUTE_CREATE = 'create';
export const API_ROUTE_MEMBERS = 'members';
export const API_ROUTE_TAGS = 'tags';
export const API_ROUTE_USER = 'user';
export const API_ROUTE_ME = 'me';
export const API_ROUTE_ADD = 'add';
export const API_ROUTE_ORGANISATION = 'organisation';
export const API_ROUTE_CONNECTION = 'connection';
export const API_ROUTE_UPLOAD = 'upload';
export const API_ROUTE_ROOT = 'root';
export const API_ROUTE_PAYMENTS = 'payments';
export const API_ROUTE_CANCEL = 'cancel';
export const API_ROUTE_NAME = 'name';

export const API_ROUTE_PENDING_INVITES = 'pendingInvites';
export const API_ROUTE_INVITE = 'invite';
export const API_ROUTE_INVITE_EXISTS = 'inviteExists';
export const API_ROUTE_ACCEPT = 'accept';
export const API_ROUTE_JOIN = 'join';
export const API_ROUTE_LEAVE = 'leave';

export const API_ROUTE_SUBSCRIPTION = 'subscription';
export const API_ROUTE_ONBOARDING = 'onboarding';

export const API_ROUTE_ROLE = 'role';

export const API_BUCKET_DEFAULT = 'bucket/default';

export const API_ROUTE_WHITEBOARD = 'whiteboard';
export const API_ROUTE_ITEM = 'item';
export const API_ROUTE_INSTANCE = 'instance';
export const API_ROUTE_TEAM = 'team';
