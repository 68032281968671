import { TippyProps } from '@tippyjs/react';
import { Box, Paragraph } from 'theme-ui';

interface TooltipLabelProps extends Omit<TippyProps, 'children'> {
    children: string;
    direction?: 'up' | 'down' | 'left' | 'right';
}

const arrowConfig = {
    up: {
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        borderBottom: '16px solid',
        borderBottomColor: 'bgInverse',
        left: '50%',
        top: '-8px',
    },
    down: {
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        borderTop: '16px solid',
        borderTopColor: 'bgInverse',
        left: '50%',
        bottom: '-8px',
    },
    left: {
        borderTop: '16px solid transparent',
        borderBottom: '16px solid transparent',
        borderRight: '16px solid',
        borderRightColor: 'bgInverse',
        top: '50%',
        left: '-8px',
    },
    right: {
        borderTop: '16px solid transparent',
        borderBottom: '16px solid transparent',
        borderLeft: '16px solid',
        borderLeftColor: 'bgInverse',
        top: '50%',
        right: '-8px',
    },
};

const marginConfig = {
    up: { mt: 1 },
    down: { mb: 1 },
    left: { ml: 1 },
    right: { mr: 1 },
};

export const TooltipLabel = ({
    children,
    direction = 'down',
}: TooltipLabelProps) => (
    <Box
        sx={{
            backgroundColor: 'bgInverse',
            borderRadius: 2,
            px: 4,
            py: 2,
            ...marginConfig[direction],
            boxShadow: 'tooltipShadow',
            position: 'relative',
        }}
    >
        <Paragraph
            variant="labelMd"
            sx={{ color: 'textInverse', fontWeight: 'regular' }}
        >
            {children}
        </Paragraph>
        <Box
            data-popper-arrow=""
            sx={{
                width: 0,
                height: 0,
                position: 'absolute',
                ...arrowConfig[direction],
            }}
        />
    </Box>
);
