import { memo } from 'react';

interface Props {
    children: React.ReactElement;
    data: IWhiteboard;
    sidebarOpen: boolean;
    startCreateFrame: boolean;
    sessionKey: string;
}

const Component = ({ children }: Props) => children;

export const MemoComponent = memo(Component, (nextProps, prevProps) => {
    //To keep in mind. Perhaps we want a better evaluation on these props
    if (nextProps.data !== prevProps.data) return false;
    if (nextProps.sidebarOpen !== prevProps.sidebarOpen) return false;
    if (nextProps.startCreateFrame !== prevProps.startCreateFrame) return false;
    if (nextProps.sessionKey !== prevProps.sessionKey) return false;

    return true;
});
