import { Flex, IconButton, Text } from 'theme-ui';
import { Icon } from '@tmi/component-library';

interface FilterSelectionProps {
    selected: {
        category: Category;
        entry: QueryEntry<string | number>;
    }[];
    onRemoveSingleClick: (
        category: Category,
        entry: QueryEntry<string | number>
    ) => void;
    onRemoveAllClick: () => void;
}

export const FilterSelection = ({
    selected,
    onRemoveSingleClick,
    onRemoveAllClick,
}: FilterSelectionProps) =>
    selected.length > 0 ? (
        <Flex
            sx={{
                gap: 2,
                flexWrap: 'wrap',
                pl: 4,
                ml: 4,
                borderLeft: 1,
                borderColor: 'bgPrimary',
            }}
        >
            {selected.map(({ category, entry }) => (
                <FilterSelectionPill
                    key={entry.label}
                    label={entry.label}
                    onRemoveClick={() => onRemoveSingleClick(category, entry)}
                />
            ))}
            <IconButton
                sx={{ height: '24px', width: '24px' }}
                onClick={onRemoveAllClick}
            >
                <Icon iconName="xCircle" fillStyle="solid" />
            </IconButton>
        </Flex>
    ) : null;

interface FilterSelectionPillProps {
    label: string;
    onRemoveClick: () => void;
}

const FilterSelectionPill = ({
    label,
    onRemoveClick,
}: FilterSelectionPillProps) => (
    <Flex
        sx={{
            alignItems: 'center',
            borderRadius: 2,
            bg: 'actionPrimary',
            px: 2,
            py: 1,
            gap: 2,
        }}
    >
        <Text
            variant="labelSm"
            sx={{
                whiteSpace: 'nowrap',
                color: 'textInverse',
                lineHeight: 'inherit',
            }}
        >
            {label}
        </Text>
        <IconButton
            onClick={onRemoveClick}
            sx={{
                color: 'textInverse',
                height: '16px',
                width: '16px',
                '&:hover:enabled': {
                    bg: 'actionPrimaryIntense',
                },
            }}
        >
            <Icon iconName="x" size={16} />
        </IconButton>
    </Flex>
);
