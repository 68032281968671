import { ThemeUIStyleObject } from 'theme-ui';

const baseTextarea: ThemeUIStyleObject = {
    border: 'none',
    resize: 'none',
    borderRadius: 0,
    fontWeight: 'medium',
    p: 0,
    width: '100%',
    WebkitTextFillColor: theme => theme.colors?.textPrimary,

    '&:focus': {
        outline: 'none',
    },
    '&:active': {
        outline: 'none',
    },
    '&::placeholder': {
        outline: 'none',
        color: 'textPrimary',
        opacity: 0.3,
    },
    '&:disabled': {
        pointerEvents: 'none',
    },
};

export const forms: Record<string, ThemeUIStyleObject> = {
    label: {
        variant: 'forms.labelMd',
    },
    labelLg: {
        variant: 'text.labelLg',
    },
    labelMd: {
        variant: 'text.labelMd',
    },
    labelSm: {
        variant: 'text.labelSm',
    },
    labelXs: {
        variant: 'text.labelXs',
    },
    input: {
        variant: 'text.sm',
        borderRadius: 3,
        border: 'none',
        bg: 'bgSecondary',
        p: 6,
        WebkitTextFillColor: theme => theme.colors?.textPrimary,

        '&::placeholder': {
            opacity: 0.3,
        },

        '&:hover:enabled': {
            boxShadow: theme =>
                `inset 0 0 0 2px ${theme.colors?.actionPrimary}`,
        },

        '&:active:enabled, &:focus:enabled': {
            outline: 'none',
            boxShadow: theme =>
                `inset 0 0 0 2px ${theme.colors?.actionPrimary}`,
        },

        '&:disabled': {
            opacity: 0.3,
        },
    },
    textarea: {
        variant: 'text.sm',
        resize: 'none',
        borderRadius: 3,
        border: 'none',
        bg: 'bgSecondary',
        p: 6,

        '&::placeholder': {
            opacity: 0.3,
        },

        '&:hover:enabled': {
            boxShadow: theme =>
                `inset 0 0 0 2px ${theme.colors?.actionPrimary}`,
        },

        '&:active:enabled, &:focus:enabled': {
            outline: 'none',
            boxShadow: theme =>
                `inset 0 0 0 2px ${theme.colors?.actionPrimary}`,
        },

        '&:disabled': {
            opacity: 0.3,
        },
    },
    textareaHlg: {
        variant: 'text.hlg',
        lineHeight: '130%',
        ...baseTextarea,
    },
    textareaHsm: {
        variant: 'text.hsm',
        ...baseTextarea,
    },
    textareaHxs: {
        variant: 'text.hxs',
        ...baseTextarea,
    },
    textareaSm: {
        variant: 'text.sm',
        ...baseTextarea,
    },
};
