import { ThemeUIStyleObject } from 'theme-ui';

export const text: Record<string, ThemeUIStyleObject> = {
    //////// Defaults ////////
    default: {
        variant: 'text.md',
        color: 'textPrimary',
    },
    heading: {
        variant: 'text.hlg',
    },
    paragraph: {
        variant: 'text.md',
    },
    //////// Base ////////
    base: {
        textAlign: 'left',
        fontFamily: 'default',
        color: 'textPrimary',
        lineHeight: 1,
    },
    baseHeading: {
        variant: 'text.base',
        fontWeight: 'bold',
    },
    baseLabel: {
        variant: 'text.base',
        fontWeight: 'medium',
    },
    baseParagraph: {
        variant: 'text.base',
        fontWeight: 'regular',
    },
    //////// Heading ////////
    hxxl: {
        variant: 'text.baseHeading',
        fontSize: [9, 9, 9, 11],
    },
    hxl: {
        variant: 'text.baseHeading',
        fontSize: 9,
    },
    hlg: {
        variant: 'text.baseHeading',
        fontSize: 8,
    },
    hmd: {
        variant: 'text.baseHeading',
        fontSize: 7,
    },
    hsm: {
        variant: 'text.baseHeading',
        fontSize: 5,
    },
    hxs: {
        variant: 'text.baseHeading',
        fontSize: 4,
    },
    //////// Label ////////
    labelXl: {
        variant: 'text.baseLabel',
        fontSize: 5,
    },
    labelLg: {
        variant: 'text.baseLabel',
        fontSize: 4,
    },
    labelMd: {
        variant: 'text.baseLabel',
        fontSize: 3,
    },
    labelSm: {
        variant: 'text.baseLabel',
        fontSize: 2,
    },
    labelXs: {
        variant: 'text.baseLabel',
        fontSize: 1,
        fontWeight: 'regular',
        lineHeight: 0,
    },
    //////// Paragraph ////////
    xl: {
        variant: 'text.baseParagraph',
        fontSize: 6,
    },
    lg: {
        variant: 'text.baseParagraph',
        fontSize: 5,
    },
    md: {
        variant: 'text.baseParagraph',
        fontSize: 4,
    },
    sm: {
        variant: 'text.baseParagraph',
        fontSize: 3,
    },
    xs: {
        variant: 'text.baseParagraph',
        fontSize: 2,
        lineHeight: 0,
    },
    //////// Lists ///////
    li: {
        variant: 'text.baseParagraph',
        display: 'block',
        '::before': {
            content: '"-"',
            mx: 2,
        },
    },
};
