import { createSlice } from '@reduxjs/toolkit';

const initialState = {} as ITeam[];

const teamSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setAllTeams: (state, { payload: { teams } }) => {
            return teams;
        },
        addTeam: (state, { payload: { team } }) => {
            return state.concat(team);
        },
    },
});

export const teamReducer = teamSlice.reducer;
export const { setAllTeams, addTeam } = teamSlice.actions;

// @TODO rename the key to the name of the slice 'teams'
export const selectTeams = state => state.teamState;
