import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CardMode } from '../../../../../types/enums';

const initialState: IItemInstance = {
    _id: '',
    _key: '',
    _rev: '',
    updatedAt: '',
    createdAt: '',
    type: 'goal',
    cardScale: 1,
    dueDate: '',
    assignees: [],
    tags: [],
    notifications: [],
    status: '',
    position: {
        x: 0,
        y: 0,
    },
    title: '',
    cardMode: CardMode.VIEW,
};
export const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        setItem: (
            state,
            {
                payload: { item },
            }: PayloadAction<{
                item: IItemInstance;
            }>
        ) => {
            state.position = item.position;
        },
        onDragEnd: (
            state,
            {
                payload: { position },
            }: PayloadAction<{
                position: ICoordinates;
            }>
        ) => {
            state.position = position;
            state.tempPosition = { x: 0, y: 0 };
        },

        onDragMove: (
            state,
            {
                payload: { position },
            }: PayloadAction<{
                position: ICoordinates;
            }>
        ) => {
            state.tempPosition = position;
        },

        setItemProperty: (
            state,
            {
                payload,
            }: PayloadAction<{
                [key: string]: any;
            }>
        ) => {
            state = Object.assign(state, { ...payload });
        },

        setTempScale: (
            state,
            {
                payload: { tempTranslate, cardScale },
            }: PayloadAction<{
                cardScale: number;
                tempTranslate: ICoordinates;
            }>
        ) => {
            state.cardScale = cardScale;
            state.tempTranslate = tempTranslate;
        },
        setScale: (
            state,
            {
                payload: { position, cardScale },
            }: PayloadAction<{
                cardScale: number;
                position: ICoordinates;
            }>
        ) => {
            state.cardScale = cardScale;
            state.position = position;
            state.tempTranslate = { x: 0, y: 0 };
        },
        setCardMode: (
            state,
            {
                payload: { cardMode, currentlyEditedBy },
            }: PayloadAction<{
                cardMode: CardMode;
                currentlyEditedBy: string;
            }>
        ) => {
            state.currentlyEditedBy = currentlyEditedBy;
            state.cardMode = cardMode;
        },
        setCard: (state, { payload: data }: PayloadAction<IItemInstance>) => {
            Object.assign(state, data);
        },
    },
});

export const cardReducer = cardSlice.reducer;
export const {
    setItem,
    setItemProperty,
    setScale,
    setTempScale,
    onDragEnd,
    onDragMove,
    setCardMode,
    setCard,
} = cardSlice.actions;

export const reducerActions = cardSlice.actions;
