import { useCallback } from 'react';
import { Box, Button, Flex, Heading, Paragraph, Text } from 'theme-ui';
import { useMarkAllAsRead } from '../../../../api';
import { Activity } from '../../../../components/activity/activity';
import { getShortName } from '../../../../helpers';
import { useAppSelector, useTranslationPrefix } from '../../../../hooks';
import { getUserForActiveOrg } from '../../../../redux/reducers';
import { OrganisationSwitcher } from './organisation-switcher';

export const ActivitySidebar = ({
    activityData,
    hasUnreadNotifications,
}: {
    activityData?: IActivityData[];
    hasUnreadNotifications: boolean;
}) => {
    const { tr } = useTranslationPrefix('sidebar.activity');
    const { fullName } = useAppSelector(getUserForActiveOrg);

    const { mutate: markAll } = useMarkAllAsRead();

    const handleMarkAll = useCallback(() => {
        const notificationIds = activityData
            ?.filter(d => !d.activityMeta.hasRead)
            .map(({ _id }) => _id);

        if (notificationIds && notificationIds?.length > 0) {
            markAll({ notificationIds });
        }
    }, [activityData, markAll]);

    return (
        <Flex sx={{ width: '100%', height: '100%', flexDirection: 'column' }}>
            <OrganisationSwitcher />
            <Box
                sx={{ px: 4, py: 6, borderBottom: 1, borderColor: 'bgPrimary' }}
            >
                <Heading variant="hmd" as="h1">
                    {tr('title', { name: getShortName(fullName) })}
                </Heading>
            </Box>
            {!!activityData && (
                <>
                    <Box
                        sx={{
                            px: 4,
                            py: 3,
                            borderBottom: 1,
                            borderColor: 'bgPrimary',
                        }}
                    >
                        <Text variant="labelSm">{tr('subtitle')}</Text>
                    </Box>

                    <Flex
                        sx={{
                            px: 4,
                            py: 2,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: 1,
                            borderColor: 'bgPrimary',
                        }}
                    >
                        <Text variant="labelXs">{tr('columnHeader')}</Text>

                        {hasUnreadNotifications ? (
                            <Button onClick={handleMarkAll} variant="subtle">
                                {tr('actions.markAsRead')}
                            </Button>
                        ) : null}
                    </Flex>
                    <Flex
                        sx={{
                            overflowY: 'auto',
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        {activityData.length > 0 ? (
                            <Box>
                                {activityData.map(notification => {
                                    // user is atm possibly undefined when it's removed from organisation
                                    // this won't happen when removed users will be anonimised.
                                    if (!notification.user) return null;
                                    return (
                                        <Activity
                                            data={notification}
                                            key={notification._id}
                                        />
                                    );
                                })}
                            </Box>
                        ) : (
                            <Paragraph
                                sx={{ mt: 20, px: 12, textAlign: 'center' }}
                            >
                                {tr('empty')}
                            </Paragraph>
                        )}
                    </Flex>
                </>
            )}
        </Flex>
    );
};
