export const headShapeArrow = {
    svgElem: (
        <path d="M0.42968 0.0292893C0.390628 0.0683417 0.390628 0.131658 0.42968 0.170711L0.758969 0.5L0.42968 0.829289C0.390628 0.868342 0.390628 0.931658 0.42968 0.970711C0.468732 1.00976 0.532049 1.00976 0.571101 0.970711L0.971101 0.570711C1.01015 0.531658 1.01015 0.468342 0.971101 0.429289L0.571101 0.0292893C0.532049 -0.00976313 0.468732 -0.00976313 0.42968 0.0292893Z" />
    ),
    offsetForward: 0.9,
};

export const tailShapeActive = theme => ({
    svgElem: (
        <svg
            width="1"
            height="1"
            viewBox="0 0 1 1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.28"
                y="0.28"
                width="0.43"
                height="0.43"
                rx="0.03"
                fill="white"
                stroke={theme!.colors!.primary as string}
                strokeWidth="0.06"
            />
        </svg>
    ),
    offsetForward: 0,
});

export const headShapeArrowActive = theme => ({
    svgElem: (
        <svg
            width="1"
            height="1"
            viewBox="0 0 1 1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_868:71298)">
                <path d="M0.521006 0.518416C0.543043 0.49638 0.543043 0.460651 0.521006 0.438615L0.1619 0.079509C0.139864 0.0574725 0.104135 0.0574725 0.0820988 0.079509C0.0600622 0.101546 0.0600622 0.137274 0.0820988 0.15931L0.401304 0.478516L0.0820988 0.797721C0.0600622 0.819757 0.0600622 0.855486 0.0820988 0.877522C0.104135 0.899559 0.139864 0.899559 0.1619 0.877522L0.521006 0.518416ZM0.451172 0.534944L0.481105 0.534944L0.481105 0.422088L0.451172 0.422088L0.451172 0.534944Z" />
                <rect
                    x="0.541886"
                    y="0.262589"
                    width="0.432044"
                    height="0.432044"
                    rx="0.028214"
                    fill="white"
                    stroke={theme!.colors!.primary as string}
                    strokeWidth="0.0564281"
                />
                <path d="M0.0474187 0.131183C0.0233874 0.107273 0.023289 0.0684094 0.0471989 0.0443782V0.0443782C0.0711088 0.0203469 0.109973 0.0202485 0.134004 0.0441584L0.541266 0.449363L0.454681 0.536388L0.0474187 0.131183Z" />
                <path d="M0.0434648 0.823942C0.0194598 0.847826 0.0193615 0.886647 0.0432452 0.910652V0.910652C0.0671289 0.934657 0.10595 0.934756 0.129955 0.910872L0.537265 0.50562L0.450774 0.41869L0.0434648 0.823942Z" />
            </g>
            <defs>
                <clipPath id="clip0_868:71298">
                    <rect width="1" height="1" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    offsetForward: 0.9,
});
