import { Theme } from 'theme-ui';

export const selectFieldStyles = (theme: Theme) => ({
    control: (base, state) => ({
        ...base,
        border: 'none',
        borderRadius: theme!.radii![3],
        boxShadow: 'none',
        '&:hover': {
            boxShadow: state.menuIsOpen
                ? 'none'
                : `inset 0 0 0 2px ${theme!.colors!.primary}`,
        },

        '&:active:enabled, &:focus:enabled': {
            boxShadow: state.menuIsOpen
                ? 'none'
                : `inset 0 0 0 2px ${theme!.colors!.primary}`,
        },

        '&:disabled': {
            opacity: 0.3,
        },
    }),
    dropdownIndicator: base => ({
        ...base,
        display: 'inline',
    }),
    indicatorContainer: base => ({
        ...base,
        marginRight: theme!.space![1],
    }),
    input: base => ({
        ...base,
        fontFamily: (theme!.fonts! as any).default,
        lineHeight: 0,
    }),
    menu: base => ({
        ...base,
        marginTop: theme!.space![2],
        borderRadius: 0,
    }),
    menuList: base => ({
        ...base,
        padding: theme!.space![2],
    }),
    option: base => ({
        ...base,
        border: 'none',
        borderRadius: theme!.radii![2],
    }),
    placeholder: base => ({
        ...base,
        fontFamily: (theme!.fonts! as any).default,
        opacity: 0.3,
    }),
    singleValue: base => ({
        ...base,
        fontFamily: (theme!.fonts! as any).default,
    }),
    valueContainer: base => ({
        ...base,
        border: 'none',
        padding: theme!.space![6],
    }),
});
