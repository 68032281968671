import { useMouse } from 'rooks';
import { Connection } from '../../../components/connection';
import { useConnectionData } from '../../../providers/connection';
import { MOUSE_PIXEL_ID } from '../constants/general';

export const Pending = () => {
    const { clientX, clientY } = useMouse();

    const x = clientX ?? 0;
    const y = clientY ?? 0;

    return (
        <>
            <div
                id={MOUSE_PIXEL_ID}
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    transform: `translate(${clientX}px, ${clientY}px)`,
                    visibility: 'hidden',
                    pointerEvents: 'none',
                    width: 1,
                    height: 1,
                }}
            />

            <ConnectionWrapper x={x} y={y} />
        </>
    );
};

export const PendingConnection = () => {
    const { initialRef } = useConnectionData();

    if (!initialRef) return null;
    return <Pending />;
};

const ConnectionWrapper = ({ x, y }) => {
    const { initialRef } = useConnectionData();
    if (!initialRef) return null;

    if (!x || !y) return null;
    return (
        <Connection
            fromRef={initialRef}
            toRef={MOUSE_PIXEL_ID}
            isPendingConnection
        />
    );
};
