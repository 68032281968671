import { Dispatch, SetStateAction } from 'react';
import { Route, Switch } from 'react-router';
import { ReactZoomPanPinchRef } from '@tmi/react-zoom-pan-pinch';

import { Sidebar, SidebarTriggerButton } from '../../../components';
import { checkInSessionEdit, checkInSessionIntro } from '../../../helpers';
import { Overview } from '../components/session/sidebar/overview';
import { SidebarCardContent } from '../components/sidebar';
import { SessionRouter } from '../components/sidebar';
import {
    SESSION_KEY,
    SESSION_ROUTE,
    WHITEBOARD_KEY,
    WHITEBOARD_ROUTE,
} from '../../../constants';
import SidebarNavigation from '../components/sidebar/navigation';
import { Flex } from 'theme-ui';
import { ActivitySidebar } from '../components/sidebar/activity';
import { useSidebarData } from '../../../providers/sidebar';
import { useGetActivity } from '../../../api';

const baseRoute = `/${WHITEBOARD_ROUTE}/${WHITEBOARD_KEY}`;
const sessionsOverviewRoute = `/${WHITEBOARD_ROUTE}/${WHITEBOARD_KEY}/${SESSION_ROUTE}`;
const sessionRoute = `/${WHITEBOARD_ROUTE}/${WHITEBOARD_KEY}/${SESSION_ROUTE}/${SESSION_KEY}`;

interface Props extends Pick<ReactZoomPanPinchRef, 'zoomToElement'> {
    startCreateFrame: boolean;
    setStartCreateFrame: Dispatch<SetStateAction<boolean>>;
}

export const SidebarRouter = ({
    setStartCreateFrame,
    zoomToElement,
    startCreateFrame,
}: Props) => {
    const {
        isSidebarOpen,
        currentlyActiveCard,
        previouslyActiveCard,
    } = useSidebarData();
    const isInSessionIntroOrEdit =
        checkInSessionIntro(window.location.pathname) ||
        checkInSessionEdit(window.location.pathname);
    const { data: activityData } = useGetActivity();
    const hasUnreadNotifications = !!activityData?.find(
        ({ activityMeta }) => !activityMeta.hasRead
    );

    const activeCard = currentlyActiveCard || previouslyActiveCard;

    return (
        <>
            {!isInSessionIntroOrEdit && (
                <SidebarTriggerButton
                    hasUnreadNotifications={hasUnreadNotifications}
                />
            )}

            <Sidebar isOpen={isSidebarOpen}>
                <Flex sx={{ height: '100%' }}>
                    <SidebarNavigation
                        hasUnreadNotifications={hasUnreadNotifications}
                    />
                    {activeCard && isSidebarOpen && (
                        <SidebarCardContent itemKey={activeCard.key} />
                    )}
                    {!activeCard && (
                        <Switch>
                            <Route
                                path={sessionRoute}
                                render={() => (
                                    <SessionRouter
                                        setStartCreateFrame={
                                            setStartCreateFrame
                                        }
                                        startCreateFrame={startCreateFrame}
                                        zoomToElement={zoomToElement}
                                    />
                                )}
                            />

                            <Route
                                path={sessionsOverviewRoute}
                                render={() => <Overview />}
                            />

                            <Route
                                path={baseRoute}
                                render={() => (
                                    <ActivitySidebar
                                        activityData={activityData}
                                        hasUnreadNotifications={
                                            hasUnreadNotifications
                                        }
                                    />
                                )}
                            />
                        </Switch>
                    )}
                </Flex>
            </Sidebar>
        </>
    );
};
