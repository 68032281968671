import { useCallback } from 'react';
import { Text, Paragraph } from 'theme-ui';
import { Icon, IconName } from '@tmi/component-library';
import { useMarkAsRead } from '../../api';
import { useTransformContext } from '@tmi/react-zoom-pan-pinch';
import { ZOOM_TO_ELEMENT_SCALE } from '../../constants';
import { BaseActivityComp } from './base-activity';
import { ActivityTypes } from '@tmi/shared-types';
import { ItemStatusUpdate } from './activity-types/item-status-update';
import { ItemAssigneeUpdate } from './activity-types/item-assignee-update';

const getCardTypeIcon = (type: string): IconName => {
    switch (type) {
        case 'goal':
            return 'flag';
        case 'result':
            return 'checkCircle';
        case 'action':
            return 'lightningBolt';
        case 'behaviour':
            return 'behaviour';
        case 'value':
            return 'heart';
        case 'deleted':
            return 'trash';
        default: {
            return 'x';
        }
    }
};

interface Props {
    data: IActivityData;
}

export const Activity = ({ data }: Props) => {
    const { activityMeta, itemInstance } = data;

    const { mutate } = useMarkAsRead();
    const { zoomToElement } = useTransformContext();

    const zoomToElementCB = useCallback(() => {
        if (!activityMeta.parent) return;
        zoomToElement(activityMeta.parent, ZOOM_TO_ELEMENT_SCALE);
    }, [activityMeta.parent, zoomToElement]);
    const handleMarkAsRead = useCallback(() => {
        mutate({ notificationId: data._id });
    }, [data._id, mutate]);

    if (!data?.user) return null;

    // TODO: Add session to this logic
    const { title, type } = itemInstance || {
        title: 'This item has been removed',
        type: 'deleted',
    };

    return (
        <BaseActivityComp
            handleMarkAsRead={handleMarkAsRead}
            notification={data}
            zoomToElement={zoomToElementCB}
            label={
                <>
                    <Icon
                        size={16}
                        iconName={getCardTypeIcon(type)}
                        fillStyle="solid"
                    />
                    <Text
                        variant="labelXs"
                        sx={{
                            color: 'inherit',
                            ml: 1,
                        }}
                    >
                        {title}
                    </Text>
                </>
            }
        >
            <ActivityContent notification={data} />
        </BaseActivityComp>
    );
};

interface ActivityContentProps {
    notification: IActivityData;
}

const ActivityContent = ({ notification }: ActivityContentProps) => {
    switch (notification.activityMeta.type) {
        case ActivityTypes.ITEM_UPDATE_STATUS:
            return (
                <ItemStatusUpdate
                    oldDoc={notification.oldDoc as IItemInstance}
                    newDoc={notification.newDoc as IItemInstance}
                />
            );
        case ActivityTypes.ITEM_UPDATE_ASSIGNEES:
            return (
                <ItemAssigneeUpdate
                    oldDoc={notification.oldDoc as IItemInstance}
                    newDoc={notification.newDoc as IItemInstance}
                />
            );
        default:
            return (
                <Paragraph variant="xs" sx={{ mt: 2 }}>
                    {(notification.newDoc as IComment).body}
                </Paragraph>
            );
    }
};
