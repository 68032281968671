import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (event: Event) => void
) {
    useEffect(() => {
        const listener = (event: any) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            } else handler(event);
        };
        document.addEventListener(`mouseup`, listener);
        document.addEventListener(`touchend`, listener);
        return () => {
            document.removeEventListener(`mouseup`, listener);
            document.removeEventListener(`touchend`, listener);
        };
        // Reload only if ref or handler changes
    }, [ref, handler]);

    return ref;
}
