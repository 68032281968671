import React from 'react';
import { AxiosResponse } from 'axios';
import { Flex, Heading, Container, Link } from 'theme-ui';
import { useTranslationPrefix } from '../../hooks';

interface Props {
    axiosError?: AxiosResponse;
    customError?: {
        message?: string;
        status?: number;
    };
}
export const InvalidInviteError: React.FC<Props> = () => {
    const { tr } = useTranslationPrefix('error_pages');

    const header = tr('invalid_invite.title');
    const action = tr('invalid_invite.action');

    return (
        <Flex
            sx={{
                bg: 'bgPrimary',
                height: 'inherit',
                overflowY: 'auto',
            }}
        >
            <Container sx={{ display: 'flex', flexDirection: 'column' }}>
                <Flex
                    sx={{
                        flexDirection: 'column',
                        width: '768px',
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <Heading
                        as="h2"
                        variant="hxxl"
                        sx={{ color: 'textTertiary' }}
                    >
                        {header}
                    </Heading>

                    <Link
                        href="https://www.kinety.app"
                        variant="button"
                        sx={{ mt: 8, mr: 'auto' }}
                    >
                        {action}
                    </Link>
                </Flex>
            </Container>
        </Flex>
    );
};

export default Error;
