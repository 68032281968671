import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Loader } from '../../../components';

const ProtectedRoute = ({ component, ...args }) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <Loader />,
            returnTo: () => window.location.pathname + window.location.search,
        })}
        {...args}
    />
);

export default ProtectedRoute;

export const ProtectedRouteWithRender = ({ component: Component, path }) => {
    const { isAuthenticated } = useAuth0();

    if (!isAuthenticated) {
        return <Redirect to="/" />;
    }
    return <Route path={path} render={props => <Component {...props} />} />;
};
