import React, { useCallback } from 'react';
import { Flex, IconButton, Paragraph } from '@theme-ui/components';
import { Icon } from '@tmi/component-library/src';
import {
    ReactZoomPanPinchRef,
    useTransformContext,
} from '@tmi/react-zoom-pan-pinch';
import { Toolbar } from './toolbar';
import { TopRightMenu } from './top-right-menu';
import { WhiteboardButtonWrapper } from './whiteboard-button-wrapper';

import { ActiveSessionUsers } from './active-session-user';
import {
    checkInSessionEdit,
    checkInSessionIntro,
    checkInSessionView,
} from '../../../helpers';
import { SessionNavigationContainer } from './session/session-navigation.container';
import { FilterInput } from './filter';
import { motion } from 'framer-motion';
import { useSidebarData } from '../../../providers/sidebar';
import { ConversationalOnboarding } from './conversational-onboarding-modal';

interface Props extends ReactZoomPanPinchRef {}

const MotionFlex = motion(Flex);

const bottomVariants = {
    open: { width: `calc(100% - min(33vw, 455px))` },
    closed: { width: '100%' },
};

const topVariants = {
    open: { width: `calc(100% - min(33vw, 455px))`, paddingLeft: '16px' },
    closed: { width: '100%', paddingLeft: '64px' },
};

const transition = {
    ease: [0.4, 0.13, 0.4, 0.96],
    duration: 0.4,
};

export const WhiteboardControls: React.FC<Props> = ({
    zoomOut,
    zoomIn,
    resetTransform,
    zoomToElement,
}) => {
    const { isSidebarOpen } = useSidebarData();
    const isInSessionEdit = checkInSessionEdit(window.location.pathname);
    const isInSessionIntro = checkInSessionIntro(window.location.pathname);
    const isInSessionView = checkInSessionView(window.location.pathname);

    const showToolbar = !isInSessionIntro && !isInSessionEdit;

    const zoomTo = useCallback(id => {
        zoomToElement(id);
    }, []); //eslint-disable-line

    return (
        <>
            {/* Bottom bar */}
            <Flex
                sx={{
                    position: 'fixed',
                    bottom: 6,
                    left: 6,
                    right: 6,
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }}
            >
                <Flex
                    sx={{
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                    }}
                >
                    {showToolbar && <Toolbar sx={{ borderRadius: 4 }} />}

                    <WhiteboardButtonWrapper
                        sx={{
                            position: 'absolute',
                            right: 0,
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <IconButton
                            sx={{ borderRadius: 1 }}
                            onClick={() => zoomOut()}
                        >
                            <Icon iconName={'zoomOut'} fillStyle="solid" />
                        </IconButton>
                        <IconButton
                            sx={{ borderRadius: 1 }}
                            onClick={() => zoomIn()}
                        >
                            <Icon iconName={'zoomIn'} fillStyle="solid" />
                        </IconButton>
                        <IconButton
                            sx={{ borderRadius: 1 }}
                            onClick={() => resetTransform()}
                        >
                            <Icon iconName={'reply'} fillStyle="solid" />
                        </IconButton>
                        <ShowZoomPercentage />
                    </WhiteboardButtonWrapper>
                </Flex>

                {/* Session controls */}
                {isInSessionView ? (
                    <MotionFlex
                        initial={false}
                        animate={isSidebarOpen ? 'open' : 'closed'}
                        variants={bottomVariants}
                        transition={transition}
                        sx={{
                            alignSelf: 'flex-end',
                            mt: 4,
                        }}
                    >
                        <SessionNavigationContainer zoomToElement={zoomTo} />
                    </MotionFlex>
                ) : null}
            </Flex>

            <ConversationalOnboarding />

            {/* Top bar */}
            {isInSessionView && <ActiveSessionUsers />}
            <Flex
                sx={{
                    position: 'fixed',
                    top: 6,
                    right: 6,
                    left: 6,
                    flexDirection: 'column',
                    justifyContent: 'stretch',
                    pointerEvents: 'none',
                }}
            >
                <MotionFlex
                    initial={false}
                    animate={isSidebarOpen ? 'open' : 'closed'}
                    variants={topVariants}
                    transition={transition}
                    sx={{
                        alignSelf: 'flex-end',
                        justifyContent: 'space-between',
                        gap: 2,
                        alignItems: 'flex-start',
                    }}
                >
                    <FilterInput />
                    <TopRightMenu />
                </MotionFlex>
            </Flex>
        </>
    );
};

const ShowZoomPercentage = () => {
    const {
        state: { scale },
    } = useTransformContext();
    const zoomPercentage = Math.round(scale * 100).toString();

    return (
        <Paragraph
            variant="labelMd"
            sx={{
                width: '42px',
                textAlign: 'right',
            }}
        >
            {zoomPercentage}%
        </Paragraph>
    );
};
