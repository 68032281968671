import { useEffect } from 'react';
import { Box } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { usePreviousImmediate } from 'rooks';

const Menu = ({
    as,
    ref,
    sx,
    ...props
}: React.HTMLProps<HTMLDivElement> & { sx?: ThemeUIStyleObject }) => (
    <Box
        sx={{
            position: 'absolute',
            zIndex: 2,
            mt: 2,
            left: '50%',
            top: '100%',
            transform: 'translateX(-50%)',
            ...sx,
        }}
        {...props}
    />
);

const Blanket = ({ as, ref, ...props }: React.HTMLProps<HTMLDivElement>) => (
    <Box
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
        }}
        {...props}
    />
);

interface PopoutProps {
    children: React.ReactElement;
    isOpen: boolean;
    onClose: () => void;
    target: React.ReactElement;
    menuPosition?: ThemeUIStyleObject;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    onMenuUnmount?: () => void;
    onMenuMouseEnter?: () => void;
    onMenuMouseLeave?: () => void;
}

export const Popout = ({
    children,
    isOpen,
    onClose,
    target,
    menuPosition,
    onMenuOpen = () => {},
    onMenuClose = () => {},
    onMenuUnmount = () => {},
    onMenuMouseEnter = () => {},
    onMenuMouseLeave = () => {},
}: PopoutProps) => {
    const wasOpen = usePreviousImmediate(isOpen);

    useEffect(() => {
        return () => {
            onMenuUnmount();
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if (isOpen && !wasOpen) {
            onMenuOpen();
        }
        if (wasOpen && !isOpen) {
            onMenuClose();
        }
    }, [onMenuOpen, onMenuClose, isOpen, wasOpen]);

    return (
        <>
            {target}
            {isOpen ? (
                <Menu
                    sx={menuPosition}
                    onMouseEnter={onMenuMouseEnter}
                    onMouseLeave={onMenuMouseLeave}
                >
                    {children}
                </Menu>
            ) : null}
            {isOpen ? <Blanket onClick={onClose} /> : null}
        </>
    );
};
