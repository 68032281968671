import { ThemeUIStyleObject, Flex, Box, Button } from 'theme-ui';

interface NotificationIndicatorProps {
    sx?: ThemeUIStyleObject;
    onClick: () => void;
}

export const NotificationIndicator = ({
    sx,
    onClick,
}: NotificationIndicatorProps) => {
    return (
        <Flex
            sx={{ position: 'relative', ...sx }}
            css={{
                '#selector:hover + #button, #button:hover': {
                    opacity: 1,
                },
            }}
        >
            <Box
                id="selector"
                sx={{
                    height: '6px',
                    width: '6px',
                    borderRadius: 5,
                    bg: 'alarming',
                    position: 'relative',
                    zIndex: 1,
                }}
            ></Box>
            <Flex
                id="button"
                sx={{
                    bg: 'actionSecondary',
                    position: 'absolute',
                    opacity: 0,
                    top: '-6px',
                    right: '-6px',
                    bottom: '-6px',
                    alignItems: 'center',
                    p: 1,
                }}
            >
                <Button
                    onClick={onClick}
                    variant="subtle"
                    sx={{
                        whiteSpace: 'nowrap',
                        mr: 4,
                    }}
                >
                    Mark as read
                </Button>
            </Flex>
        </Flex>
    );
};
