import React, { useContext } from 'react';
import { TransformWrapper } from '@tmi/react-zoom-pan-pinch';
import { WHITEBOARD_SIZE } from '../../../constants';

import { WhiteboardContext } from './whiteboard.container';

interface Props {}

const defaultScale = 1;

export const PanPinchContainer: React.FC<Props> = ({ children }) => {
    const { panPinchDisabled } = useContext(WhiteboardContext);

    return (
        <TransformWrapper
            disabled={panPinchDisabled}
            initialPositionX={-WHITEBOARD_SIZE.width / 2}
            initialPositionY={-WHITEBOARD_SIZE.height / 2}
            initialScale={defaultScale}
            limitToBounds={true}
            minScale={0.2}
            maxScale={4}
            doubleClick={{ disabled: true }}
            panning={{
                excluded: ['input', 'textarea'],
            }}
            centerOnInit={false}
            pinch={{ disabled: true }}
            wheel={{ step: 0.9, touchPadDisabled: false }}
        >
            {children}
        </TransformWrapper>
    );
};
