import React from 'react';
import { IndividialIconProps } from '../Icon';

export const Behaviour = ({ fillStyle }: IndividialIconProps) => {
    const Stroke = null;
    const Fill = (
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9897 11.5256L10.0931 11.9936C9.17179 12.4852 8.0052 12.2512 7.3246 11.4356L6.88361 10.9068C6.51012 10.4568 6.54049 9.80549 6.95448 9.39263L9.70165 6.64547L9.16054 6.3271C9.07392 6.27648 8.97492 6.24948 8.8748 6.24948H6.83186L7.12773 5.65775C7.26722 5.37988 7.1536 5.04239 6.87573 4.9029L5.18829 4.05917C4.91155 3.9208 4.57406 4.0333 4.43456 4.31117L1.05967 11.061C0.920172 11.3388 1.03379 11.6763 1.31166 11.8158L2.99911 12.6595C3.07898 12.7 3.16448 12.718 3.24997 12.718C3.45697 12.718 3.65496 12.6033 3.75396 12.4075L3.9452 12.0262L8.30669 15.2514C8.80505 15.6193 9.39903 15.8027 9.99189 15.8027C10.5949 15.8027 11.1956 15.6126 11.694 15.2334L13.6514 13.7305L10.9897 11.5256Z" />
            <path d="M18.941 11.0612L15.5661 4.31137C15.4266 4.03238 15.0902 3.92101 14.8113 4.05938L13.1238 4.9031C12.8459 5.0426 12.7334 5.38008 12.8718 5.65795L13.1688 6.24968H11.6895L7.75098 10.1871L8.19084 10.7158C8.53058 11.123 9.10993 11.2423 9.57004 10.9982L11.1349 10.1826L14.5716 13.0298L16.0071 11.9229L16.249 12.4066C16.348 12.6035 16.5471 12.7171 16.7518 12.7171C16.8362 12.7171 16.9217 12.698 17.0038 12.6575L18.6913 11.8138C18.968 11.6765 19.0794 11.339 18.941 11.0612Z" />
        </svg>
    );

    return Fill;
};
