import { useEffect, useRef } from 'react';
import { usePreviousImmediate } from 'rooks';
import { Flex, Box } from 'theme-ui';
import { BaseCardProps } from '..';
import {
    PeoplePicker,
    TagPicker,
    DueDatePicker,
    TitleField,
} from '../../../../../components';
import {
    CardTypes,
    CARD_META,
    GOAL_TITLE_MAX_LENGTH,
} from '../../../constants/card';
import { useTranslationPrefix } from '../../../../../hooks';
import { CardMode } from '../../../../../types/enums';
import { alphabeticalTagSort } from '../../../../../helpers/alphabetical-sort';
import { StatusPicker } from '../../../../../components/status-picker';
import { getHighlightStyle } from '../../../../../helpers/filter/filter';
import { getEditOutlineStyle, getLockedOverlayStyle } from '../../../helpers';

interface GoalProps extends BaseCardProps {
    data: GoalData;
}

export const Goal = ({
    data,
    cardMode,
    dispatchHandler,
    cardScale,
    isLocked,
    isGhost,
    isHighlight,
    sx,
}: GoalProps) => {
    const { tr } = useTranslationPrefix('whiteboard.cards.goal');
    const { title, assignees, tags, dueDate, status } = data;
    const isEditing = cardMode === CardMode.EDIT;
    const titleRef = useRef<HTMLTextAreaElement>(null);
    const { width, height } = CARD_META['goal'];
    const previousCardMode = usePreviousImmediate<CardMode>(cardMode);
    const hasLeftEditMode =
        previousCardMode === CardMode.EDIT && cardMode !== CardMode.EDIT;

    useEffect(() => {
        if (hasLeftEditMode) {
            titleRef?.current?.blur();
        }
    }, [isEditing, hasLeftEditMode]);

    return (
        <Box
            sx={{
                width: `${width * cardScale}px`,
                height: `${height * cardScale}px`,
            }}
        >
            <Flex
                sx={{
                    position: 'relative',
                    borderRadius: 5,
                    bg: 'bgSecondary',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: `${width}px`,
                    height: `${height}px`,
                    transformOrigin: 'top left',
                    transform: `scale(${cardScale})`,
                    boxShadow: 'cardShadow',
                    pointerEvents: isLocked || isGhost ? 'none' : 'auto',
                    ...getEditOutlineStyle(CardTypes.GOAL, isEditing),
                    ...getLockedOverlayStyle(CardTypes.GOAL, isLocked),
                    ...getHighlightStyle(isHighlight),
                    ...sx,
                }}
            >
                <PeoplePicker
                    value={assignees}
                    onChange={dispatchHandler('assignees')}
                    isEditActive={isEditing}
                    avatarSize={20}
                />
                <DueDatePicker
                    value={dueDate}
                    onChange={dispatchHandler('dueDate')}
                    sx={{ mt: 2 }}
                    isEditActive={isEditing}
                />
                <TitleField
                    ref={titleRef}
                    value={title}
                    disabled={!isEditing}
                    placeholder={tr('title.placeholder')}
                    onChange={({ currentTarget }) =>
                        dispatchHandler('title')(currentTarget.value)
                    }
                    sx={{
                        textAlign: 'center',
                        width: '177px',
                        height: '54px',
                        mt: 4,
                        hyphens: 'auto',
                    }}
                    variant="textareaSm"
                    maxLength={GOAL_TITLE_MAX_LENGTH}
                />
                <TagPicker
                    value={[...tags].sort(alphabeticalTagSort)}
                    onChange={dispatchHandler('tags')}
                    isEditActive={isEditing}
                    sx={{ mt: 4, width: '160px' }}
                    variant="xs"
                />
                <StatusPicker
                    value={status ?? ''}
                    onChange={dispatchHandler('status')}
                    isEditActive={isEditing}
                    sx={{ mt: 2 }}
                />
            </Flex>
        </Box>
    );
};
