import React from 'react';
import { Flex, Heading, Paragraph, Container, Image, Link } from 'theme-ui';
import Logo from '@tmi/ui-assets/src/images/logo.png';
import Shapes from '@tmi/ui-assets/src/images/shapes.png';
import WindowSprite from '@tmi/ui-assets/src/images/window-sprite.png';
import { useTranslationPrefix } from '../../hooks';

export const MobileView: React.FC = () => {
    const { tr } = useTranslationPrefix('general.mobile');

    return (
        <Flex
            sx={{
                bg: 'primary',
                height: 'inherit',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Image
                src={Logo}
                sx={{
                    position: 'absolute',
                    top: 6,
                    left: 6,
                    height: '40px',
                    zIndex: 0,
                }}
            />
            <Image
                src={Shapes}
                sx={{
                    position: 'absolute',
                    top: 5,
                    right: -5,
                    height: '300px',
                    zIndex: 0,
                }}
            />
            <Image
                src={WindowSprite}
                sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    zIndex: 0,
                }}
            />
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: '50vh',
                    zIndex: 1,
                }}
            >
                <Flex
                    sx={{
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'start',
                        maxWidth: '668px',
                        color: 'textInverse',
                    }}
                >
                    <Heading
                        as="h1"
                        variant="hmd"
                        sx={{
                            color: 'textInverse',
                            mt: 6,
                        }}
                    >
                        {tr('title')}
                    </Heading>

                    <Paragraph
                        variant="xs"
                        sx={{ color: 'textInverse', mt: 4 }}
                    >
                        {tr('body')}
                        <br />
                        <br />
                        {tr('suggestion')}
                        <br />
                        <br />
                        <Link href="https://www.kinety.app">{tr('link')}</Link>
                    </Paragraph>
                </Flex>
            </Container>
        </Flex>
    );
};
