import React from 'react';
import { Flex } from '@theme-ui/components';
import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { addElementToDom } from '../../helpers';
import { SIDEBAR_ID } from '../../constants';

const MotionFlex = motion(Flex);

type SidebarProps = {
    children: React.ReactNode;
    isOpen?: boolean;
};

export const Sidebar = ({ children, isOpen }: SidebarProps) => {
    const node =
        document.getElementById(SIDEBAR_ID) ?? addElementToDom(SIDEBAR_ID);

    return createPortal(
        <AnimatePresence initial={false}>
            {isOpen && (
                <MotionFlex
                    initial={{ x: '-100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100%' }}
                    transition={{
                        ease: [0.4, 0.13, 0.4, 0.96],
                        duration: 0.4,
                    }}
                    sx={{
                        width: `${100 / 3}vw`,
                        maxWidth: '455px',
                        flexDirection: 'column',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        bg: 'white',
                        boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    {children}
                </MotionFlex>
            )}
        </AnimatePresence>,
        node
    );
};
