import DatePickerLibComp from 'react-datepicker';
import { Box, Button, Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import 'react-datepicker/dist/react-datepicker.css';
import { datepickerStyles } from './date-picker.styles';
import { addMonths, getMonth, getYear, subMonths } from 'date-fns';
import { Icon } from '@tmi/component-library';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

interface DatePickerProps {
    value: Date | null;
    onChange: (value: Date) => void;
    placeholder: string;
    sx?: ThemeUIStyleObject;
}

export const DatePicker = ({
    value,
    onChange,
    placeholder,
    sx,
}: DatePickerProps) => {
    return (
        <Box sx={{ ...datepickerStyles, ...sx }}>
            <DatePickerLibComp
                dateFormat="dd-MM-yyyy"
                selected={value}
                onChange={onChange}
                placeholderText={placeholder}
                renderCustomHeader={DatePickerHeader}
                minDate={subMonths(new Date(), 6)}
                maxDate={addMonths(new Date(), 6)}
            />
        </Box>
    );
};

const DatePickerHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 6,
            py: 4,
        }}
    >
        <Button
            variant="icon"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            sx={{ height: '16px', width: '16px' }}
        >
            <Icon iconName="chevronLeft" size={16} />
        </Button>

        <Text variant="labelMd">
            {months[getMonth(date)]} {getYear(date)}
        </Text>

        <Button
            variant="icon"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            sx={{ height: '16px', width: '16px' }}
        >
            <Icon iconName="chevronRight" size={16} />
        </Button>
    </Flex>
);
