import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { rootQueryKey } from '../constants';

import {
    API_BASE_URL,
    API_ROUTE_USERS,
    API_ROUTE_ME,
    API_ROUTE_ROLE,
    API_ROUTE_ROOT,
    API_ROUTE_ONBOARDING,
} from '../constants/api';
import { useToast } from '../hooks';
import { RoleType } from '../types/enums';

interface MeData {
    user: IUser;
    whiteboards: IWhiteboardInstance[];
    organisationTeams: ITeam[];
}

export const getMe = (): Promise<MeData> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ME}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const useGetMe = () => {
    const { isAuthenticated } = useAuth0();

    return useQuery<MeData, Error>(rootQueryKey, getMe, {
        enabled: isAuthenticated,
    });
};

export const getWhiteboardsForUser = (): Promise<IWhiteboardInstance[]> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_USERS}/whiteboards`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updateUser = (
    user: Partial<IUser & { teamId: string }>
): Promise<IUser> => {
    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ME}`, user)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updateUserOnboarding = (): Promise<IUser> => {
    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ONBOARDING}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updateUserRoot = (user: {
    fullName: string;
    occupation?: string;
    profilePicture?: string;
    teamId?: string;
}): Promise<IUser> => {
    return axios
        .put(
            `${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ME}/${API_ROUTE_ROOT}`,
            user
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const setOnboarding = (): Promise<IUser> => {
    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ONBOARDING}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const changeRole = ({
    userId,
    ...rest
}: {
    userId: string;
    teamId: string;
    roleName: RoleType;
}): Promise<IUser> => {
    return axios
        .put(
            `${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ROLE}/${userId}`,
            rest
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getRoles = (): Promise<IRole[]> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ROLE}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const useSetOnboarded = () => {
    const showToast = useToast();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    return useMutation(updateUserOnboarding, {
        onSuccess: res => {
            queryClient.setQueryData<MeData | undefined>(
                rootQueryKey,
                previousCache => {
                    if (!previousCache) return;

                    return {
                        ...previousCache,
                        user: res,
                    };
                }
            );
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.updateUserFail'),
            });
        },
    });
};
