export const WHITEBOARD_ROUTE = 'whiteboard';
export const SESSION_ROUTE = 'session';
export const LOGOUT_ROUTE = 'logout';
export const ONBOARDING_ROUTE = 'onboarding';
export const INVITE_ROUTE = 'invite';
export const PERSONAL_ROUTE = 'personal';
export const ORGANISATION_ROUTE = 'organisation';
export const INVITE_MEMBERS_ROUTE = 'members';
export const WHITEBOARD_KEY = ':whiteboardKey';
export const SESSION_KEY = ':sessionKey';
export const SETTINGS_ROUTE = 'settings';
export const WAITING_ROUTE = 'waiting';
export const ITEM_KEY = ':itemKey';
export const CARD_ROUTE = 'card';
export const PAYMENTS_ROUTE = 'payments';

export const INTRO_ROUTE = 'intro';
export const EDIT_ROUTE = 'edit';
export const VIEW_ROUTE = 'view';
