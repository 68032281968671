import { ThemeUIStyleObject } from 'theme-ui';

export const datepickerStyles: ThemeUIStyleObject = {
    '.react-datepicker': {
        fontFamily: 'default',
        borderRadius: 2,
        border: 0,
        width: '333px',
        height: '333px',
        boxShadow: 'floatingBar',
    },
    '.react-datepicker__triangle': {
        display: 'none',
    },
    '.react-datepicker__month-container': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    '.react-datepicker__month': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        mx: 4,
        mb: 4,
    },
    '.react-datepicker__week,': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '.react-datepicker__day-names': {
        display: 'flex',
        justifyContent: 'space-between',
        mx: 4,
    },
    '.react-datepicker__day-name': {
        height: '38px',
        width: '38px',
        color: '#757575',
        fontWeight: 'medium',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.react-datepicker__day': {
        height: '38px',
        width: '38px',
        color: 'textPrimary',
        fontWeight: 'medium',
        fontSize: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.react-datepicker__day:hover': {
        borderRadius: 5,
    },
    '.react-datepicker__day--outside-month': {
        opacity: 0.3,
        pointerEvents: 'none',
    },
    '.react-datepicker__input-container': {
        fontFamily: 'default',
        fontSize: 2,
        lineHeight: 1,
        width: '76px',
        height: '21px',

        input: {
            width: '100%',
            borderRadius: 2,
            px: 1,
            py: '2px',
            bg: 'bgPrimary',
            color: 'textSecondary',
            border: 'none',
            textAlign: 'center',
            height: '100%',

            '::placeholder': {
                color: 'textSecondary',
            },
        },
    },
    '.react-datepicker__header': {
        bg: 'transparent',
        border: 0,
        pb: 0,
    },
    '.react-datepicker__day--selected': {
        bg: '#D1D5DB',
        color: 'inherit',
        borderRadius: 5,
    },
    // '.react-datepicker__day:focus': {
    //     outline: 'none',
    // },
    '.react-datepicker__day--keyboard-selected': {
        bg: '#E5E9EF',
        color: 'inherit',
        borderRadius: 5,
    },
    '.react-datepicker__day--keyboard-selected:active': {
        outline: 'none',
        border: 'none',
    },
};
