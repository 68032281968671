import React from 'react';
import { IndividialIconProps } from '../Icon';

export const MenuOpen = ({ fillStyle }: IndividialIconProps) => {
    const Fill = (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.6922 4.8008c-.7137 0-1.2923.5372-1.2923 1.2 0 .6627.5786 1.2 1.2923 1.2h15.4154c.7137 0 1.2923-.5373 1.2923-1.2 0-.6628-.5786-1.2-1.2923-1.2H3.6922ZM3.6922 10.8008c-.7137 0-1.2923.5372-1.2923 1.2 0 .6627.5786 1.2 1.2923 1.2h7.0154c.7137 0 1.2923-.5373 1.2923-1.2 0-.6628-.5786-1.2-1.2923-1.2H3.6922ZM3.6922 16.8008c-.7137 0-1.2923.5372-1.2923 1.2 0 .6627.5786 1.2 1.2923 1.2h5.8154c.7137 0 1.2923-.5373 1.2923-1.2 0-.6628-.5786-1.2-1.2923-1.2H3.6922ZM13.2 14.4004c-.6627 0-1.2.5372-1.2 1.2 0 .6627.5373 1.2 1.2 1.2h5.5029l-1.5514 1.5515c-.4687.4686-.4687 1.2284 0 1.697.4686.4686 1.2284.4686 1.697 0l3.6-3.6a1.1998 1.1998 0 0 0 0-1.697l-3.6-3.6c-.4686-.4687-1.2284-.4687-1.697 0-.4687.4686-.4687 1.2284 0 1.697l1.5514 1.5515H13.2Z" />
        </svg>
    );

    return Fill;
};
