import React from 'react';
import {
    Flex,
    Box,
    Label,
    Button,
    ThemeUIStyleObject,
    Spinner,
    Avatar,
} from 'theme-ui';
import { Dropzone } from './dropzone';

interface ProfilePictureProps {
    id: string;
    label: string;
    uploadButtonLabel: string;
    removeButtonLabel: string;
    alt: string;
    sx?: ThemeUIStyleObject;
    currentPicture?: string;
    disabled?: boolean;
    isLoading?: boolean;
    uploadFile: (file: File) => void;
    removeFile: (url: string) => void;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
    id,
    label,
    uploadButtonLabel,
    removeButtonLabel,
    alt,
    sx,
    currentPicture,
    disabled = false,
    isLoading = false,
    uploadFile,
    removeFile,
}) => {
    const handleRemove = () => {
        removeFile(currentPicture!);
    };

    return (
        <Flex sx={{ flexDirection: 'column', position: 'relative', ...sx }}>
            <Label htmlFor={id} sx={{ pb: 4 }}>
                {label}
            </Label>

            <Flex
                sx={{
                    alignItems: ['center', 'center', 'center', 'stretch'],
                    flexDirection: ['row', 'row', 'row', 'column'],
                }}
            >
                <Flex
                    sx={{
                        height: ['80px', '160px', '240px'],
                        width: ['80px', '160px', '240px'],
                        borderRadius: 3,
                        bg: 'primary',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {currentPicture && !isLoading && (
                        <Avatar
                            sx={{
                                borderRadius: 3,
                            }}
                            src={currentPicture}
                            alt={alt}
                        />
                    )}
                    {isLoading && (
                        <Spinner
                            sx={{ color: 'textInverse' }}
                            variant="styles.spinner"
                        />
                    )}
                </Flex>
                <Flex
                    sx={{
                        flexDirection: 'column',
                        flex: 1,
                        ml: [6, 7, 9, 0],
                        mt: [0, 0, 0, 6],
                    }}
                >
                    {!disabled && (
                        <>
                            <Dropzone
                                uploadFile={uploadFile}
                                text={uploadButtonLabel}
                            />

                            <Button
                                sx={{ mt: 1, mx: 'auto' }}
                                onClick={handleRemove}
                                disabled={!currentPicture}
                                variant="tertiary"
                            >
                                {removeButtonLabel}
                            </Button>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
