import { useTransformContext } from '@tmi/react-zoom-pan-pinch';
import { throttle } from 'lodash';
import { useMemo } from 'react';
import { CARD_META } from '../modules/whiteboard/constants/card';

const checkIsInView = (positionX, positionY, scale, item) => {
    const left = Math.abs(positionX) / scale;

    const right = (Math.abs(positionX) + window.innerWidth) / scale;

    const bottom = (Math.abs(positionY) + window.innerHeight) / scale;
    const top = Math.abs(positionY) / scale;

    const { width, height } = CARD_META[item.type];

    if (
        item.position.x + width * item.cardScale > left &&
        item.position.x < right &&
        item.position.y + height * item.cardScale > top &&
        item.position.y < bottom
    ) {
        return true;
    }

    return false;
};

export const useIsInView = (item: IItemInstance) => {
    const zoomState = useTransformContext();

    const memo = useMemo(() => throttle(checkIsInView, 1000), []);

    const {
        state: { scale, positionX, positionY },
        instance: { isPanning },
    } = zoomState;

    const isInView = memo(positionX, positionY, scale, item);

    if (isInView) return true;

    if (isPanning) return false;

    return checkIsInView(positionX, positionY, scale, item);
};
