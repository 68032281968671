import { Flex, Badge, Box } from 'theme-ui';
import { Icon } from '@tmi/component-library/src';
import { options as statusOptions } from '../../status-picker/status-picker';
import { useTranslationPrefix } from '../../../hooks';

interface Props {
    oldDoc?: IItemInstance;
    newDoc?: IItemInstance;
}

export const ItemStatusUpdate = ({ oldDoc, newDoc }: Props) => {
    const { tr } = useTranslationPrefix('whiteboard.cards.generic.status');

    const oldStatus = oldDoc?.status;
    const oldStatusMeta = statusOptions.find(
        ({ value }) => oldStatus === value
    );
    const newStatus = newDoc?.status;
    const newStatusMeta = statusOptions.find(
        ({ value }) => newStatus === value
    );

    if (!newStatusMeta) return null;

    return (
        <Flex sx={{ alignItems: 'center', gap: 2, mt: 2 }}>
            {oldStatusMeta && (
                <>
                    <Badge
                        sx={{
                            bg: 'bgPrimary',
                            color: 'textSecondary',
                            textDecoration: 'line-through',
                        }}
                    >
                        {tr(oldStatusMeta.key)}
                    </Badge>
                    <Box sx={{ color: 'textSecondary' }}>
                        <Icon iconName="arrowRight" size={20} />
                    </Box>
                </>
            )}
            <Badge sx={{ bg: newStatusMeta.color }}>
                {tr(newStatusMeta.key)}
            </Badge>
        </Flex>
    );
};
