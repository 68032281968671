import { Flex, Text } from 'theme-ui';
import { Avatar } from '../avatar';

interface LabelProps {
    profilePicture?: string;
    fullName: string;
    email: string;
    variant?: 'xs' | 'sm';
    strikeThrough?: boolean;
}

export const Label = ({
    profilePicture,
    fullName,
    email,
    variant = 'sm',
    strikeThrough = false,
}: LabelProps) => (
    <Flex sx={{ alignItems: 'center' }}>
        <Avatar
            pictureUrl={profilePicture}
            fullName={fullName}
            email={email}
            sx={{ opacity: strikeThrough ? 0.4 : 1 }}
        />
        <Text
            variant={variant}
            sx={{
                ml: 2,
                color: strikeThrough ? 'textSecondary' : 'textPrimary',
                textDecoration: strikeThrough ? 'line-through' : 'none',
            }}
        >
            {fullName}
        </Text>
    </Flex>
);
