import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useReducer,
    useState,
    useMemo,
} from 'react';
import {
    filterCategories,
    initialState,
} from '../modules/whiteboard/components/filter/filter.constants';

type State = {
    filterState: FilterStateType;
    selectedCards: IItemInstance[];
};

type API = {
    dispatch: Dispatch<FilterActionType>;
    setSelectedCards: Dispatch<SetStateAction<IItemInstance[]>>;
};

const FilterDataContext = createContext<State>({} as State);
const FilterAPIContext = createContext<API>({} as API);

function handleEntry(
    arr: QueryEntry<string | number>[],
    change: QueryEntry<string | number>
) {
    if (arr.some(({ value }) => value === change.value)) {
        if (change.uniqueEmpty) {
            return [];
        } else {
            return arr.filter(({ value }) => value !== change.value);
        }
    } else {
        if (change.uniqueEmpty) {
            return [change];
        } else {
            return [...arr.filter(({ uniqueEmpty }) => !uniqueEmpty), change];
        }
    }
}

function handleUniqueEntry(
    arr: QueryEntry<string | number>[],
    entry: QueryEntry<string | number>
) {
    if (arr.length === 0) {
        return [entry];
    } else {
        if (arr.some(({ value }) => value === entry.value)) {
            return [];
        } else {
            return [entry];
        }
    }
}

function stateUpdater(
    state: FilterStateType,
    action: { type: Category; payload: QueryEntry<string | number> },
    category: Category
) {
    return {
        ...state,
        [category]: {
            ...state[category],
            query: handleEntry(state[category].query, action.payload),
        },
    };
}

function uniqueStateUpdater(
    state: FilterStateType,
    action: { type: Category; payload: QueryEntry<string | number> },
    category: Category
) {
    return {
        ...state,
        [category]: {
            ...state[category],
            query: handleUniqueEntry(state[category].query, action.payload),
        },
    };
}

export function reducer(state: FilterStateType, action: FilterActionType) {
    switch (action.type) {
        case filterCategories.CARD_TYPE:
            return uniqueStateUpdater(
                state,
                action,
                filterCategories.CARD_TYPE
            );
        case filterCategories.ASSIGNEES:
            return stateUpdater(state, action, filterCategories.ASSIGNEES);
        case filterCategories.DUE_DATE:
            return uniqueStateUpdater(state, action, filterCategories.DUE_DATE);
        case filterCategories.TAGS:
            return stateUpdater(state, action, filterCategories.TAGS);
        case filterCategories.STATUS:
            return uniqueStateUpdater(state, action, filterCategories.STATUS);
        case 'RESET':
            return initialState;
        default:
            return state;
    }
}

export function FilterProvider({ children }) {
    const [filterState, dispatch] = useReducer(reducer, initialState);
    const [selectedCards, setSelectedCards] = useState<IItemInstance[]>([]);

    const API = useMemo(() => ({ dispatch, setSelectedCards }), []);

    return (
        <FilterAPIContext.Provider value={API}>
            <FilterDataContext.Provider
                value={{
                    filterState,
                    selectedCards,
                }}
            >
                {children}
            </FilterDataContext.Provider>
        </FilterAPIContext.Provider>
    );
}

export const useFilterData = () => useContext(FilterDataContext);
export const useFilterAPI = () => useContext(FilterAPIContext);
