import { useEffect } from 'react';
import { throttle } from 'lodash';
import { CURSOR_HALF_DIMEMSION } from '../../constants';
import { getCursorColor, getShortName } from '../../helpers';
import { useAppSelector, useCursors } from '../../hooks';
import { getUserForActiveOrg } from '../../redux/reducers';
import { Cursor } from './cursor';

const calAbsolutePosition = (coord: number, scale: number) => coord / scale;

export const CursorHandler: React.FC<{
    scale: any;
    canvasRef: any;
}> = ({ scale, canvasRef }) => {
    const user = useAppSelector(getUserForActiveOrg);

    const { cursors, handleSendCursors } = useCursors();

    useEffect(() => {
        const throttled = throttle(handleCursorUpdate, 25);
        //when scale changes we need to remove the event handler and pass then new scale
        window.removeEventListener('mousemove', throttled);

        function handleCursorUpdate(e) {
            if (!canvasRef.current) return;
            const rect = canvasRef.current.getBoundingClientRect();
            const x = e.clientX - rect.left; //x position within the element.
            const y = e.clientY - rect.top; //y position within the element.

            handleSendCursors({
                x: calAbsolutePosition(x, scale),
                y: calAbsolutePosition(y, scale),
            });
        }

        window.addEventListener('mousemove', throttled);

        return () => {
            throttled.cancel();
            window.removeEventListener('mousemove', throttled);
        };
    }, [canvasRef, scale, handleSendCursors]);

    return (
        <>
            {cursors.map(({ x, y, _id: userId, fullName: name }) => {
                if (userId === user._id) return null;
                const color = getCursorColor(name);
                const shortName = getShortName(name);

                const transform = `translate3d(${x - CURSOR_HALF_DIMEMSION ||
                    0}px, ${y - CURSOR_HALF_DIMEMSION ||
                    0}px, 0) scale(${Math.pow(scale, -1)})`;

                return (
                    <Cursor
                        key={userId}
                        bgColor={color.bg}
                        textColor={color.text}
                        text={shortName}
                        transform={transform}
                    />
                );
            })}
        </>
    );
};
