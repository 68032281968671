import React from 'react';
import { Flex, Spinner } from 'theme-ui';

export const Loader = () => {
    return (
        <Flex
            sx={{
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Spinner variant="styles.spinner" />
        </Flex>
    );
};
