import axios from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import {
    EDIT_ROUTE,
    INTRO_ROUTE,
    sessionParticipantsQueryKey,
    sessionsQueryKey,
    SESSION_ROUTE,
    WHITEBOARD_ROUTE,
} from '../constants';

import {
    API_BASE_URL,
    API_ROUTE_SESSION,
    API_ROUTE_JOIN,
    API_ROUTE_LEAVE,
} from '../constants/api';
import { createQueryKey } from '../helpers';
import { useAppSelector, useToast, useWhiteboardParams } from '../hooks';
import { getUserForActiveOrg } from '../redux/reducers';

const createSession = (input: { whiteboardId: string }): Promise<ISession> => {
    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_SESSION}`, {
            ...input,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const deleteSession = (input: {
    sessionKey: string;
    whiteboardKey: string;
}): Promise<ISession> => {
    const { sessionKey, whiteboardKey } = input;
    return axios
        .delete(
            `${API_BASE_URL}/${API_ROUTE_SESSION}/${sessionKey}/${whiteboardKey}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const updateSession = (
    input: Partial<ISession> & { whiteboardKey: string }
): Promise<ISession> => {
    const { _key, whiteboardKey } = input;
    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_SESSION}/${_key}/${whiteboardKey}`, {
            ...input,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const joinSession = (
    input: Partial<ISessionInteraction> & { whiteboardKey: string }
) => {
    const { _key, whiteboardKey } = input;
    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_SESSION}/${API_ROUTE_JOIN}/${_key}/${whiteboardKey}`,
            {
                ...input,
            }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const leaveSession = (
    input: Partial<ISessionInteraction> & { whiteboardKey: string }
) => {
    const { _key, whiteboardKey } = input;
    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_SESSION}/${API_ROUTE_LEAVE}/${_key}/${whiteboardKey}`,
            {
                ...input,
            }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const getSession = ({
    sessionKey,
}: {
    sessionKey: string;
}): Promise<ISession> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_SESSION}/${sessionKey}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const getSessionParticipants = ({
    sessionKey,
}: {
    sessionKey: string;
}): Promise<{ v: IUser; e: IEdge }[]> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_SESSION}/${sessionKey}/participants`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const getAllSessions = ({
    whiteboardKey,
}: {
    whiteboardKey: string;
}): Promise<ISession[]> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_SESSION}/all/${whiteboardKey}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const useCreateSession = ({
    whiteboardKey,
}: {
    whiteboardKey: string;
}) => {
    const showToast = useToast();
    const { t } = useTranslation();
    const history = useHistory();

    return useMutation(createSession, {
        onSuccess: async res => {
            history.push(
                `/${WHITEBOARD_ROUTE}/${whiteboardKey}/${SESSION_ROUTE}/${res._key}/${EDIT_ROUTE}`
            );
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};

export const useDeleteSession = () => {
    const { whiteboardKey, sessionKey } = useWhiteboardParams();
    const showToast = useToast();
    const { t } = useTranslation();
    const history = useHistory();

    return useMutation(() => deleteSession({ sessionKey, whiteboardKey }), {
        onSuccess: async res => {
            history.push(
                `/${WHITEBOARD_ROUTE}/${whiteboardKey}/${SESSION_ROUTE}`
            );
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};

export const useUpdateSession = () => {
    const { sessionKey, whiteboardKey } = useWhiteboardParams();

    const showToast = useToast();
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const sessionQuery = createQueryKey(sessionsQueryKey, sessionKey);

    return useMutation(
        (input: Partial<ISession>) =>
            updateSession({
                _key: sessionKey,
                _id: `${API_ROUTE_SESSION}/${sessionKey}`,
                whiteboardKey,
                ...input,
            }),
        {
            onMutate: async (newSession: Partial<ISession>) => {
                await queryClient.cancelQueries(sessionQuery);

                // Snapshot the previous value
                const previousCache:
                    | ISession
                    | undefined = queryClient.getQueryData(sessionQuery);

                if (previousCache) {
                    // Optimistically update to the new value
                    queryClient.setQueryData(sessionQuery, {
                        ...previousCache,
                        ...newSession,
                    });
                }
                // Return a context with the previous
                return { previousCache };
            },

            // If the mutation fails, use the context we returned above
            onError: (err, newTodo, context) => {
                if (context?.previousCache) {
                    queryClient.setQueryData(
                        sessionQuery,
                        context.previousCache
                    );
                }
                showToast({
                    type: 'error',
                    message: t('general.toasts.general'),
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(sessionQuery);
            },
        }
    );
};

export const useJoinSession = () => {
    const { sessionKey, whiteboardKey } = useWhiteboardParams();
    const { _id: userId } = useAppSelector(getUserForActiveOrg);
    const history = useHistory();

    const queryClient = useQueryClient();

    const showToast = useToast();

    const handler = useCallback(() => {
        return joinSession({
            sessionId: `${API_ROUTE_SESSION}/${sessionKey}`,
            _key: sessionKey,
            whiteboardKey,
            userId,
        });
    }, [sessionKey, whiteboardKey, userId]);
    const qk = createQueryKey(sessionParticipantsQueryKey, sessionKey);

    return useMutation(handler, {
        onSuccess: res => {
            queryClient.invalidateQueries(qk);
        },
        // If the mutation fails, use the context we returned above
        onError: (err, newTodo, context) => {
            history.push(
                `/${WHITEBOARD_ROUTE}/${whiteboardKey}/${SESSION_ROUTE}/${sessionKey}/${INTRO_ROUTE}`
            );

            showToast({
                type: 'error',
                message: 'Make sure you are logged out of all sessions',
            });
        },
    });
};

export const useLeaveSession = () => {
    const { sessionKey, whiteboardKey } = useWhiteboardParams();
    const { _id: userId } = useAppSelector(getUserForActiveOrg);

    const showToast = useToast();
    const { t } = useTranslation();

    return useMutation(
        () =>
            leaveSession({
                sessionId: `${API_ROUTE_SESSION}/${sessionKey}`,
                _key: sessionKey,
                whiteboardKey,
                userId,
            }),
        {
            onError: () => {
                showToast({
                    type: 'error',
                    message: t('general.toasts.general'),
                });
            },
        }
    );
};

export const useGetSession = () => {
    const { sessionKey } = useWhiteboardParams();
    const get = useCallback(() => getSession({ sessionKey }), [sessionKey]);
    return useQuery(createQueryKey(sessionsQueryKey, sessionKey), get, {
        enabled: !!sessionKey,
    });
};

export const useGetSessionParticipants = () => {
    const { sessionKey } = useWhiteboardParams();
    const get = useCallback(() => getSessionParticipants({ sessionKey }), [
        sessionKey,
    ]);

    return useQuery(
        createQueryKey(sessionParticipantsQueryKey, sessionKey),
        get,
        {
            enabled: !!sessionKey,
        }
    );
};

export const useGetAllSessions = ({
    whiteboardKey,
}: {
    whiteboardKey: string;
}) => {
    const get = useCallback(() => getAllSessions({ whiteboardKey }), [
        whiteboardKey,
    ]);
    return useQuery(createQueryKey(sessionsQueryKey, whiteboardKey), get, {
        enabled: !!whiteboardKey,
    });
};
