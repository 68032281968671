import { ThemeUIStyleObject } from 'theme-ui';

export const badges: Record<string, ThemeUIStyleObject> = {
    primary: {
        variant: 'text.xs',
        borderRadius: 5,
        px: 2,
        pt: '1px',
        pb: '2px',
    },
};
