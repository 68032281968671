import React from 'react';
import { Flex, Text, Image, Box } from '@theme-ui/components';
import { getUserColor } from '../../helpers';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { TooltipLabel } from '../tooltip-label';
import Tippy from '@tippyjs/react';

interface AvatarProps {
    pictureUrl: string | undefined;
    email: string;
    fullName: string;
    size?: number;
    outlineWidth?: number;
    outlineColor?: string;
    sx?: ThemeUIStyleObject;
}

export const Avatar: React.FC<AvatarProps> = ({
    fullName,
    email,
    pictureUrl,
    size = 24,
    sx,
    outlineWidth,
    outlineColor,
}: AvatarProps) => (
    <Tippy
        placement="bottom"
        animation={false}
        render={attrs => (
            <TooltipLabel {...attrs} direction="up">
                {fullName}
            </TooltipLabel>
        )}
    >
        <Box
            sx={{
                flexShrink: 0,
                borderRadius: 5,
                height: size,
                width: size,
                transform: 'translateZ(0)',
                position: 'relative',

                ...(outlineWidth
                    ? {
                          '&::after': {
                              content: '""',
                              position: 'absolute',
                              top: `-${outlineWidth}px`,
                              left: `-${outlineWidth}px`,
                              right: `-${outlineWidth}px`,
                              bottom: `-${outlineWidth}px`,
                              bg: outlineColor,
                              zIndex: -1,
                              borderRadius: 5,
                          },
                      }
                    : null),

                ...sx,
            }}
        >
            {pictureUrl ? (
                <Image src={pictureUrl} variant="avatar" />
            ) : (
                <Flex
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        borderRadius: 5,
                        bg: getUserColor(email),
                    }}
                >
                    <Text variant="xs" sx={{ cursor: 'default' }}>
                        {fullName?.charAt(0).toUpperCase()}
                    </Text>
                </Flex>
            )}
        </Box>
    </Tippy>
);
