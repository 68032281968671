import axios from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getWhiteboardQueryKey, itemQueryKey } from '../constants';

import {
    API_BASE_URL,
    API_ROUTE_ITEM,
    API_ROUTE_INSTANCE,
    API_ROUTE_CONNECTION,
} from '../constants/api';
import { createQueryKey } from '../helpers';
import { useAppSelector, useToast, useWhiteboardParams } from '../hooks';
import { getUserForActiveOrg, selectActiveOrg } from '../redux/reducers';

export const createConnection = (input: {
    startItemId: string;
    endItemId: string;
    whiteboardKey: string;
}): Promise<any> => {
    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_ITEM}/${API_ROUTE_INSTANCE}/${API_ROUTE_CONNECTION}`,
            input
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const getItem = ({
    itemKey,
    organisationKey,
}: {
    itemKey: string;
    organisationKey: string;
}): Promise<IItemInstance> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_ITEM}/${API_ROUTE_INSTANCE}/${itemKey}/${organisationKey}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updateItem = ({
    input,
    whiteboardKey,
    teamKey,
}: {
    input: Partial<IItemInstance>;
    whiteboardKey: string;
    teamKey: string;
}): Promise<IItemInstance> => {
    return axios
        .put(
            `${API_BASE_URL}/${API_ROUTE_ITEM}/${API_ROUTE_INSTANCE}/${whiteboardKey}/${teamKey}`,
            { ...input }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const deleteItem = ({
    key,
}: {
    key: string;
}): Promise<IItemInstance> => {
    return axios
        .delete(`${API_BASE_URL}/${API_ROUTE_ITEM}/instance/${key}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const useGetItemInstance = ({
    itemKey,
    disableInitialFetch,
    initialData,
}: {
    itemKey: string;
    disableInitialFetch?: boolean;
    initialData?: IItemInstance;
}) => {
    const { _key: organisationKey } = useAppSelector(selectActiveOrg);
    const queryKey = createQueryKey(itemQueryKey, itemKey);
    return useQuery(
        queryKey,
        () => getItem({ itemKey: itemKey ?? '', organisationKey }),
        {
            enabled: disableInitialFetch ? false : !!itemKey,
            initialData: initialData ?? null,
        }
    );
};

export const useUpdateItemInstance = ({ _id }: { _id: string }) => {
    const { whiteboardKey } = useWhiteboardParams();
    const showToast = useToast();
    const { t } = useTranslation();

    const { _key: teamKey } = useAppSelector(selectActiveOrg);

    const { _id: userId } = useAppSelector(getUserForActiveOrg);

    const updater = useCallback(
        (data: Partial<IItemInstance>) => {
            return updateItem({
                input: { ...data, _id, lastUpdatedBy: userId },
                whiteboardKey,
                teamKey,
            });
        },
        [whiteboardKey, _id, userId, teamKey]
    );

    return useMutation(updater, {
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.general'),
            });
        },
    });
};

export const useDeleteItem = ({ key }: { key: string }) => {
    const { whiteboardKey } = useWhiteboardParams();
    const showToast = useToast();
    const { t } = useTranslation();

    const queryClient = useQueryClient();
    const removeResponseFromCache = useCallback(
        res => {
            queryClient.setQueryData<IWhiteboard | undefined>(
                [getWhiteboardQueryKey, { key: whiteboardKey }],
                prev => {
                    if (!prev) return;

                    return {
                        ...prev,
                        items:
                            prev.items?.filter(({ _id }) => _id !== res._id) ??
                            [],
                        connections:
                            prev.connections?.filter(
                                ({ _from, _to }) =>
                                    ![_from, _to].includes(res._id)
                            ) ?? [],
                    };
                }
            );
        },
        [queryClient, whiteboardKey]
    );

    const updater = useCallback(() => {
        return deleteItem({
            key,
        });
    }, [key]);

    return useMutation(updater, {
        onSuccess: res => {
            // Update local cache manually, no refetch required
            removeResponseFromCache(res);
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.deleteCardFail'),
            });
        },
    });
};
