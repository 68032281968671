import { Box, Paragraph } from '@theme-ui/components';
import { CursorSvg } from '.';

interface Props {
    bgColor: string;
    textColor: string;
    text: string;
    transform: string;
}

export const Cursor: React.FC<Props> = ({
    bgColor,
    textColor,
    text,
    transform,
}) => (
    <Box
        sx={{
            position: 'relative',
            zIndex: 9999,
            display: 'inline-block',
            transition: '0.3s',
            transform: transform,
        }}
    >
        <CursorSvg color={bgColor} />
        <Box
            sx={{
                position: 'absolute',
                mt: -3,
                ml: 5,
                padding: 2,
                borderRadius: 3,
                bg: bgColor,
                boxShadow: 'cursor',
            }}
        >
            <Paragraph
                sx={{
                    color: textColor,
                    fontSize: 3,
                    whiteSpace: 'nowrap',
                }}
            >
                {text}
            </Paragraph>
        </Box>
    </Box>
);
