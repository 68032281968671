// Missing an icon? Go to https://heroicons.dev/ and add it here

import React from 'react';
import { Box } from 'theme-ui';
import { Adjustments } from './Icons/Adjustments';
import { ArrowDown } from './Icons/ArrowDown';
import { ArrowLeft } from './Icons/ArrowLeft';
import { ArrowRight } from './Icons/ArrowRight';
import { ArrowsExpand } from './Icons/ArrowsExpand';
import { ArrowUp } from './Icons/ArrowUp';
import { Behaviour } from './Kinety/Behaviour';
import { Calendar } from './Icons/Calendar';
import { CheckCircle } from './Icons/CheckCircle';
import { Check } from './Icons/Check';
import { ChevronDown } from './Icons/ChevronDown';
import { ChevronLeft } from './Icons/ChevronLeft';
import { ChevronRight } from './Icons/ChevronRight';
import { ChevronUp } from './Icons/ChevronUp';
import { ClipboardCheck } from './Icons/ClipboardCheck';
import { Clock } from './Icons/Clock';
import { Cog } from './Icons/Cog';
import { ColorSwatch } from './Icons/ColorSwatch';
import { Document } from './Icons/Document';
import { DotsHorizontal } from './Icons/DotsHorizontal';
import { DotsVertical } from './Icons/DotsVertical';
import { Duplicate } from './Icons/Duplicate';
import { Eye } from './Icons/Eye';
import { Exclamation } from './Icons/Exclamation';
import { EyeOff } from './Icons/EyeOff';
import { FolderOpen } from './Icons/FolderOpen';
import { Flag } from './Icons/Flag';
import { Filter } from './Icons/Filter';
import { Globe } from './Icons/Globe';
import { Hamburger } from './Icons/Hamburger';
import { Heart } from './Icons/Heart';
import { Home } from './Icons/Home';
import { LightningBolt } from './Icons/LightningBolt';
import { Link } from './Icons/Link';
import { LockClosed } from './Icons/LockClosed';
import { Login } from './Icons/Login';
import { Logout } from './Icons/Logout';
import { Menu } from './Icons/Menu';
import { MenuClose } from './Icons/MenuClose';
import { MenuOpen } from './Icons/MenuOpen';
import { Minus } from './Icons/Minus';
import { MinusCircle } from './Icons/MinusCircle';
import { PaperAirplane } from './Icons/PaperAirplane';
import { Pencil } from './Icons/Pencil';
import { PencilAlt } from './Icons/PencilAlt';
import { Photograph } from './Icons/Photograph';
import { Plus } from './Icons/Plus';
import { PlusCircle } from './Icons/PlusCircle';
import { Refresh } from './Icons/Refresh';
import { Reply } from './Icons/Reply';
import { Rewind } from './Icons/Rewind';
import { Search } from './Icons/Search';
import { Session } from './Icons/Session';
import { Star } from './Icons/Star';
import { StatusOnline } from './Icons/StatusOnline';
import { Support } from './Icons/Support';
import { SwitchHorizontal } from './Icons/SwitchHorizontal';
import { ThumbUp } from './Icons/ThumbUp';
import { Trash } from './Icons/Trash';
import { UserAdd } from './Icons/UserAdd';
import { ViewGridAdd } from './Icons/ViewGridAdd';
import { ViewList } from './Icons/ViewList';
import { X } from './Icons/X';
import { XCircle } from './Icons/XCircle';
import { ZoomIn } from './Icons/ZoomIn';
import { ZoomOut } from './Icons/ZoomOut';

export type IconName =
    | 'adjustments'
    | 'arrowDown'
    | 'arrowLeft'
    | 'arrowRight'
    | 'arrowsExpand'
    | 'arrowUp'
    | 'behaviour'
    | 'calendar'
    | 'checkCircle'
    | 'check'
    | 'chevronDown'
    | 'chevronLeft'
    | 'chevronRight'
    | 'chevronUp'
    | 'clipboardCheck'
    | 'clock'
    | 'cog'
    | 'colorSwatch'
    | 'document'
    | 'dotsHorizontal'
    | 'dotsVertical'
    | 'duplicate'
    | 'eye'
    | 'exclamation'
    | 'eyeOff'
    | 'folderOpen'
    | 'flag'
    | 'filter'
    | 'globe'
    | 'hamburger'
    | 'heart'
    | 'home'
    | 'lightningBolt'
    | 'link'
    | 'lockClosed'
    | 'login'
    | 'logout'
    | 'menu'
    | 'menuClose'
    | 'menuOpen'
    | 'minus'
    | 'minusCircle'
    | 'paperAirplane'
    | 'pencil'
    | 'pencilAlt'
    | 'photograph'
    | 'plus'
    | 'plusCircle'
    | 'refresh'
    | 'reply'
    | 'rewind'
    | 'search'
    | 'session'
    | 'star'
    | 'statusOnline'
    | 'support'
    | 'switchHorizontal'
    | 'thumbUp'
    | 'trash'
    | 'userAdd'
    | 'viewGridAdd'
    | 'viewList'
    | 'x'
    | 'xCircle'
    | 'zoomIn'
    | 'zoomOut';
type FillType = 'outline' | 'solid';

export interface IconProps {
    iconName: IconName;
    fillStyle?: FillType;
    size?: number;
}

export interface IndividialIconProps {
    fillStyle: FillType;
}

export const Icon: React.FC<IconProps> = ({
    iconName,
    fillStyle = 'outline',
    size = 24,
}) => {
    const icons = {
        adjustments: <Adjustments fillStyle={fillStyle} />,
        arrowDown: <ArrowDown fillStyle={fillStyle} />,
        arrowLeft: <ArrowLeft fillStyle={fillStyle} />,
        arrowRight: <ArrowRight fillStyle={fillStyle} />,
        arrowsExpand: <ArrowsExpand fillStyle={fillStyle} />,
        arrowUp: <ArrowUp fillStyle={fillStyle} />,
        behaviour: <Behaviour fillStyle={fillStyle} />,
        calendar: <Calendar fillStyle={fillStyle} />,
        checkCircle: <CheckCircle fillStyle={fillStyle} />,
        check: <Check fillStyle={fillStyle} />,
        chevronDown: <ChevronDown fillStyle={fillStyle} />,
        chevronLeft: <ChevronLeft fillStyle={fillStyle} />,
        chevronRight: <ChevronRight fillStyle={fillStyle} />,
        chevronUp: <ChevronUp fillStyle={fillStyle} />,
        clipboardCheck: <ClipboardCheck fillStyle={fillStyle} />,
        clock: <Clock fillStyle={fillStyle} />,
        cog: <Cog fillStyle={fillStyle} />,
        colorSwatch: <ColorSwatch fillStyle={fillStyle} />,
        document: <Document fillStyle={fillStyle} />,
        dotsHorizontal: <DotsHorizontal fillStyle={fillStyle} />,
        dotsVertical: <DotsVertical fillStyle={fillStyle} />,
        duplicate: <Duplicate fillStyle={fillStyle} />,
        eye: <Eye fillStyle={fillStyle} />,
        exclamation: <Exclamation fillStyle={fillStyle} />,
        eyeOff: <EyeOff fillStyle={fillStyle} />,
        folderOpen: <FolderOpen fillStyle={fillStyle} />,
        flag: <Flag fillStyle={fillStyle} />,
        filter: <Filter fillStyle={fillStyle} />,
        globe: <Globe fillStyle={fillStyle} />,
        hamburger: <Hamburger fillStyle={fillStyle} />,
        heart: <Heart fillStyle={fillStyle} />,
        home: <Home fillStyle={fillStyle} />,
        lightningBolt: <LightningBolt fillStyle={fillStyle} />,
        link: <Link fillStyle={fillStyle} />,
        lockClosed: <LockClosed fillStyle={fillStyle} />,
        login: <Login fillStyle={fillStyle} />,
        logout: <Logout fillStyle={fillStyle} />,
        menu: <Menu fillStyle={fillStyle} />,
        menuClose: <MenuClose fillStyle={fillStyle} />,
        menuOpen: <MenuOpen fillStyle={fillStyle} />,
        minus: <Minus fillStyle={fillStyle} />,
        minusCircle: <MinusCircle fillStyle={fillStyle} />,
        paperAirplane: <PaperAirplane fillStyle={fillStyle} />,
        pencil: <Pencil fillStyle={fillStyle} />,
        pencilAlt: <PencilAlt fillStyle={fillStyle} />,
        photograph: <Photograph fillStyle={fillStyle} />,
        plus: <Plus fillStyle={fillStyle} />,
        plusCircle: <PlusCircle fillStyle={fillStyle} />,
        refresh: <Refresh fillStyle={fillStyle} />,
        reply: <Reply fillStyle={fillStyle} />,
        rewind: <Rewind fillStyle={fillStyle} />,
        search: <Search fillStyle={fillStyle} />,
        session: <Session fillStyle={fillStyle} />,
        star: <Star fillStyle={fillStyle} />,
        statusOnline: <StatusOnline fillStyle={fillStyle} />,
        support: <Support fillStyle={fillStyle} />,
        switchHorizontal: <SwitchHorizontal fillStyle={fillStyle} />,
        thumbUp: <ThumbUp fillStyle={fillStyle} />,
        trash: <Trash fillStyle={fillStyle} />,
        userAdd: <UserAdd fillStyle={fillStyle} />,
        viewGridAdd: <ViewGridAdd fillStyle={fillStyle} />,
        viewList: <ViewList fillStyle={fillStyle} />,
        x: <X fillStyle={fillStyle} />,
        xCircle: <XCircle fillStyle={fillStyle} />,
        zoomIn: <ZoomIn fillStyle={fillStyle} />,
        zoomOut: <ZoomOut fillStyle={fillStyle} />,
    };

    return (
        <Box
            sx={{
                height: `${size}px`,
                width: `${size}px`,
                flexShrink: 0,

                svg: {
                    fill: `${fillStyle === 'solid' ? 'currentColor' : 'none'}`,
                    stroke: `${
                        fillStyle === 'outline' ? 'currentColor' : 'none'
                    }`,
                },
            }}
        >
            {icons[iconName]}
        </Box>
    );
};
