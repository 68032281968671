/** @jsxImportSource theme-ui */

import { createPortal } from 'react-dom';
import { Box, Flex, Paragraph, Button } from 'theme-ui';
import { addElementToDom } from '../../../../helpers';
import { useRef, useState } from 'react';
import { useOutsideMouseDown } from '../../../../hooks/';
import { Icon, IconName } from '@tmi/component-library/src';
import {
    CONTEXT_MENU_ID,
    CONTEXT_MENU_PLACEMENT_ID,
} from '../../../../constants';
import { usePopper } from 'react-popper';

interface ContextMenuProps {
    position: ICoordinates;
    onOutsideClick: () => void;
    children: React.ReactElement | React.ReactElement[];
}

const ContextMenu = ({
    position,
    onOutsideClick,
    children,
}: ContextMenuProps) => {
    const node =
        document.getElementById(CONTEXT_MENU_PLACEMENT_ID) ??
        addElementToDom(CONTEXT_MENU_PLACEMENT_ID);

    const [parentRef, setParentRef] = useState<HTMLDivElement | null>(null);
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const { styles, attributes } = usePopper(parentRef, popperRef, {
        placement: 'right-start',
    });

    useOutsideMouseDown(ref, onOutsideClick);

    return createPortal(
        <Box
            id={CONTEXT_MENU_ID}
            ref={setParentRef}
            sx={{
                position: 'fixed',
                left: position.x,
                top: position.y,
            }}
        >
            <Box
                ref={setPopperRef}
                {...attributes.popper}
                sx={{ ...styles.popper }}
            >
                <Flex
                    ref={ref}
                    sx={{
                        bg: 'white',
                        borderRadius: 2,
                        minWidth: '200px',
                        p: 1,
                        boxShadow: 'floatingBar',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </Flex>
            </Box>
        </Box>,
        node
    );
};

interface ButtonProps {
    onClick: () => void;
    iconName: IconName;
    text: string;
    isSelected?: boolean;
    kbd?: string;
}

const ContextMenuButton = ({
    onClick,
    iconName,
    text,
    kbd,
    isSelected = false,
}: ButtonProps) => (
    <Button variant="contextMenu" onClick={onClick}>
        <Icon size={16} iconName={iconName} fillStyle="solid" />
        <Paragraph variant="sm" sx={{ ml: 2, flex: 1 }}>
            {text}
        </Paragraph>
        {kbd && <Kbd>{kbd}</Kbd>}
        {isSelected && (
            <Box sx={{ color: 'textTertiary' }}>
                <Icon size={16} iconName="check" fillStyle="solid" />
            </Box>
        )}
    </Button>
);

interface GroupProps {
    children: React.ReactElement | React.ReactElement[];
    iconName: IconName;
    text: string;
    kbd?: string;
}

const ContextMenuGroup = ({ children, iconName, text, kbd }: GroupProps) => {
    const [parentRef, setParentRef] = useState<HTMLDivElement | null>(null);
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

    const { styles, attributes } = usePopper(parentRef, popperRef, {
        placement: 'right-start',
    });

    return (
        <Box
            sx={{
                position: 'relative',
                '&:hover': { bg: 'actionSecondary' },
                '#buttonGroupWrapper': {
                    visibility: 'hidden',
                },
                '#buttonGroupTrigger:hover > #buttonGroupWrapper': {
                    visibility: 'visible',
                },
            }}
        >
            <Flex
                ref={setParentRef}
                id="buttonGroupTrigger"
                variant="buttons.contextMenu"
            >
                <Icon size={16} iconName={iconName} fillStyle="solid" />
                <Paragraph variant="sm" sx={{ mx: 2, flex: 1 }}>
                    {text}
                </Paragraph>
                {kbd && <Kbd>{kbd}</Kbd>}
                <Icon size={16} iconName="chevronRight" fillStyle="solid" />

                <Flex
                    id="buttonGroupWrapper"
                    ref={setPopperRef}
                    {...attributes.popper}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '100%',
                        flexDiretion: 'column',
                        minWidth: '160px',
                        alignItems: 'stretch',
                        bg: 'white',
                        borderRadius: 2,
                        p: 1,
                        boxShadow: 'floatingBar',
                        flexDirection: 'column',
                        ...styles.popper,
                    }}
                >
                    {children}
                </Flex>
            </Flex>
        </Box>
    );
};

ContextMenu.Button = ContextMenuButton;
ContextMenu.Group = ContextMenuGroup;

export { ContextMenu };

interface KbdProps {
    children: string;
}

const Kbd = ({ children }: KbdProps) => (
    <kbd
        sx={{
            fontSize: 2,
            fontWeight: 'regular',
            textAlign: 'left',
            fontFamily: 'default',
            color: 'textSecondary',
            lineHeight: 0,
            bg: '#e9ecef',
            borderRadius: 2,
            px: 1,
            py: '2px',
        }}
    >
        {children}
    </kbd>
);
