import { head } from 'lodash';

export const getProp = (
    input: string,
    prop: 'userId' | 'whiteboardKey' | 'key' | 'scope' | 'data' | 'action'
) => {
    const reg = '(?<=' + prop + '=)[^|]*';
    const regex = new RegExp(reg, 'gm');

    return head(input.match(regex));
};
