import { format } from 'date-fns';
import { Flex, Box, Text, Button } from 'theme-ui';
import { Avatar } from '..';
import { NotificationIndicator } from '../notification-indicator';
import { useTranslationPrefix } from '../../hooks/use-translation-prefix';
import { ActivityTypes } from '@tmi/shared-types';
import { getAssigneeChanges } from '../../helpers/get-assignee-changes';

interface BaseActivityCompProps {
    children: React.ReactElement;
    handleMarkAsRead: () => void;
    notification: IActivityData;
    zoomToElement: () => void;
    label: React.ReactElement;
}

export const BaseActivityComp = ({
    children,
    handleMarkAsRead,
    notification,
    zoomToElement,
    label,
}: BaseActivityCompProps) => {
    const { activityMeta, meta, user, oldDoc, newDoc } = notification;
    const { tr } = useTranslationPrefix('sidebar.activity.notifications');
    let labelText;

    if (activityMeta.type === ActivityTypes.ITEM_UPDATE_ASSIGNEES) {
        const { removedAssignees, addedAssignees } = getAssigneeChanges(
            oldDoc as IItemInstance,
            newDoc as IItemInstance
        );
        if (removedAssignees.length > 0 && addedAssignees.length > 0) {
            labelText = `${activityMeta.type}__changed`;
        } else if (removedAssignees.length > 0) {
            labelText = `${activityMeta.type}__removed`;
        } else {
            labelText = `${activityMeta.type}__added`;
        }
    } else {
        labelText = activityMeta.type;
    }

    return (
        <Flex
            sx={{
                position: 'relative',
                borderBottom: 1,
                borderColor: 'bgPrimary',
                alignItems: 'center',
            }}
        >
            {!activityMeta.hasRead && (
                <NotificationIndicator
                    onClick={handleMarkAsRead}
                    sx={{ position: 'absolute', top: 4, right: 4 }}
                />
            )}
            <Box
                sx={{
                    px: 6,
                    py: 3,
                    flex: 1,
                }}
            >
                <Flex>
                    <Avatar
                        pictureUrl={user.profilePicture}
                        email={user.email}
                        fullName={user.fullName}
                    />
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            ml: 2,
                        }}
                    >
                        <Flex sx={{ alignItems: 'center' }}>
                            <Text
                                variant="labelXs"
                                sx={{ color: 'textSecondary' }}
                            >
                                {tr(labelText, { name: user.fullName })}
                            </Text>
                            <Text
                                variant="labelXs"
                                sx={{
                                    ml: 1,
                                    color: 'textSecondary',
                                    fontWeight: 'regular',
                                }}
                            >
                                •{' '}
                                {format(
                                    new Date(meta.createdAt),
                                    'dd MMMM HH:mm'
                                )}
                            </Text>
                        </Flex>
                        <Button
                            variant="subtle"
                            onClick={zoomToElement}
                            sx={{
                                mt: 1,
                                display: 'flex',
                                alignSelf: 'flex-start',
                                color: 'textSecondary',
                            }}
                        >
                            {label}
                        </Button>
                    </Flex>
                </Flex>

                {children}
            </Box>
        </Flex>
    );
};
