import React from 'react';
import { Icon } from '@tmi/component-library';
import { getUserColor } from '../helpers';
import { Grid, Flex, Box, Text, IconButton } from 'theme-ui';

interface EmailListProps {
    emails: string[];
    handleRemoveClick?: (email: string) => void;
}

interface LineProps {
    emailAddress: string;
    color: string;
    handleRemoveClick?: (emailAddress: string) => void;
}

const Line = ({ emailAddress, color, handleRemoveClick }: LineProps) => {
    return (
        <Flex sx={{ alignItems: 'center' }}>
            <Flex
                sx={{
                    bg: color,
                    width: '40px',
                    height: '40px',
                    borderRadius: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexShrink: 0,
                }}
            >
                <Text variant="xl" sx={{ fontWeight: 1 }}>
                    {emailAddress.charAt(0).toUpperCase()}
                </Text>
            </Flex>
            <Box
                sx={{
                    flex: 1,
                    px: 6,
                    minWidth: 0,
                }}
            >
                <Text
                    as="p"
                    variant="sm"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {emailAddress}
                </Text>
            </Box>
            {handleRemoveClick && (
                <IconButton onClick={() => handleRemoveClick(emailAddress)}>
                    <Icon iconName="x" />
                </IconButton>
            )}
        </Flex>
    );
};

export const EmailList = ({ emails, handleRemoveClick }: EmailListProps) => (
    <Grid
        columns={2}
        sx={{
            gridColumnGap: 12,
            gridRowGap: 4,
        }}
    >
        {emails.map(emailAddress => (
            <Line
                key={emailAddress}
                emailAddress={emailAddress}
                color={getUserColor(emailAddress)}
                handleRemoveClick={handleRemoveClick}
            />
        ))}
    </Grid>
);
