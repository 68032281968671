import React from 'react';
import { Box, Heading } from 'theme-ui';
import { Modal } from './modal';
import { MarkdownWrapper } from './markdown';

interface ConfirmationModalProps {
    heading: string;
    body?: string;
    children?: React.ReactElement;
    isOpen: boolean;
    onClose: () => void;
    id?: string;
}

export const ConfirmationModal = ({
    heading,
    body,
    children,
    isOpen,
    onClose,
    id,
}: ConfirmationModalProps) => {
    return (
        <Modal id={id ?? ''} isOpen={isOpen} onClose={onClose}>
            <>
                <Box sx={{ position: 'relative', zIndex: 2 }}>
                    <Heading>{heading}</Heading>
                </Box>

                {!!body && (
                    <Box mt={8}>
                        <MarkdownWrapper source={body} />
                    </Box>
                )}

                <Box sx={{ mt: 10 }}>{children}</Box>
            </>
        </Modal>
    );
};
