export const rootQueryKey = 'root';
export const getTeamForWhiteboardQueryKey = 'team';
export const getTeamsQueryKey = 'teams';
export const getMembersQueryKey = 'members';
export const getWhiteboardQueryKey = 'whiteboard';
export const getComponentsQueryKey = 'components';
export const tagsQueryKey = 'tags';
export const getWhiteboardsForUserQueryKey = 'whiteboard/user';
export const getTeamMembersQueryKey = 'team/members';
export const commentsQueryKey = 'comments';
export const activityQueryKey = 'activity';
export const sessionsQueryKey = 'sessions';
export const sessionParticipantsQueryKey = 'sessionParticipants';
export const itemQueryKey = 'item';
export const sidebarQueryKey = 'sidebar';
export const pendingInvitesQueryKey = 'pendingInvites';
export const inviteExistsQueryKey = 'inviteExists';
export const paymentsQueryKey = 'payments';

// Mutation keys
export const uploadPersonalAvatarKey = 'upload/avatar/personal';
export const uploadOrganisationAvatarKey = 'upload/avatar/organisation';
