import useWebSocket from 'react-use-websocket';
import { getProp } from '@tmi/helpers/src';
import { useAppSelector, useWhiteboardParams } from '..';
import { getUserForActiveOrg } from '../../redux/reducers';

export const useHandleUpdateWhiteboard = ({
    scope,
    key,
}: {
    scope: string;
    key: string;
}) => {
    // listen to the whiteboard websocket and filter messages on notification scope required
    const { whiteboardKey } = useWhiteboardParams();
    const user = useAppSelector(getUserForActiveOrg);

    const socketUrl = `${process.env.REACT_APP_API_SOCKET_URL}/whiteboard?whiteboardId=${whiteboardKey}&userId=${user._id}`;

    return useWebSocket(socketUrl, {
        share: true,
        filter: (message: MessageEvent<string>) => {
            const messageScope = getProp(message.data, 'scope');
            const notificationKey = getProp(message.data, 'key');

            if (key) {
                return notificationKey === key;
            }

            return messageScope === scope;
        },
        shouldReconnect: () => true,
    });
};
