import { useAuth0 } from '@auth0/auth0-react';

export const Logout: React.FC = () => {
    const { logout } = useAuth0();
    logout({
        returnTo: window.location.origin,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
    return <div></div>;
};
