import { Icon } from '@tmi/component-library/src';
import { createPortal } from 'react-dom';
import { Flex, Box, Text, ThemeUIStyleObject } from 'theme-ui';
import { TOAST_ID } from '../../constants';
import { addElementToDom } from '../../helpers';

interface ToastDisplayProps {
    handleClick?: () => void;
    type?: 'success' | 'error';
    message?: string;
    sx?: ThemeUIStyleObject;
}

export const ToastDisplay = ({
    handleClick,
    type,
    message,
    sx,
}: ToastDisplayProps) => {
    const node = document.getElementById(TOAST_ID) ?? addElementToDom(TOAST_ID);

    return createPortal(
        <Flex
            onClick={handleClick}
            sx={{
                zIndex: 1000,
                position: 'fixed',
                bg: 'actionTertiary',
                px: 4,
                py: 2,
                borderRadius: 3,
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx,
            }}
        >
            {type ? (
                <Box
                    sx={{
                        color: 'white',
                        mr: 3,
                    }}
                >
                    <Icon
                        iconName={
                            type === 'success' ? 'checkCircle' : 'exclamation'
                        }
                    />
                </Box>
            ) : null}

            <Text sx={{ color: 'white', textAlign: 'center' }}>{message}</Text>
        </Flex>,
        node
    );
};
