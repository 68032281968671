const colors = {
    primary: '#6E4AE6',
    accent: '#FFEF60',
    alarming: '#F35239',

    bgPrimary: '#E4E6E9',
    bgSecondary: '#FFFFFF',
    bgInverse: '#464059',

    textPrimary: '#464059',
    textSecondary: '#9CA3AF',
    textTertiary: '#6E4AE6',
    textInverse: '#FFFFFF',

    actionPrimarySubtle: '#BAA6FF',
    actionPrimary: '#6E4AE6',
    actionPrimaryHighlight: '#6242CC',
    actionPrimaryIntense: '#493199',

    actionSecondary: '#E4E6E9',
    actionSecondaryHighlight: '#EBEDF2',
    actionSecondaryIntense: '#DBDEE5',

    actionTertiary: '#8B8799',
    actionTertiaryHighlight: '#605B73',
    actionTertiaryIntense: '#6E4AE6',
};

const space = new Array(32).fill(null).map((_, i) => `${i * 4}px`);

const fonts = { default: 'GTAmerica, sans-serif' };

const fontWeights = { regular: 400, medium: 500, bold: 700, black: 900 };

const fontSizes = [
    '10px', //  0
    '11px', //  1
    '12px', //  2
    '14px', //  3
    '16px', //  4
    '18px', //  5
    '21px', //  6
    '24px', //  7
    '28px', //  8
    '32px', //  9
    '36px', // 10
    '40px', // 11
    '48px', // 12
    '60px', // 13
    '72px', // 14
];

const lineHeights = ['135%', '150%', '170%', '175%'];

const radii = [0, '2px', '4px', '8px', '16px', '99999px'];

const shadows = {
    floatingBar: `0 8px 28px -6px rgba(24, 39, 75, 0.14), 0 18px 88px -4px rgba(24, 39, 75, 0.14)`,
    cardShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.05)`,
    tooltipShadow: `0 16px 48px 0 rgba(0,0,0,0.22)`,
    pin: `0 2px 4px 0 rgba(0, 0, 0, 0.05)`,
    cursor: `8px 8px 16px -2px rgba(24, 39, 75, 0.08)`,
    iconButton: `0 1px 8px 1px rgba(0, 0, 0, 0.3)`,
};

const borders = ['none', '1px solid'];

const breakpoints = [
    '480px', //   0 -> xs
    '576px', //   1 -> sm
    '768px', //   2 -> md
    '1024px', //  3 -> lg
    '1440px', //  4 -> xl
];

export {
    colors,
    space,
    fonts,
    fontWeights,
    fontSizes,
    lineHeights,
    radii,
    shadows,
    borders,
    breakpoints,
};
