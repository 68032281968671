import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useWindowSize } from 'rooks';
import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import {
    LOGOUT_ROUTE,
    ONBOARDING_ROUTE,
    INVITE_ROUTE,
    WHITEBOARD_ROUTE,
    SETTINGS_ROUTE,
    PAYMENTS_ROUTE,
} from '../constants';
import { ErrorPage, InvalidInviteError, Loader } from '../components';

import ProtectedRoute, {
    ProtectedRouteWithRender,
} from './components/protected-route';
import security from '../helpers/security';
import { Logout } from './auth';
import { MobileView } from './mobile';
import { useHandleRoot } from '../hooks';
import { useGetDoesInviteExist } from '../api/public';

export const Onboarding = lazy(() => import('../modules/onboarding'));
export const Invite = lazy(() => import('../modules/invite'));
export const Whiteboard = lazy(() => import('../modules/whiteboard'));
export const Settings = lazy(() => import('../modules/settings'));
export const Payments = lazy(() => import('../modules/payments'));

const AuthenticationWrapper = ({ children }) => {
    const { isLoading, getAccessTokenSilently } = useAuth0();
    // hack to expose the function outside the provider/context tree

    security.setAccessTokenSilently(getAccessTokenSilently);
    const loading = useHandleRoot();
    if (isLoading || loading) return <Loader />;

    return children;
};

export const AppRouter: React.FC = () => {
    const { innerWidth } = useWindowSize();
    const isMobileWidth = innerWidth && innerWidth < 1024;
    const inviteId = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })['inviteId'] as string;

    const { data, isLoading } = useGetDoesInviteExist(inviteId ?? '');

    if (isLoading) {
        return <Loader />;
    }

    if (data === false) {
        return <InvalidInviteError />;
    }

    if (isMobileWidth && process.env.NODE_ENV !== 'development')
        return <MobileView />;

    return (
        <AuthenticationWrapper>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route
                        path={`/${LOGOUT_ROUTE}`}
                        render={() => <Logout />}
                    />
                    <ProtectedRoute
                        path={`/${ONBOARDING_ROUTE}`}
                        component={() => <Onboarding />}
                    />

                    <ProtectedRoute
                        path={`/${INVITE_ROUTE}`}
                        component={Invite}
                    />

                    <ProtectedRoute
                        path={`/${SETTINGS_ROUTE}/:whiteboardKey`}
                        component={() => <Settings />}
                    />
                    <ProtectedRouteWithRender
                        path={`/${WHITEBOARD_ROUTE}`}
                        component={Whiteboard}
                    />

                    <ProtectedRoute
                        path={`/${PAYMENTS_ROUTE}`}
                        component={() => <Payments />}
                    />

                    <ProtectedRoute
                        path={`/`}
                        exact
                        component={() => <Whiteboard />}
                    />

                    <Route
                        path="/*"
                        render={() => (
                            <ErrorPage
                                customError={{
                                    status: 404,
                                }}
                            />
                        )}
                    />
                </Switch>
            </Suspense>
        </AuthenticationWrapper>
    );
};
