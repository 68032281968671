import { RefObject, useContext } from 'react';
import { pick } from 'lodash';
import { Box } from 'theme-ui';
import { useMouse } from 'rooks';
import { Goal, Action, Behaviour, Value, Result } from './card-types';
import { WhiteboardContext } from '../../containers/whiteboard.container';
import { CARD_META } from '../../constants/card';
import { getAdjustedCoordinates } from '../../../../helpers';
import { CardMode } from '../../../../types/enums';

interface GhostCardProps {
    canvasRef: RefObject<HTMLDivElement>;
    scale: number;
}

export const GhostCard = ({ canvasRef, scale }: GhostCardProps) => {
    const { pageX, pageY } = useMouse();
    const { pendingCard } = useContext(WhiteboardContext);

    if (!pendingCard) return null;

    const baseProps = {
        dispatchHandler: () => () => {},
        blurHandler: () => () => {},
        cardMode: CardMode.VIEW,
        cardScale: 1,
        isLocked: false,
        isGhost: true,
    };

    const emptyData = {
        title: '',
        dueDate: '',
        tags: [],
        assignees: [],
        status: '',
    };

    const cardTypes = {
        goal: (
            <Goal
                {...baseProps}
                data={pick(emptyData, [
                    'title',
                    'assignees',
                    'tags',
                    'dueDate',
                    'status',
                ])}
            />
        ),
        action: (
            <Action
                {...baseProps}
                data={pick(emptyData, [
                    'title',
                    'assignees',
                    'tags',
                    'dueDate',
                    'status',
                ])}
            />
        ),
        behaviour: (
            <Behaviour
                {...baseProps}
                data={pick(emptyData, ['title', 'tags'])}
            />
        ),
        value: (
            <Value {...baseProps} data={pick(emptyData, ['title', 'tags'])} />
        ),
        result: (
            <Result
                {...baseProps}
                data={pick(emptyData, [
                    'title',
                    'assignees',
                    'tags',
                    'dueDate',
                    'status',
                ])}
            />
        ),
    };

    const { x, y } = getAdjustedCoordinates(pageX!, pageY!, scale, canvasRef);

    const left = x - CARD_META[pendingCard].width / 2;
    const top = y - CARD_META[pendingCard].height / 2;

    return (
        <Box
            sx={{
                position: 'fixed',
                left,
                top,
                opacity: 0.5,
                cursor: 'grabbing',
            }}
        >
            {cardTypes[pendingCard]}
        </Box>
    );
};
