import React from 'react';
import { DatePicker } from '../date-picker';
import { Flex, Box, Text } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { format } from 'date-fns';

interface DueDatePickerProps {
    sx?: ThemeUIStyleObject;
    value: string | null;
    onChange: (value: Date) => void;
    isEditActive: boolean;
}

export const DueDatePicker = ({
    sx,
    value,
    onChange,
    isEditActive,
}: DueDatePickerProps) => {
    if (!isEditActive && !value)
        return <Box sx={{ height: '21px', visibility: 'hidden', ...sx }} />;

    const dateValue = value ? new Date(value) : null;

    if (!isEditActive && dateValue) {
        const formatedDate = format(dateValue, 'dd-MM-yyyy');
        return (
            <Flex
                sx={{ height: '21px', py: '2px', alignItems: 'center', ...sx }}
            >
                <Text
                    variant="xs"
                    sx={{ color: 'textSecondary', lineHeight: '115%' }}
                >
                    {formatedDate}
                </Text>
            </Flex>
        );
    }

    return (
        <DatePicker
            sx={sx}
            value={dateValue}
            onChange={onChange}
            placeholder="dd-mm-yyyy"
        />
    );
};
