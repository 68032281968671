export const CardTypes: Record<string, CardType> = {
    GOAL: 'goal',
    ACTION: 'action',
    RESULT: 'result',
    BEHAVIOUR: 'behaviour',
    VALUE: 'value',
};

export const CARD_META: Record<CardType, CardMeta> = {
    goal: {
        width: 210,
        height: 210,
        cardRadius: '99999px',
        outlineRadius: '99999px',
    },
    action: {
        width: 249,
        height: 142,
        cardRadius: '8px',
        outlineRadius: '16px',
    },
    behaviour: {
        width: 230,
        height: 135,
        cardRadius: '99999px',
        outlineRadius: '99999px',
    },
    value: {
        width: 209,
        height: 135,
        cardRadius: '8px',
        outlineRadius: '16px',
    },
    result: {
        width: 209,
        height: 209,
        cardRadius: '40px',
        outlineRadius: '48px',
    },
};

export const SCALE_BOUNDARIES = { min: 0.25, max: 4 };
export const GOAL_TITLE_MAX_LENGTH = 135;
export const ACTION_TITLE_MAX_LENGTH = 90;
