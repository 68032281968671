import React, { useCallback, useState } from 'react';
import {
    Flex,
    Box,
    Paragraph,
    Button,
    Text,
    Heading,
} from '@theme-ui/components';
import { CommentContainer } from '../card/comment.container';
import { useTranslationPrefix } from '../../../../hooks';
import { DueDatePicker } from '../../../../components';
import { useUpdateItemInstance } from '../../../../api/item';
import { alphabeticalTagSort } from '../../../../helpers/alphabetical-sort';
import { TagList } from '../../../../components/tag-picker/tag-list';
import { AvatarList } from '../../../../components/avatar-list';
import { itemQueryKey, SIDEBAR_CARD_CONTENT_ID } from '../../../../constants';
import { StatusPicker } from '../../../../components/status-picker';
import { CardMode } from '../../../../types/enums';
import { differenceInMinutes } from 'date-fns';
import { useQueryClient } from 'react-query';
import { createQueryKey } from '../../../../helpers';

// TODO: Improve this (hopefully using some clever TS'ing)
const TITLE = 'title';
const DUE_DATE = 'dueDate';
const ASSIGNEES = 'assignees';
const TAGS = 'tags';
const STATUS = 'status';

const allowedFields = {
    goal: [TITLE, DUE_DATE, ASSIGNEES, TAGS, STATUS],
    action: [TITLE, DUE_DATE, ASSIGNEES, TAGS, STATUS],
    result: [TITLE, DUE_DATE, ASSIGNEES, TAGS, STATUS],
    behaviour: [TITLE, TAGS],
    value: [TITLE, TAGS],
};

interface Props {
    itemKey: string;
}
const SHOW_AFTER_MINS = 5;

export const SidebarCardContent = ({ itemKey }: Props) => {
    const { mutate } = useUpdateItemInstance({
        _id: `item_instances/${itemKey}`,
    });
    const cardQueryKey = createQueryKey(itemQueryKey, itemKey);

    const queryClient = useQueryClient();

    const data = queryClient.getQueryData(cardQueryKey) as IItemInstance;

    const [showDetails, setShowDetails] = useState(false);
    const { tr } = useTranslationPrefix(`whiteboard.cards.${data?.type ?? ''}`);
    const { tr: genericTr } = useTranslationPrefix(`whiteboard.cards.generic`);

    const unlockCardHandler = useCallback(() => {
        mutate(
            { cardMode: CardMode.VIEW },
            {
                onSettled: () => {
                    queryClient.invalidateQueries(cardQueryKey);
                },
            }
        );
    }, [cardQueryKey, mutate, queryClient]);

    if (!data) return null;

    const { type, title, dueDate, assignees, tags, status } = data;

    const allowedFieldsForType = allowedFields[type];

    const showUnlock =
        differenceInMinutes(new Date(), new Date(data.updatedAt)) >
            SHOW_AFTER_MINS && data.cardMode === CardMode.LOCKED;

    return (
        <Flex
            id={SIDEBAR_CARD_CONTENT_ID}
            sx={{
                flexDirection: 'column',
                height: '100%',
                width: '100%',
            }}
        >
            <Flex
                sx={{
                    flexDirection: 'column',
                    p: 6,
                }}
            >
                {allowedFieldsForType.includes(TITLE) ? (
                    <>
                        {title ? (
                            <Heading variant="hsm">{title}</Heading>
                        ) : (
                            <Heading
                                variant="hsm"
                                sx={{
                                    opacity: 0.3,
                                }}
                            >
                                {tr('title.placeholder')}
                            </Heading>
                        )}
                    </>
                ) : null}

                {showUnlock && (
                    <Box mt={4}>
                        <Button onClick={unlockCardHandler}>
                            Unlock this card
                        </Button>
                    </Box>
                )}

                {allowedFieldsForType.includes(DUE_DATE) && !!dueDate ? (
                    <DueDatePicker
                        value={dueDate}
                        onChange={() => {}}
                        sx={{
                            mt: 4,
                        }}
                        isEditActive={false}
                    />
                ) : null}
                {showDetails && (
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        {allowedFieldsForType.includes(ASSIGNEES) &&
                        assignees.length > 0 ? (
                            <>
                                <Paragraph
                                    variant="labelSm"
                                    sx={{
                                        mt: 4,
                                    }}
                                >
                                    {genericTr('assignees.sidebarHeader')}
                                </Paragraph>
                                <AvatarList
                                    users={assignees}
                                    sx={{
                                        mt: 1,
                                    }}
                                    disableOverload
                                />
                            </>
                        ) : null}

                        {allowedFieldsForType.includes(TAGS) &&
                        tags.length > 0 ? (
                            <>
                                <Paragraph
                                    variant="labelSm"
                                    sx={{
                                        mt: 4,
                                    }}
                                >
                                    {genericTr('tags.sidebarHeader')}
                                </Paragraph>
                                <TagList
                                    sx={{
                                        mt: 1,
                                        width: 'auto',
                                    }}
                                    tags={[...tags].sort(alphabeticalTagSort)}
                                    disableOverload
                                />
                            </>
                        ) : null}

                        {allowedFieldsForType.includes(STATUS) && !!status ? (
                            <>
                                <Paragraph
                                    variant="labelSm"
                                    sx={{
                                        mt: 4,
                                    }}
                                >
                                    {genericTr('status.sidebarHeader')}
                                </Paragraph>
                                <StatusPicker
                                    value={status ?? ''}
                                    isEditActive={false}
                                    sx={{
                                        mt: 1,
                                        width: 'auto',
                                    }}
                                />
                            </>
                        ) : null}
                    </Flex>
                )}
                {(allowedFieldsForType.includes(ASSIGNEES) &&
                    assignees.length > 0) ||
                (allowedFieldsForType.includes(TAGS) && tags.length > 0) ||
                (allowedFieldsForType.includes(STATUS) && !!status) ? (
                    <Button
                        onClick={() => setShowDetails(prev => !prev)}
                        variant="invisible"
                        sx={{
                            alignSelf: 'flex-start',
                            mt: 6,
                        }}
                    >
                        <Text
                            variant="labelSm"
                            sx={{
                                color: 'textSecondary',
                            }}
                        >
                            {showDetails ? 'Hide details' : 'Show details'}
                        </Text>
                    </Button>
                ) : null}
            </Flex>

            <CommentContainer itemInstanceId={data._id} itemKey={data._key} />
        </Flex>
    );
};
