import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Flex, Box, IconButton, useThemeUI } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';
import Select, { GroupBase, MultiValue, OptionsOrGroups } from 'react-select';
import { Label } from './label';
import { baseStyles } from '../select/base.styles';
import { Popout } from '../popout';

import { Icon } from '@tmi/component-library';
import { AvatarList } from '../avatar-list';
import { EVENT_ASSIGN_USER } from '../../constants';
import { useTranslationPrefix, useGetTeamMembers } from '../../hooks';
import { WhiteboardContext } from '../../modules/whiteboard/containers/whiteboard.container';

export type HandleOnChange = (newValue: MultiValue<PeoplePickerOption>) => void;

interface PeoplePickerProps {
    sx?: ThemeUIStyleObject;
    onChange: (newValue: Partial<IOrgUser>[]) => void;
    value?: IOrgUser[];
    placeholder?: string;
    isEditActive: boolean;
    avatarSize?: number;
}

type PeoplePickerOption = {
    id: string;
    value: string;
    data: Partial<IOrgUser>;
    label: JSX.Element;
};

const SelectDropdownStyles = (theme: any) => ({
    container: base => ({
        ...base,
        width: '349px',
        boxShadow: theme.shadows.floatingBar,
    }),
});

export const mapToSelectUsers = users =>
    users.map(user => {
        //@TODO temporary fix for member delete
        if (!user) return null;
        const { _id, fullName, profilePicture, email } = user;
        return {
            id: _id,
            value: `${_id} - ${fullName}`,
            data: {
                _id,
                fullName,
                email,
                profilePicture,
            },
            label: (
                <Label
                    profilePicture={profilePicture}
                    fullName={fullName}
                    email={email}
                />
            ),
        };
    });

const PeoplePickerEdit = ({
    sx,
    onChange,
    value = [],
    isEditActive,
    placeholder,
    avatarSize,
}: PeoplePickerProps) => {
    const { tr } = useTranslationPrefix('whiteboard.cards.generic.assignees');
    const { data: teamMembers } = useGetTeamMembers();

    if (!teamMembers) {
        return null;
    }

    const optionsWithHeader = [
        {
            label: tr('optionsHeader'),
            options: mapToSelectUsers(teamMembers),
        },
    ];

    const handleOnChange: HandleOnChange = newValue => {
        const values = newValue.map(el => el.data);
        window.analytics.track(EVENT_ASSIGN_USER, {
            newValue,
        });
        onChange(values);
    };

    return (
        <Flex sx={{ position: 'relative', ...sx }}>
            <AvatarList users={value} avatarSize={avatarSize} />
            <SelectList
                options={optionsWithHeader}
                selected={mapToSelectUsers(value)}
                isEditActive={isEditActive}
                placeholder={placeholder || tr('placeholder')}
                handleOnChange={handleOnChange}
                target={
                    <AddUsersButton value={value} avatarSize={avatarSize} />
                }
            />
        </Flex>
    );
};

interface SelectListProps {
    isEditActive: boolean;
    placeholder: string;
    selected: PeoplePickerOption[];
    options: OptionsOrGroups<PeoplePickerOption, GroupBase<PeoplePickerOption>>;
    handleOnChange: HandleOnChange;
    target: React.ReactElement;
}
export const SelectList = ({
    isEditActive,
    placeholder,
    selected,
    options,
    target,
    handleOnChange,
}: SelectListProps) => {
    const { theme } = useThemeUI();
    const { setPanPinchDisabled } = useContext(WhiteboardContext);

    const enableZoomPanPinch = useCallback(() => {
        setPanPinchDisabled(false);
    }, [setPanPinchDisabled]);

    const disableZoomPanPinch = useCallback(() => {
        setPanPinchDisabled(true);
    }, [setPanPinchDisabled]);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!isEditActive) {
            setIsOpen(false);
        }
    }, [isEditActive]);

    return (
        <Popout
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onMenuMouseEnter={disableZoomPanPinch}
            onMenuMouseLeave={enableZoomPanPinch}
            onMenuClose={enableZoomPanPinch}
            onMenuUnmount={enableZoomPanPinch}
            target={React.cloneElement(target, {
                onClick: () => setIsOpen(true),
            })}
        >
            <Select
                autoFocus
                options={options}
                isMulti
                closeMenuOnSelect={false}
                styles={{
                    ...baseStyles(theme),
                    ...SelectDropdownStyles(theme),
                }}
                formatGroupLabel={data => (
                    <Box sx={{ mb: 2 }}>{data.label}</Box>
                )}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={selected}
                menuIsOpen={true}
            />
        </Popout>
    );
};

interface AddUsersButtonProps {
    value: any;
    onClick?: () => void;
    avatarSize?: number;
}

const AddUsersButton = ({
    value,
    onClick,
    avatarSize = 32,
}: AddUsersButtonProps) => (
    <IconButton
        sx={{
            height: avatarSize,
            width: avatarSize,
            ...(value.length > 0
                ? { color: 'textTertiary', bg: 'transparent', ml: 1 }
                : { color: 'textInverse', bg: 'actionTertiary', ml: 1 }),
        }}
        onClick={onClick}
    >
        <Icon
            iconName={value.length > 0 ? 'plus' : 'userAdd'}
            fillStyle="solid"
            size={avatarSize * 0.75}
        />
    </IconButton>
);

export const PeoplePicker = (props: PeoplePickerProps) => {
    const { isEditActive, value = [], sx, avatarSize } = props;

    if (!isEditActive && value.length === 0)
        return (
            <Box
                sx={{ height: `${avatarSize}px`, visibility: 'hidden', ...sx }}
            />
        );

    if (!isEditActive)
        return (
            <Flex sx={sx}>
                <AvatarList users={value} avatarSize={avatarSize} />
            </Flex>
        );

    return <PeoplePickerEdit {...props} />;
};
