import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Flex, Heading, Label, Paragraph, Input, Button } from 'theme-ui';
import { createItemInstance } from '../../../api';
import { createConnection, updateItem } from '../../../api/item';
import { Modal } from '../../../components/modal';
import {
    getWhiteboardQueryKey,
    ONBOARDING_DISMISSED,
    WHITEBOARD_SIZE,
} from '../../../constants';
import {
    useAppSelector,
    useGetWhiteboard,
    useTranslationPrefix,
    useWhiteboardParams,
} from '../../../hooks';
import { getUserForActiveOrg, selectActiveOrg } from '../../../redux/reducers';
import {
    ACTION_TITLE_MAX_LENGTH,
    CARD_META,
    GOAL_TITLE_MAX_LENGTH,
} from '../constants/card';
import { formatISO, addMonths } from 'date-fns';

const ConversationalOnboardingModal = ({ isOpen, onClose }) => {
    const { whiteboardKey } = useWhiteboardParams();
    const queryClient = useQueryClient();
    const { mutateAsync } = useMutation(createItemInstance);
    const { mutate } = useMutation(createConnection);
    const me = useAppSelector(getUserForActiveOrg);
    const { _key: teamKey } = useAppSelector(selectActiveOrg);
    const [goalTitle, setGoalTitle] = useState('');
    const [actionTitle, setActionTitle] = useState('');
    const { tr } = useTranslationPrefix('general.onboardingWizard');

    const handleClick = useCallback(
        async e => {
            const newGoal: INewInstance = {
                position: {
                    x:
                        WHITEBOARD_SIZE.width / 2 +
                        e.view.innerWidth / 2 -
                        CARD_META.goal.width / 2,
                    y:
                        WHITEBOARD_SIZE.height / 2 +
                        e.view.innerHeight / 2 -
                        CARD_META.goal.height / 2,
                },
                type: 'goal',
                cardScale: 1,
                title: goalTitle,
                dueDate: formatISO(addMonths(new Date(), 3)),
                status: 'onTrack',
            };

            const newAction: INewInstance = {
                position: {
                    x:
                        WHITEBOARD_SIZE.width / 2 +
                        e.view.innerWidth / 2 -
                        CARD_META.action.width / 2 +
                        400,
                    y:
                        WHITEBOARD_SIZE.height / 2 +
                        e.view.innerHeight / 2 -
                        CARD_META.action.height / 2,
                },
                type: 'action',
                cardScale: 1,
                title: actionTitle,
                dueDate: '',
                status: 'notStarted',
            };

            const goalMutation = mutateAsync({ item: newGoal, whiteboardKey });
            const actionMutation = mutateAsync({
                item: newAction,
                whiteboardKey,
            });

            const [goal, action] = await Promise.all([
                goalMutation,
                actionMutation,
            ]);

            // Create connection between goal and action
            await mutate({
                startItemId: goal._id,
                endItemId: action._id,
                whiteboardKey,
            });

            // Add current user as assignee to both cards
            await updateItem({
                teamKey,
                whiteboardKey,
                input: {
                    _id: goal._id,
                    assignees: [me],
                },
            });
            await updateItem({
                teamKey,
                whiteboardKey,
                input: {
                    _id: action._id,
                    assignees: [me],
                },
            });

            queryClient.invalidateQueries(getWhiteboardQueryKey);
            onClose();
        },
        [
            actionTitle,
            goalTitle,
            me,
            mutate,
            mutateAsync,
            onClose,
            queryClient,
            teamKey,
            whiteboardKey,
        ]
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} sx={{ p: 8 }}>
            <>
                <Heading variant="hmd">{tr('title')}</Heading>
                <Paragraph variant="md" sx={{ mt: 4 }}>
                    {tr('body')}
                </Paragraph>

                <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                    <Label>{tr('goal.label')}</Label>
                    <Input
                        sx={{ bg: 'bgPrimary', p: 4, mt: 2 }}
                        placeholder={tr('goal.placeholder')}
                        onChange={e => {
                            setGoalTitle(e.target.value);
                        }}
                        value={goalTitle}
                        maxLength={GOAL_TITLE_MAX_LENGTH}
                    />
                </Flex>

                <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                    <Label>{tr('action.label')}</Label>
                    <Input
                        sx={{ bg: 'bgPrimary', p: 4, mt: 2 }}
                        placeholder={tr('action.placeholder')}
                        onChange={e => {
                            setActionTitle(e.target.value);
                        }}
                        value={actionTitle}
                        maxLength={ACTION_TITLE_MAX_LENGTH}
                    />
                </Flex>

                <Flex sx={{ mt: 10, justifyContent: 'flex-end' }}>
                    <Button variant="secondary" onClick={onClose}>
                        {tr('actions.cancel')}
                    </Button>
                    <Button
                        onClick={handleClick}
                        sx={{ ml: 4 }}
                        disabled={!goalTitle || !actionTitle}
                    >
                        {tr('actions.submit')}
                    </Button>
                </Flex>
            </>
        </Modal>
    );
};

export const ConversationalOnboarding = () => {
    const [isOpen, setIsOpen] = useState(true);

    const onClose = useCallback(() => {
        sessionStorage.setItem(ONBOARDING_DISMISSED, 'true');
        setIsOpen(false);
    }, []);

    const isOnboardingDismissed =
        Boolean(sessionStorage.getItem(ONBOARDING_DISMISSED)) || false;
    const { data } = useGetWhiteboard();

    if (!data) {
        return null;
    } else {
        if ((data.items?.length || []) > 0 || isOnboardingDismissed) {
            return null;
        }

        return (
            <ConversationalOnboardingModal isOpen={isOpen} onClose={onClose} />
        );
    }
};
