import { IconButton, Box } from '@theme-ui/components';
import { Icon } from '@tmi/component-library';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { addElementToDom } from '../../helpers';
import { SIDEBAR_TRIGGER_BUTTON_ID } from '../../constants';
import { useSidebarAPI, useSidebarData } from '../../providers/sidebar';

const MotionIconButton = motion(IconButton);

const variants = {
    open: { x: `calc(min(${100 / 3}vw, 455px) - 24px)` },
    closed: { x: 24 },
};

export const SidebarTriggerButton = ({
    hasUnreadNotifications,
}: {
    hasUnreadNotifications: boolean;
}) => {
    const { isSidebarOpen } = useSidebarData();
    const { toggleSidebar } = useSidebarAPI();

    const node =
        document.getElementById(SIDEBAR_TRIGGER_BUTTON_ID) ??
        addElementToDom(SIDEBAR_TRIGGER_BUTTON_ID);

    return createPortal(
        <MotionIconButton
            initial={false}
            animate={isSidebarOpen ? 'open' : 'closed'}
            variants={variants}
            transition={{ ease: [0.4, 0.13, 0.4, 0.96], duration: 0.4 }}
            onClick={toggleSidebar}
            sx={{
                position: 'fixed',
                top: 6,
                height: '48px',
                width: '48px',
                bg: 'bgSecondary',
                boxShadow: 'floatingBar',
                borderRadius: 3,
            }}
        >
            <Icon
                iconName={isSidebarOpen ? 'menuClose' : 'menuOpen'}
                fillStyle="solid"
            />

            {hasUnreadNotifications && !isSidebarOpen && (
                <Box
                    sx={{
                        height: '12px',
                        width: '12px',
                        borderRadius: 5,
                        bg: 'alarming',
                        position: 'absolute',
                        top: '-4px',
                        right: '-4px',
                    }}
                />
            )}
        </MotionIconButton>,
        node
    );
};
