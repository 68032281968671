import { ThemeUIStyleObject } from 'theme-ui';

export const images: Record<string, ThemeUIStyleObject> = {
    avatar: {
        width: '100%',
        height: '100%',
        borderRadius: 99999,
        objectFit: 'cover',
    },
};
