import { Flex, Box, Text } from 'theme-ui';

interface FrameRowOverlayProps {
    index: number;
    title: string;
}

export const FrameRowOverlay = ({ index, title }: FrameRowOverlayProps) => {
    return (
        <Flex
            sx={{
                border: `1px solid`,
                borderRadius: 2,
                borderColor: 'actionSecondary',
                bg: 'bgSecondary',
                p: 4,
                my: 4,
            }}
        >
            <Flex
                sx={{
                    cursor: 'pointer',
                    alignItems: 'flex-start',
                    borderRadius: 2,
                    width: '101px',
                    height: '67px',
                    bg: 'bgPrimary',
                    mr: 4,
                }}
            >
                <Box
                    sx={{
                        borderRadius: 1,
                        m: 2,
                        bg: 'actionTertiary',
                        px: 2,
                        py: 1,
                        color: 'white',
                    }}
                >
                    {index}
                </Box>
            </Flex>

            <Text sx={{ p: 6, width: '100%' }}>{title}</Text>
        </Flex>
    );
};
