import { useEffect, useRef } from 'react';
import { usePreviousImmediate } from 'rooks';
import { Flex, Box } from 'theme-ui';
import { Icon } from '@tmi/component-library';
import { BaseCardProps } from '..';
import { TagPicker, TitleField } from '../../../../../components';
import { CardTypes, CARD_META } from '../../../constants/card';
import { useTranslationPrefix } from '../../../../../hooks';
import { CardMode } from '../../../../../types/enums';
import { alphabeticalTagSort } from '../../../../../helpers/alphabetical-sort';
import { getHighlightStyle } from '../../../../../helpers/filter/filter';
import { getEditOutlineStyle, getLockedOverlayStyle } from '../../../helpers';

interface BehaviourProps extends BaseCardProps {
    data: BehaviourData;
}

export const Behaviour = ({
    data,
    dispatchHandler,
    cardMode,
    cardScale,
    isLocked,
    isGhost,
    isHighlight,
    sx,
}: BehaviourProps) => {
    const { tr } = useTranslationPrefix('whiteboard.cards.behaviour');
    const { title, tags } = data;
    const isEditing = cardMode === CardMode.EDIT;
    const titleRef = useRef<HTMLTextAreaElement>(null);
    const { width, height } = CARD_META['behaviour'];
    const previousCardMode = usePreviousImmediate<CardMode>(cardMode);
    const hasLeftEditMode =
        previousCardMode === CardMode.EDIT && cardMode !== CardMode.EDIT;

    useEffect(() => {
        if (hasLeftEditMode) {
            titleRef?.current?.blur();
        }
    }, [isEditing, hasLeftEditMode]);

    return (
        <Box
            sx={{
                width: `${width * cardScale}px`,
                height: `${height * cardScale}px`,
            }}
        >
            <Flex
                sx={{
                    position: 'relative',
                    pt: 2,
                    pb: 4,
                    px: 4,
                    borderRadius: 5,
                    bg: 'bgSecondary',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: `${width}px`,
                    height: `${height}px`,
                    transformOrigin: 'top left',
                    transform: `scale(${cardScale})`,
                    boxShadow: 'cardShadow',
                    pointerEvents: isLocked || isGhost ? 'none' : 'auto',
                    ...getEditOutlineStyle(CardTypes.BEHAVIOUR, isEditing),
                    ...getLockedOverlayStyle(CardTypes.BEHAVIOUR, isLocked),
                    ...getHighlightStyle(isHighlight),
                    ...sx,
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        borderRadius: 5,
                        bg: 'primary',
                        boxShadow: 'pin',
                        color: 'textInverse',
                    }}
                >
                    <Icon iconName="behaviour" fillStyle="solid" size={16} />
                </Box>
                <TitleField
                    ref={titleRef}
                    value={title}
                    placeholder={tr('title.placeholder')}
                    disabled={!isEditing}
                    onChange={({ currentTarget }) =>
                        dispatchHandler('title')(currentTarget.value)
                    }
                    sx={{ textAlign: 'center', mt: 4 }}
                    variant="textareaSm"
                    maxLength={70}
                />
                <TagPicker
                    value={[...tags].sort(alphabeticalTagSort)}
                    onChange={dispatchHandler('tags')}
                    sx={{ position: 'absolute', bottom: 4, width: '150px' }}
                    isEditActive={isEditing}
                    variant="xs"
                />
            </Flex>
        </Box>
    );
};
