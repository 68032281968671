import React from 'react';
import { useHistory } from 'react-router';
import { Flex, Paragraph, Heading, Container, Button, Link } from 'theme-ui';
import { ONBOARDING_ROUTE, ORGANISATION_ROUTE } from '../../constants';
import { useTranslationPrefix } from '../../hooks';

export const NoWorkspacePage = () => {
    const history = useHistory();
    const { tr } = useTranslationPrefix('error_pages.no_workspace');

    return (
        <Flex
            sx={{
                bg: 'bgPrimary',
                height: 'inherit',
                overflowY: 'auto',
            }}
        >
            <Container sx={{ display: 'flex', flexDirection: 'column' }}>
                <Flex
                    sx={{
                        flexDirection: 'column',
                        width: '668px',
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <Heading
                        as="h2"
                        variant="hxxl"
                        sx={{ color: 'textTertiary' }}
                    >
                        {tr('title')}
                    </Heading>

                    <Paragraph sx={{ mt: 8 }}>{tr('body')}</Paragraph>
                    <Flex sx={{ mt: 8, justifyContent: 'flex-start' }}>
                        <Button
                            onClick={() => {
                                history.push(
                                    `/${ONBOARDING_ROUTE}/${ORGANISATION_ROUTE}`
                                );
                            }}
                            sx={{ mr: 4 }}
                        >
                            {tr('actions.createNew')}
                        </Button>
                        <Link variant="button" href="https://www.kinety.app">
                            {tr('actions.toWebsite')}
                        </Link>
                    </Flex>
                </Flex>
            </Container>
        </Flex>
    );
};

export default NoWorkspacePage;
