import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { createQueryKey } from '../../helpers';

export const useUpdateCache = <T>(queryNameSpace: string) => {
    const queryClient = useQueryClient();
    return useCallback(
        (res, dataIdentifier) => {
            const queryKey = createQueryKey(queryNameSpace, dataIdentifier);

            queryClient.setQueryData<T | undefined>(queryKey, previousCache => {
                if (!previousCache) {
                    return res;
                }

                if (Array.isArray(previousCache)) {
                    return [res, ...previousCache];
                } else {
                    return {
                        ...previousCache,
                        ...res,
                    };
                }
            });
        },
        // linter expects T which is a generic and cannot be passed
        // eslint-disable-next-line
        [queryClient, queryNameSpace]
    );
};
