import {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from 'react';
import Xarrow, { refType } from 'react-xarrows';
import { useOnClickOutside, useWhiteboardParams } from '../../hooks';
import { ConnectionMode } from '../../types/enums';
import { useThemeUI } from 'theme-ui';
import { DeleteElementModal } from '../../modules/whiteboard/components/delete-element-modal';
import { useDeleteConnectionMutation } from '../../modules/whiteboard/containers/connection.container';
import {
    headShapeArrow,
    tailShapeActive,
    headShapeArrowActive,
} from './arrow-shapes';
import { useKey } from 'rooks';
import { useIsFetching } from 'react-query';
import { createQueryKey } from '../../helpers';
import { itemQueryKey, ITEM_INSTANCE_ID } from '../../constants';

interface ConnectionProps {
    fromRef: refType;
    toRef: refType;
    edge?: IEdge;
    scale?: number;
    rerenderKey?: number;
    rerenderId?: string;
    isPendingConnection?: boolean;
}

const getKey = (id: string) => id.substring(`${ITEM_INSTANCE_ID}/`.length + 1);

const createItemQk = (id: refType) => {
    if (typeof id === 'string') {
        const key = getKey(id);
        return createQueryKey(itemQueryKey, key + itemQueryKey);
    }

    return '';
};

export const Connection = ({
    fromRef,
    toRef,
    edge,
    isPendingConnection = false,
}: ConnectionProps) => {
    // eslint-disable-next-line
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    const { theme } = useThemeUI();
    const gkFrom = createItemQk(fromRef);
    const gkTo = createItemQk(toRef);

    const isFetching = useIsFetching(gkFrom);
    const isFetchingTo = useIsFetching(gkTo);

    useEffect(() => {
        if (isFetching === 1 || isFetchingTo === 1) {
            forceUpdate();
        }
    }, [forceUpdate, isFetching, isFetchingTo]);

    const { mutate: deleteConnectionMutation } = useDeleteConnectionMutation();
    const { whiteboardKey } = useWhiteboardParams();

    const [connectionMode, setConnectionMode] = useState(ConnectionMode.VIEW);
    const [arrowColor, setArrowColor] = useState(
        theme!.colors!.actionPrimarySubtle as string
    );

    const [arrowShape, setArrowShape] = useState(headShapeArrow);
    const [tailShape, setTailShape] = useState<any | null>(null);
    const connectionRef = useRef<HTMLDivElement>(null!);

    const handleClick = useCallback(() => {
        setConnectionMode(ConnectionMode.EDIT);
        setArrowColor(theme!.colors!.primary as string);
        setTailShape(tailShapeActive(theme));
        setArrowShape(headShapeArrowActive(theme));
    }, [theme]);

    const handleClickOutside = useCallback(() => {
        setConnectionMode(ConnectionMode.VIEW);
        setArrowColor(theme!.colors!.actionPrimarySubtle as string);
        setArrowShape(headShapeArrow);
        setTailShape(null);
    }, [theme]);

    const handleMouseEnter = useCallback(() => {
        if (connectionMode === ConnectionMode.VIEW)
            setArrowColor(theme!.colors!.primary as string);
    }, [connectionMode, theme]);

    const handleMouseLeave = useCallback(() => {
        if (connectionMode === ConnectionMode.VIEW)
            setArrowColor(theme!.colors!.actionPrimarySubtle as string);
    }, [connectionMode, theme]);

    useOnClickOutside(connectionRef, handleClickOutside);

    const [showDeleteItemModal, setShowDelete] = useState(false);

    const handleCloseModal = () => {
        setShowDelete(false);
    };

    const handleDelete = useCallback(() => {
        if (connectionMode === ConnectionMode.EDIT) {
            setShowDelete(true);
        }
    }, [connectionMode]);

    useKey(
        ['Backspace', 'Delete'],
        () => {
            handleDelete();
        },
        {
            when: connectionMode === ConnectionMode.EDIT,
        }
    );

    const pointerEventsNone = {
        style: { pointerEvents: 'none' as any },
    };

    let arrowProps = {
        start: fromRef,
        end: toRef,
        strokeWidth: 2,
        lineColor: arrowColor,
        headShape: arrowShape,
        headColor: arrowColor,
        ...(isPendingConnection
            ? {
                  arrowBodyProps: pointerEventsNone,
                  arrowHeadProps: pointerEventsNone,
                  arrowTailProps: pointerEventsNone,
              }
            : {}),
    };

    const tailProps = {
        tailShape: tailShape,
        tailColor: arrowColor,
        showTail: true,
    };

    if (tailShape) {
        arrowProps = { ...arrowProps, ...tailProps };
    }

    const memoContainerProps = useMemo(
        () => ({
            ref: connectionRef,
        }),
        [connectionRef]
    );

    const memoPassProps = useMemo(
        () => ({
            onClick: () => handleClick(),
            onMouseEnter: () => handleMouseEnter(),
            onMouseLeave: () => handleMouseLeave(),
        }),
        [handleClick, handleMouseEnter, handleMouseLeave]
    );

    return (
        <>
            <Xarrow
                {...arrowProps}
                divContainerProps={memoContainerProps}
                passProps={memoPassProps}
            />
            {showDeleteItemModal && edge && (
                <DeleteElementModal
                    handleClose={handleCloseModal}
                    handleDelete={() =>
                        deleteConnectionMutation({
                            key: edge._key,
                            whiteboardKey: whiteboardKey,
                        })
                    }
                    type="connection"
                />
            )}
        </>
    );
};

export const ConnectionMemo = memo(Connection, (nextProps, prevProps) => {
    if (
        prevProps?.rerenderId &&
        nextProps.rerenderKey !== prevProps.rerenderKey &&
        [nextProps.fromRef as string, nextProps.toRef as string].includes(
            prevProps?.rerenderId as string
        )
    ) {
        return false;
    }
    if (nextProps.scale !== prevProps.scale) {
        return false;
    }

    return true;
});
