import React from 'react';
import { Link } from 'react-router-dom';

import { Flex, IconButton, Paragraph, Text } from '@theme-ui/components';
import { Icon } from '@tmi/component-library';

import { useGetSession, useGetSessionParticipants } from '../../../api/session';
import { AvatarList } from '../../../components/avatar-list';
import { SESSION__ADMIN, WHITEBOARD_ROUTE } from '../../../constants';
import { useDropdown, useWhiteboardParams } from '../../../hooks';

interface Props {}

export const ActiveSessionUsers: React.FC<Props> = () => {
    const { whiteboardKey } = useWhiteboardParams();
    const { data } = useGetSession();

    const { data: sessionParticipants = [] } = useGetSessionParticipants();

    const {
        Dropdown,
        dropdownProps,
        positionalRef,
        toggleDropdown,
    } = useDropdown({ placement: 'bottom-end', offset: { x: 15, y: 22 } });

    if (!sessionParticipants || sessionParticipants.length === 0) return null;

    const host = sessionParticipants.find(
        participant => participant.e.type === SESSION__ADMIN
    );

    // Filter out session leader because we display them separately
    const activeUsers: IUser[] = sessionParticipants
        .filter(participant => participant.v._id !== host?.v._id)
        .map(participant => participant.v);

    const menuActions: IDropDownMenuAction[] = [
        // {
        //     name: 'Session info',
        //     route: '/settings',
        //     icon: 'cog',
        // },
        {
            name: 'Leave session',
            route: `/${WHITEBOARD_ROUTE}/${whiteboardKey}`,
            icon: 'logout',
        },
    ];

    return (
        <Flex
            sx={{
                position: 'fixed',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                py: 3,
                pl: 4,
                pr: 2,
                borderBottomRightRadius: 3,
                borderBottomLeftRadius: 3,
                alignItems: 'center',
                bg: 'bgInverse',
            }}
        >
            {data?.title && (
                <Paragraph
                    variant="labelSm"
                    sx={{
                        color: 'textInverse',
                        mr: 4,
                    }}
                >
                    {data?.title}
                </Paragraph>
            )}
            {host && (
                <AvatarList
                    sx={{ ml: 6 }}
                    users={[
                        { ...host.v, fullName: `${host.v.fullName} (host)` },
                    ]}
                    avatarSize={32}
                />
            )}
            {activeUsers.length > 0 && (
                <AvatarList
                    sx={{ ml: 6 }}
                    users={activeUsers}
                    avatarSize={32}
                />
            )}
            <IconButton
                ref={positionalRef}
                onClick={toggleDropdown}
                sx={{
                    ml: 4,
                    borderRadius: 1,
                    color: 'textInverse',
                    p: 1,

                    '&:hover:enabled': {
                        bg: 'actionTertiary',
                    },

                    '&:focus:enabled': {
                        boxShadow: theme =>
                            `inset 0 0 0 2px ${theme.colors?.actionTertiary}`,
                        outline: 'none',
                    },

                    '&:disabled': {
                        opacity: 0.3,
                    },
                }}
            >
                <Icon iconName="dotsVertical" />
            </IconButton>

            <Dropdown {...dropdownProps} sx={{ minWidth: '150px' }}>
                <ActionDropdownContent actions={menuActions} />
            </Dropdown>
        </Flex>
    );
};

const ActionDropdownContent = ({ actions }) => {
    return (
        <>
            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }}
            >
                {actions &&
                    actions?.map(({ icon, route, name, show = true }) => (
                        <React.Fragment key={name}>
                            {show && (
                                <Link to={route}>
                                    <Flex
                                        sx={{
                                            alignItems: 'center',
                                            color: 'textPrimary',
                                            p: 2,
                                            borderRadius: 1,
                                            ':hover': {
                                                bg: 'bgPrimary',
                                            },
                                        }}
                                    >
                                        {icon && (
                                            <Icon
                                                iconName={icon}
                                                fillStyle="solid"
                                                size={16}
                                            />
                                        )}
                                        <Text variant="sm" sx={{ ml: 2 }}>
                                            {name}
                                        </Text>
                                    </Flex>
                                </Link>
                            )}
                        </React.Fragment>
                    ))}
            </Flex>
        </>
    );
};
