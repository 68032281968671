import { Button } from '@theme-ui/components';
import { useTranslationPrefix } from '../../../../../hooks';

export const View = ({ handleBack }) => {
    const { tr } = useTranslationPrefix('sidebar.sessions.view');

    return (
        <Button
            sx={{
                mt: 6,
                mx: 6,
            }}
            onClick={() => {
                handleBack();
            }}
        >
            {tr('actions.leave')}
        </Button>
    );
};
