import React, { useEffect } from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import reportWebVitals from './reportWebVitals';

import { ProvidersWrapper } from './providers';
import { AppRouter } from './routes';
import { ReduxProvider } from './redux';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import './config/i18n';
import '@tmi/ui-themes/src/fonts/GTAmerica/GTAmerica.css';

import { Toast } from './components/toast/toast';
import { CookieConsent } from './components/cookie-consent';
import {
    SIDEBAR_ID,
    SIDEBAR_TRIGGER_BUTTON_ID,
    CONTEXT_MENU_PLACEMENT_ID,
} from './constants';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  a {
      color: inherit;
      text-decoration: none;
  }

  /* Total hack 'fix' for the stupid invisible error overlay that screws the hot hot hot hot reloading */
  body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
  }
  /* Remove this when https://github.com/facebook/create-react-app/issues/11771 gets fixed for CRA */

  html, body {
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior-x: none;
  }
  
  html {
    box-sizing: border-box;
    overflow-wrap: break-word; 

  }

  *, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  #root {
    position: relative;
    z-index: 0;
    white-space: pre-line;
    height: 100%;

    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }
  }
`;

export const AppWrapper = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.REACT_APP_SEGMENT_WRITE_KEY}";;analytics.SNIPPET_VERSION="4.15.3";analytics.load("${process.env.REACT_APP_SEGMENT_WRITE_KEY}");analytics.page();}}();`;

        if (document.body) {
            document.body.appendChild(script);
        }
    }, []);

    return (
        <React.StrictMode>
            <Helmet defaultTitle="Kinety" titleTemplate="%s - Kinety">
                <meta charSet="utf-8" />
                <meta property="og:url" content={window.location.href} />
                <meta name="author" content="Kinety" />
            </Helmet>
            <ReduxProvider>
                <Router>
                    <ProvidersWrapper>
                        <GlobalStyle />
                        <AppRouter />
                        <Toast />
                        <CookieConsent />
                        <div id={SIDEBAR_ID}></div>
                        <div id={SIDEBAR_TRIGGER_BUTTON_ID}></div>
                        <div id={CONTEXT_MENU_PLACEMENT_ID}></div>
                    </ProvidersWrapper>
                </Router>
            </ReduxProvider>
        </React.StrictMode>
    );
};

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
    hydrate(<AppWrapper />, rootElement);
} else {
    render(<AppWrapper />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
