import { Dispatch, SetStateAction } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { ReactZoomPanPinchRef } from '@tmi/react-zoom-pan-pinch';
import { useGetSession } from '../../../../api/session';
import {
    EDIT_ROUTE,
    INTRO_ROUTE,
    SESSION_ROUTE,
    VIEW_ROUTE,
    WHITEBOARD_ROUTE,
} from '../../../../constants';
import { useWhiteboardParams } from '../../../../hooks';

import { Edit } from '../session/sidebar/edit/edit';
import { SessionDetails } from '../session/sidebar/session-details';

interface Props extends Pick<ReactZoomPanPinchRef, 'zoomToElement'> {
    startCreateFrame: boolean;
    setStartCreateFrame: Dispatch<SetStateAction<boolean>>;
}

export const SessionRouter = ({
    zoomToElement,
    startCreateFrame,
    setStartCreateFrame,
}: Props) => {
    const { path } = useRouteMatch();
    const { sessionKey, whiteboardKey } = useWhiteboardParams();
    const history = useHistory();

    const handleBack = () => {
        history.push(
            `/${WHITEBOARD_ROUTE}/${whiteboardKey}/${SESSION_ROUTE}/${sessionKey}/${INTRO_ROUTE}`
        );
    };

    const { data, isLoading } = useGetSession();

    if (isLoading || !data) return null;

    return (
        <Switch>
            <Route
                exact
                path={`${path}/${INTRO_ROUTE}`}
                render={() => <SessionDetails />}
            />

            <Route
                exact
                path={`${path}/${EDIT_ROUTE}`}
                render={() => (
                    <Edit
                        data={data}
                        startCreateFrame={startCreateFrame}
                        setStartCreateFrame={setStartCreateFrame}
                        zoomToElement={zoomToElement}
                        handleBack={handleBack}
                    />
                )}
            />
            <Route
                exact
                path={`${path}/${VIEW_ROUTE}`}
                render={() => <SessionDetails />}
            />
        </Switch>
    );
};
