import { useState } from 'react';
import { Box, Flex, Paragraph } from '@theme-ui/components';
import { usePopper } from 'react-popper';
import { ThemeUIStyleObject } from 'theme-ui';
import { useGetSession } from '../../../../api/session';

interface Props {
    isHost: boolean;
    activeFrame?: string;
    sx?: ThemeUIStyleObject;
    handleGoToFrame: (frameId: string) => void;
}
export const FrameTimeline = ({
    isHost,
    activeFrame,
    handleGoToFrame,
    sx,
}: Props) => {
    const { data } = useGetSession();

    const frames = data?.frames ?? [];

    return (
        <Flex
            sx={{
                ...sx,
                bg: 'bgInverse',
                alignItems: 'center',
                justifyContent: 'space-around',
                py: 4,
                px: 6,
                borderRadius: 5,
                height: '32px',
            }}
        >
            {frames.map((frame, index) => {
                const isActive = activeFrame === frame.id;
                return (
                    <SubFrame
                        onClick={handleGoToFrame}
                        key={frame.id}
                        frame={frame}
                        isActive={isActive}
                        isHost={isHost}
                        frameNumber={index + 1}
                    />
                );
            })}
        </Flex>
    );
};

interface SubFrameProps {
    frame: ISessionFrame;
    isActive: boolean;
    isHost: boolean;
    frameNumber: number;
    onClick: (frameId: string) => void;
}

const SubFrame = ({
    frame,
    isActive,
    isHost,
    frameNumber,
    onClick,
}: SubFrameProps) => {
    const [showFrameInfo, setShowFrameInfo] = useState(false);
    const offset = { x: 0, y: -4 };

    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);

    const { styles, attributes } = usePopper(ref, popperRef, {
        placement: 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [offset.x, offset.y],
                },
            },
        ],
    });

    const isInteractive = isHost || isActive;

    const handleFrameClick = (): void => {
        if (!isInteractive) return;
        onClick(frame.id);
    };

    const handleMouseOver = (): void => {
        if (!isInteractive) return;
        setShowFrameInfo(true);
    };

    const handleMouseLeave = (): void => {
        if (!isInteractive) return;
        setShowFrameInfo(false);
    };

    return (
        <Flex
            onClick={handleFrameClick}
            ref={setRef}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            sx={{
                height: '24px',
                mx: 2,
                flex: 1,
                alignItems: 'center',
                cursor: isInteractive ? 'pointer' : 'auto',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    borderRadius: 3,
                    bg: isActive ? 'actionPrimarySubtle' : 'actionSecondary',
                    opacity: isActive ? 1 : 0.3,
                    height: isActive ? '8px' : '4px',
                    width: '100%',
                }}
            />

            {showFrameInfo && (
                <Flex
                    {...attributes.popper}
                    ref={setPopperRef}
                    sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        ...styles.popper,
                    }}
                >
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            p: 1,
                            bg: 'bgSecondary',
                            borderRadius: 2,
                            boxShadow: 'floatingBar',
                        }}
                    >
                        <Paragraph variant="xs">{frame.title}</Paragraph>
                        <Box
                            sx={{
                                borderRadius: 2,
                                width: '165px',
                                height: '90px',
                                bg: 'bgPrimary',
                                mt: 1,
                            }}
                        />
                    </Flex>

                    <Paragraph
                        variant="labelXs"
                        sx={{
                            mt: 1,
                            px: 2,
                            color: 'white',
                            bg: 'actionPrimary',
                            borderRadius: 2,
                            fontWeight: 'normal',
                        }}
                    >
                        {frameNumber}
                    </Paragraph>
                </Flex>
            )}
        </Flex>
    );
};
