import React from 'react';
import { AuthProvider } from './auth';
import { QueryProvider } from './query';
import { ModalProvider } from './modals';
import { ToastProvider } from './toast';
import { ThemeProvider } from './theme';
import { FilterProvider } from './filter';
import { SidebarProvider } from './sidebar';
import { ConnectionProvider } from './connection';

export const ProvidersWrapper: React.FC = ({ children }) => {
    return (
        <AuthProvider>
            <QueryProvider>
                <ToastProvider>
                    <ModalProvider>
                        <SidebarProvider>
                            <ConnectionProvider>
                                <FilterProvider>
                                    <ThemeProvider>{children}</ThemeProvider>
                                </FilterProvider>
                            </ConnectionProvider>
                        </SidebarProvider>
                    </ModalProvider>
                </ToastProvider>
            </QueryProvider>
        </AuthProvider>
    );
};
