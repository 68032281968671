import { ReactElement } from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

interface SortableListProps {
    children: ReactElement;
    items: string[];
    onDragStart: (e: any) => void;
    onDragEnd: (e: any) => void;
    dragOverlay?: ReactElement;
}

export const SortableList = ({
    children,
    items,
    onDragStart,
    onDragEnd,
    dragOverlay,
}: SortableListProps) => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                {children}
            </SortableContext>

            {dragOverlay || null}
        </DndContext>
    );
};
