import { useCallback, useEffect } from 'react';
import { batch } from 'react-redux';
import { useHistory } from 'react-router';
import { useGetMe } from '../api';

import {
    ONBOARDING_ROUTE,
    INVITE_ROUTE,
    WAITING_ROUTE,
    WHITEBOARD_ROUTE,
} from '../constants';
import {
    selectActiveOrg,
    setActiveOrg,
    setAllTeams,
    setUser,
} from '../redux/reducers';
import { useAppDispatch, useAppSelector, useGetWhiteboardTeam } from '.';

export const useHandleRoot = (): boolean => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const organisation = useAppSelector(selectActiveOrg);
    const { data, isLoading } = useGetMe();

    const isOnboarding = window.location.pathname.includes(ONBOARDING_ROUTE);

    // If an user has been invited via a link
    // then we have to pass the invite id in our redirect
    const queryParams = window.location.search;

    const checkOnboardingRequired = useCallback(() => {
        if (isOnboarding) {
            return;
        }

        if (!data?.user) return;

        if (!data.user.isOnboarded) {
            if (queryParams && queryParams.includes('inviteId')) {
                history.push(`/${ONBOARDING_ROUTE}/${queryParams}`);
            } else {
                history.push(`/${ONBOARDING_ROUTE}`);
            }
            return;
        }
    }, [data, history, isOnboarding, queryParams]);

    const checkHasOrganisation = useCallback(() => {
        if (!data || isOnboarding) {
            return;
        }

        if (data?.user?.isOnboarded && data.organisationTeams.length === 0) {
            history.push(`/${ONBOARDING_ROUTE}/${WAITING_ROUTE}`);
        }
    }, [data, history, isOnboarding]);

    const checkIsInvited = useCallback(() => {
        //@TODO need better fix for invite callback

        if (data?.user?.isOnboarded && queryParams.includes('inviteId')) {
            history.push(`/${INVITE_ROUTE}/${queryParams}`);
        }
    }, [data, history, queryParams]);

    useEffect(() => {
        if (isLoading || !data) return;

        const { organisationTeams, whiteboards } = data;

        const orgCount = organisationTeams?.length || 0;

        // if selected organisation is a real organisation, then do nothing
        // else select a real organisation as an active organisation
        const updateActiveOrg =
            orgCount > 0 &&
            !organisationTeams?.find(({ _id }) => _id === organisation?._id);

        batch(() => {
            dispatch(
                setUser({
                    user: data.user,
                })
            );
            dispatch(
                setAllTeams({
                    teams: organisationTeams,
                })
            );

            if (updateActiveOrg) {
                dispatch(
                    setActiveOrg({
                        team: organisationTeams[orgCount - 1],
                    })
                );
            }
        });

        checkOnboardingRequired();
        checkIsInvited();
        // TODO: merge it with the error page
        //checkHasOrganisation();

        if (whiteboards && window.location.pathname === '/') {
            history.push(`/${WHITEBOARD_ROUTE}`);
        }
    }, [
        isLoading,
        data,
        dispatch,
        checkOnboardingRequired,
        checkIsInvited,
        checkHasOrganisation,
        history,
        organisation._id,
    ]);

    return isLoading;
};

export const useHandleDiffOrg = () => {
    //This is a temporaty fix for setting the organisation belonging to current whiteboard
    const { data } = useGetWhiteboardTeam();

    const { data: meData, isFetching } = useGetMe();

    const orgKeyOfWhiteboard = data?._key;

    const activeOrg = useAppSelector(selectActiveOrg);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!meData || !orgKeyOfWhiteboard || isFetching) return;

        if (orgKeyOfWhiteboard !== activeOrg._key) {
            if (!meData.organisationTeams) return;

            const newOrg = meData.organisationTeams.find(
                ({ _key }) => _key === orgKeyOfWhiteboard
            );

            if (newOrg)
                dispatch(
                    setActiveOrg({
                        team: newOrg,
                    })
                );
        }
    }, [orgKeyOfWhiteboard, activeOrg._key, dispatch, isFetching, meData]);
};
