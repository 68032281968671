import {
    Flex,
    Heading,
    Label,
    Paragraph,
    Button,
    Text,
} from '@theme-ui/components';
import { Icon } from '@tmi/component-library/src';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import {
    useGetSession,
    useGetSessionParticipants,
} from '../../../../../api/session';
import { PeoplePicker } from '../../../../../components';
import { SESSION_ROUTE, WHITEBOARD_ROUTE } from '../../../../../constants';
import { checkInSessionView } from '../../../../../helpers';
import {
    useGetTeamMembers,
    useRefetchTrigger,
    useTranslationPrefix,
    useWhiteboardParams,
} from '../../../../../hooks';

import { Intro } from './intro';
import { View } from './view';

export const SessionDetails = () => {
    const history = useHistory();
    const { tr } = useTranslationPrefix('sidebar.sessions.intro');
    const isInViewMode = checkInSessionView(window.location.pathname);

    const { whiteboardKey, sessionKey } = useWhiteboardParams();

    const { data, refetch } = useGetSession();
    const {
        data: sessionParticipants = [],
        refetch: refetchParticipants,
    } = useGetSessionParticipants();
    const { data: teamMembers } = useGetTeamMembers();

    const refetchHandler = useCallback(() => {
        refetch();
        refetchParticipants();
    }, [refetch, refetchParticipants]);

    useRefetchTrigger({
        key: sessionKey,
        scope: 'session',
        refetch: refetchHandler,
    });

    const isSessionStarted = sessionParticipants?.length > 0;

    // TODO this mapping sucks, why do we have two types of user?
    const sessionParticipantUserIds: string[] = sessionParticipants.map(
        participant => participant.v._id
    );
    const activeParticipants =
        teamMembers?.filter(member =>
            sessionParticipantUserIds.includes(member?._id)
        ) ?? [];

    const handleBack = () => {
        history.push(`/${WHITEBOARD_ROUTE}/${whiteboardKey}/${SESSION_ROUTE}`);
    };

    return (
        <Flex
            sx={{
                py: 6,
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: '100%',
                width: '100%',
            }}
        >
            <Button
                onClick={handleBack}
                variant="invisible"
                sx={{
                    display: 'flex',
                    color: 'textPrimary',
                    alignItems: 'center',
                    gap: 2,
                    ml: 6,
                }}
            >
                <Icon iconName="arrowLeft" />
                <Text variant="labelSm">{tr('actions.back')}</Text>
            </Button>
            <Heading sx={{ mt: 5, mx: 6 }}>{data?.title ?? 'No title'}</Heading>
            {data?.scheduldedAt && (
                <>
                    <Label variant="labelSm" sx={{ mt: 6, mx: 6 }}>
                        {tr('date')}
                    </Label>
                    <Paragraph
                        variant="xs"
                        sx={{
                            mt: 2,
                            px: 1,
                            py: '2px',
                            borderRadius: 1,
                            bg: 'actionSecondary',
                            mx: 6,
                        }}
                    >
                        {format(new Date(data.scheduldedAt), 'dd-MM-yyyy')}
                    </Paragraph>
                </>
            )}
            {data?.description && (
                <>
                    <Label variant="labelSm" sx={{ mt: 6, mx: 6 }}>
                        {tr('description')}
                    </Label>
                    <Paragraph variant="xs" sx={{ mt: 2, mx: 6 }}>
                        {data?.description}
                    </Paragraph>
                </>
            )}
            {/* In case we want to use this also to show participants while already in session, 
                remove the condition !isInViewMode*/}
            {isSessionStarted && !isInViewMode && (
                <>
                    <Label variant="labelSm" sx={{ mt: 6, mx: 6 }}>
                        {tr('joined')}
                    </Label>
                    <PeoplePicker
                        value={activeParticipants}
                        onChange={() => {}}
                        isEditActive={false}
                        sx={{ mt: 2, mx: 6 }}
                    />
                </>
            )}
            {isInViewMode ? (
                <View handleBack={handleBack} />
            ) : (
                <Intro isSessionStarted={isSessionStarted} />
            )}
        </Flex>
    );
};
