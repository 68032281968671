import React from 'react';
import { IndividialIconProps } from '../Icon';

export const Session = ({ fillStyle }: IndividialIconProps) => {
    const Stroke = (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.6004 3.6c-.6627 0-1.2.5372-1.2 1.2 0 .6627.5373 1.2 1.2 1.2v9.6c0 1.3255 1.0745 2.4 2.4 2.4h3.103l-1.5515 1.5514c-.4687.4687-.4687 1.2285 0 1.6971.4686.4686 1.2284.4686 1.697 0l2.7515-2.7515 2.7515 2.7515c.4686.4686 1.2284.4686 1.697 0 .4686-.4686.4686-1.2284 0-1.6971L14.8974 18h3.103c1.3255 0 2.4-1.0745 2.4-2.4V6c.6627 0 1.2-.5373 1.2-1.2 0-.6628-.5373-1.2-1.2-1.2h-16.8Zm6.6338 3.742a1.1999 1.1999 0 0 1 1.2318.0595l3.6 2.4a1.2 1.2 0 0 1 0 1.9969l-3.6 2.4A1.1999 1.1999 0 0 1 9.6004 13.2V8.4a1.2 1.2 0 0 1 .6338-1.058Z"
            />
        </svg>
    );
    const Fill = (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.6004 3.6c-.6627 0-1.2.5372-1.2 1.2 0 .6627.5373 1.2 1.2 1.2v9.6c0 1.3255 1.0745 2.4 2.4 2.4h3.103l-1.5515 1.5514c-.4687.4687-.4687 1.2285 0 1.6971.4686.4686 1.2284.4686 1.697 0l2.7515-2.7515 2.7515 2.7515c.4686.4686 1.2284.4686 1.697 0 .4686-.4686.4686-1.2284 0-1.6971L14.8974 18h3.103c1.3255 0 2.4-1.0745 2.4-2.4V6c.6627 0 1.2-.5373 1.2-1.2 0-.6628-.5373-1.2-1.2-1.2h-16.8Zm6.6338 3.742a1.1999 1.1999 0 0 1 1.2318.0595l3.6 2.4a1.2 1.2 0 0 1 0 1.9969l-3.6 2.4A1.1999 1.1999 0 0 1 9.6004 13.2V8.4a1.2 1.2 0 0 1 .6338-1.058Z"
            />
        </svg>
    );

    return fillStyle === 'outline' ? Stroke : Fill;
};
