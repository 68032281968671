import React, { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Dropdown } from '../components';
import { useOnClickOutside } from './use-on-click-outside';
import { ThemeUIStyleObject } from '@theme-ui/css';

interface DropdownComponentProps {
    children: any;
    dropdownRef: React.Ref<HTMLDivElement>;
    attributes: { [key: string]: { [key: string]: string } | undefined };
    styles: { [key: string]: React.CSSProperties };
    setPopperElement: React.Dispatch<
        React.SetStateAction<HTMLDivElement | null>
    >;
    showDropdown: boolean;
    sx?: ThemeUIStyleObject;
}

const DropdownComponent = ({
    children,
    dropdownRef,
    attributes,
    styles,
    setPopperElement,
    showDropdown,
    sx,
}: DropdownComponentProps) => {
    if (!showDropdown) {
        return null;
    }

    return (
        <div
            ref={setPopperElement}
            style={{
                ...styles.popper,
                maxHeight: 'calc(100vh - 96px)',
                borderRadius: 4,
                overflowY: 'auto',
                zIndex: 15,
            }}
            {...attributes.popper}
        >
            <Dropdown ref={dropdownRef} sx={sx}>
                {children}
            </Dropdown>
        </div>
    );
};

type PlacementType =
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'left-start'
    | 'left'
    | 'left-end';

type OffsetType = {
    x: number;
    y: number;
};

interface IUseDropdownProps {
    placement?: PlacementType;
    debug?: boolean;
    offset?: OffsetType;
}

export const useDropdown = ({
    placement = 'bottom-end',
    debug = false,
    offset = { x: 0, y: 8 },
}: IUseDropdownProps = {}) => {
    const [showDropdown, setShowDropdown] = useState(debug);
    const dropdownRef = useRef(null);
    const [referenceElement, setReferenceElement] = useState<
        HTMLButtonElement | HTMLDivElement | null
    >(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [offset.x, offset.y],
                },
            },
        ],
    });

    const openDropdown = useCallback(() => {
        setShowDropdown(true);
    }, [setShowDropdown]);

    const closeDropdown = useCallback(() => {
        setShowDropdown(false);
    }, [setShowDropdown]);

    const toggleDropdown = useCallback(() => {
        setShowDropdown(!showDropdown);
    }, [showDropdown, setShowDropdown]);

    useOnClickOutside(dropdownRef, () => closeDropdown());

    const dropdownProps = {
        showDropdown,
        dropdownRef,
        attributes,
        styles,
        setPopperElement,
        removeDefaultPadding: false,
    };

    return {
        positionalRef: setReferenceElement,
        Dropdown: DropdownComponent,
        dropdownProps,
        isDropdownOpen: showDropdown,
        openDropdown,
        closeDropdown,
        toggleDropdown,
    };
};
