import React from 'react';
import { Box, Flex, Textarea, Label, Text, ThemeUIStyleObject } from 'theme-ui';

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
    id: string;
    label: string;
    isRequired?: boolean;
    sx?: ThemeUIStyleObject;
    value?: string;
}

export const TextArea = ({
    label,
    isRequired = false,
    placeholder = '',
    id,
    as,
    ref,
    sx,
    disabled,
    value,
    maxLength,
    ...rest
}: TextAreaProps) => (
    <Flex sx={{ flexDirection: 'column', position: 'relative', ...sx }}>
        <Label htmlFor={id} sx={{ pb: 4, opacity: !!disabled ? 0.3 : 1 }}>
            {label} {isRequired && '*'}
        </Label>
        <Textarea
            value={value}
            placeholder={placeholder}
            {...rest}
            id={id}
            disabled={disabled}
            rows={3}
            maxLength={maxLength}
        />
        {!!maxLength && (
            <Box sx={{ position: 'absolute', right: 4, bottom: 4 }}>
                <Text
                    variant="xxs"
                    sx={{
                        color:
                            !value || value.length < maxLength
                                ? 'textSecondary'
                                : 'alarming',
                    }}
                >
                    {value?.length || 0} / {maxLength}
                </Text>
            </Box>
        )}
    </Flex>
);
