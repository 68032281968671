import { Flex, IconButton, Text, Divider, Box } from '@theme-ui/components';
import { Icon } from '@tmi/component-library/src';
import React from 'react';
import { Link } from 'react-router-dom';
import { AvatarList } from '../../../components/avatar-list';
import { PRO_SUBSCRIPTION_KEY } from '../../../constants';
import {
    useAppSelector,
    useCursors,
    useDropdown,
    useGetWhiteboardTeam,
    useTranslationPrefix,
    useWhiteboardParams,
} from '../../../hooks';
import { getUserForActiveOrg } from '../../../redux/reducers';
import { APPCUE_TUTORIAL_ID } from '../constants/general';
import { WhiteboardButtonWrapper } from './whiteboard-button-wrapper';

interface Props {}

export const TopRightMenu: React.FC<Props> = () => {
    const {
        Dropdown,
        dropdownProps,
        positionalRef,
        toggleDropdown,
    } = useDropdown({ placement: 'bottom-end', offset: { x: 8, y: 16 } });
    const { cursors } = useCursors();
    const { data: team } = useGetWhiteboardTeam();
    const user = useAppSelector(state => getUserForActiveOrg(state));
    const { whiteboardKey } = useWhiteboardParams();
    const { tr } = useTranslationPrefix('whiteboard.menu.actions');

    const hasProSubscription =
        team && team.subscriptionPlan === PRO_SUBSCRIPTION_KEY;

    const me = {
        profilePicture: user.profilePicture,
        fullName: user.fullName!,
        email: user.email,
    };

    const activeUsers = [
        me,
        ...cursors?.filter(cursor => cursor._id !== user._id),
    ];

    return (
        <WhiteboardButtonWrapper
            sx={{
                alignItems: 'center',
                py: 2,
                pl: 3,
                pr: 2,
                flexShrink: 0,
                pointerEvents: 'auto',
            }}
        >
            <AvatarList users={activeUsers} avatarSize={32} />
            <IconButton
                ref={positionalRef}
                onClick={toggleDropdown}
                sx={{
                    ml: 2,
                    borderRadius: 1,
                    p: 1,
                }}
            >
                <Icon iconName="dotsVertical" />
            </IconButton>

            <Dropdown {...dropdownProps} sx={{ minWidth: '150px' }}>
                <Flex
                    onClick={toggleDropdown}
                    sx={{
                        flexDirection: 'column',
                        alignItems: 'stretch',
                    }}
                >
                    <ExternalLink
                        name={tr('learn')}
                        route="https://community.kinety.app/learn-kinety-i67vpvg4"
                    />
                    <ExternalLink
                        name={tr('contact')}
                        route="https://community.kinety.app/support-requests-t4cs3pb4"
                    />
                    <ActionLink
                        name={tr('tutorial')}
                        onClick={() =>
                            (window as any).Appcues?.show(APPCUE_TUTORIAL_ID)
                        }
                    />
                    {hasProSubscription && (
                        <ExternalLink
                            name={tr('support')}
                            route="https://themainingredient.typeform.com/to/tbu1Z6VV"
                        />
                    )}
                    <Divider sx={{ borderColor: 'bgPrimary' }} />
                    <InternalLink
                        name={tr('settings')}
                        route={`/settings/${whiteboardKey}`}
                        icon="cog"
                    />
                    <InternalLink
                        name={tr('logout')}
                        route="/logout"
                        icon="logout"
                    />
                </Flex>
            </Dropdown>
        </WhiteboardButtonWrapper>
    );
};

const ExternalLink = ({ route, icon, name }: IDropDownMenuAction) => (
    <a href={route} target="_blank" rel="noreferrer">
        <VisualLink icon={icon} name={name} />
    </a>
);

const InternalLink = ({ route, icon, name }: IDropDownMenuAction) => (
    <Link to={route || '/'}>
        <VisualLink icon={icon} name={name} />
    </Link>
);

const ActionLink = ({ icon, name, onClick }: IDropDownMenuAction) => (
    <Box onClick={onClick}>
        <VisualLink icon={icon} name={name} />
    </Box>
);

const VisualLink = ({
    icon,
    name,
}: Pick<IDropDownMenuAction, 'icon' | 'name'>) => (
    <Flex
        sx={{
            alignItems: 'center',
            color: 'textPrimary',
            p: 2,
            borderRadius: 1,
            cursor: 'pointer',
            ':hover': {
                bg: 'bgPrimary',
            },
        }}
    >
        {icon && <Icon iconName={icon} fillStyle="solid" size={16} />}
        <Text variant="sm" sx={{ ml: icon ? 2 : 0, whiteSpace: 'nowrap' }}>
            {name}
        </Text>
    </Flex>
);
