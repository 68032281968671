import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { getTeamForWhiteboard } from '../../api';
import { getTeamForWhiteboardQueryKey } from '../../constants';
import { useWhiteboardParams } from '..';

export const useGetWhiteboardTeam = () => {
    const { whiteboardKey } = useWhiteboardParams();

    if (!whiteboardKey)
        throw Error('Trying to fetch team, but no whiteboard key is present');

    return useQuery<ITeam, AxiosError>(
        [getTeamForWhiteboardQueryKey, { key: whiteboardKey }],
        () => getTeamForWhiteboard({ whiteboardKey }),
        {
            enabled: !!whiteboardKey,
            retry: (failureCount, error) => {
                if (error.response?.status === 403) return false;
                if (failureCount >= 3) return false;
                return true;
            },
        }
    );
};
