import React, { useCallback, useReducer, useState } from 'react';
import { useTransformContext } from '@tmi/react-zoom-pan-pinch';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useDndMonitor } from '@dnd-kit/core';

import { ConnectionMemo } from '../../../components/connection/connection';
import { useToast, useWhiteboardParams } from '../../../hooks';
import { createConnection } from '../../../api/item';
import { deleteConnectionEdge } from '../../../api/whiteboard';
import { getWhiteboardQueryKey } from '../../../constants';

export const useCreateConnectionMutation = () => {
    const { whiteboardKey } = useWhiteboardParams();
    const queryClient = useQueryClient();
    const showToast = useToast();
    const { t } = useTranslation();

    const addResponseToCache = useCallback(
        (res: Partial<IWhiteboard>) => {
            queryClient.setQueryData<IWhiteboard | undefined>(
                [getWhiteboardQueryKey, { key: whiteboardKey }],
                prev => {
                    if (!prev) return;
                    return {
                        ...prev,
                        connections: [
                            ...(prev.connections ?? []),
                            ...(res.connections ?? []),
                        ],
                    };
                }
            );
        },
        [queryClient, whiteboardKey]
    );

    return useMutation(createConnection, {
        onSuccess: async res => {
            addResponseToCache({ connections: [res] });
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.addConnectionFail'),
            });
        },
    });
};

export const useDeleteConnectionMutation = () => {
    const { whiteboardKey } = useWhiteboardParams();
    const showToast = useToast();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const removeResponseFromCache = useCallback(
        res => {
            queryClient.setQueryData<IWhiteboard | undefined>(
                [getWhiteboardQueryKey, { key: whiteboardKey }],
                prev => {
                    if (!prev) return;
                    return {
                        ...prev,
                        connections:
                            prev.connections?.filter(
                                ({ _id }) => _id !== res._id
                            ) ?? [],
                    };
                }
            );
        },
        [queryClient, whiteboardKey]
    );

    return useMutation(deleteConnectionEdge, {
        onSuccess: async res => {
            removeResponseFromCache(res);

            return null;
        },
        onError: err => {
            showToast({
                type: 'error',
                message: t('general.toasts.deleteConnectionFail'),
            });
        },
    });
};

interface Props {
    connections?: IEdge[];
}

export const ConnectionContainer: React.FC<Props> = ({ connections }) => {
    const {
        state: { scale },
    } = useTransformContext();

    const [rerenderId, setToRerenderId] = useState<string>('');
    const [rerenderKey, forceUpdate] = useReducer(x => x + 1, 0);

    // check local drag event
    useDndMonitor({
        onDragMove: ({ active }) => {
            if (!active.id || !active.data) return;

            forceUpdate();
            setToRerenderId(active.id);
        },
    });

    return (
        <>
            {connections &&
                connections.map(connection => (
                    <ConnectionMemo
                        key={connection._from + connection._to}
                        scale={scale}
                        fromRef={connection._from}
                        toRef={connection._to}
                        edge={connection}
                        rerenderId={rerenderId}
                        rerenderKey={rerenderKey}
                    />
                ))}
        </>
    );
};
