import { Flex } from 'theme-ui';
import { ThemeUIStyleObject } from '@theme-ui/css';

interface WhiteboardButtonWrapperProps {
    children?: React.ReactElement | React.ReactElement[];
    sx?: ThemeUIStyleObject;
}
// TODO: fix styling once it has been updated by design

export const WhiteboardButtonWrapper = ({
    children,
    sx,
}: WhiteboardButtonWrapperProps) => {
    return (
        <Flex
            sx={{
                backgroundColor: 'bgSecondary',
                borderRadius: 3,
                boxShadow: 'floatingBar',
                px: 4,
                py: 3,
                ...sx,
            }}
        >
            {children}
        </Flex>
    );
};
