import { RefObject } from 'react';
import shajs from 'sha.js';

export const generateNormalizedValueFromString = (str: string) => {
    const hex = shajs('sha256')
        .update(str)
        .digest('hex');

    const lastFour = hex.substr(-4, 4);

    return parseInt(`0x${lastFour}`, 16) / parseInt('0xffff', 16);
};

export const getConsistentRandomValueFromArray = (
    str: string,
    arr: any[]
): any => arr[Math.floor(generateNormalizedValueFromString(str) * arr.length)];

export const getAdjustedCoordinates = (
    x: number,
    y: number,
    whiteboardScale: number,
    ref: RefObject<HTMLDivElement>
): { x: number; y: number } => {
    const rect = ref?.current?.getBoundingClientRect();
    if (!rect) {
        return {
            x,
            y,
        };
    }

    return {
        x: (x - rect.left) / whiteboardScale,
        y: (y - rect.top) / whiteboardScale,
    };
};
