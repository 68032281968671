import { useToast } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { pendingInvitesQueryKey } from './../constants/query-keys';
import { selectActiveOrg } from './../redux/reducers/active-org.reducer';
import { useAppSelector } from './../hooks/redux-hooks';
import { API_ROUTE_PENDING_INVITES } from './../constants/api';
import axios from 'axios';
import { EVENT_ACCEPT_INVITE, EVENT_INVITE_USER } from '../constants';

import {
    API_BASE_URL,
    API_ROUTE_INVITE,
    API_ROUTE_ACCEPT,
} from '../constants/api';
import { createQueryKey } from '../helpers';

export const inviteMember = (input: {
    emails: string[];
    teamId: string;
}): Promise<any> => {
    const { emails, teamId } = input;
    window.analytics.track(EVENT_INVITE_USER, {
        ...emails,
        teamId,
    });

    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_INVITE}`, input)
        .then(res => {
            return res.data.forEach(invite => {
                const {
                    email,
                    organiser: {
                        email: organiserEmail,
                        fullName: organiserName,
                    },
                    createdAt,
                    active,
                } = invite;

                window.analytics.identify(email, {
                    organiserEmail,
                    organiserName,
                    createdAt,
                    active,
                });
            });
        })
        .catch(err => {
            throw err;
        });
};

export const acceptInvite = ({
    inviteId,
}: {
    inviteId: string;
}): Promise<{
    team: ITeam;
    whiteboard: IWhiteboardInstance[];
}> => {
    window.analytics.track(EVENT_ACCEPT_INVITE, {
        inviteId,
    });

    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_INVITE}/${API_ROUTE_ACCEPT}`, {
            inviteId,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getOrgByInvite = (inviteId: string): Promise<ITeam> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_INVITE}/${API_ROUTE_ACCEPT}/${inviteId}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

const getPendingInvites = (teamId: string): Promise<IOrgInvitedUser[]> =>
    axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_INVITE}/${API_ROUTE_PENDING_INVITES}/${teamId}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

export const useGetPendingInvites = () => {
    const { _key: teamId } = useAppSelector(selectActiveOrg);
    const queryKey = createQueryKey(pendingInvitesQueryKey, teamId);

    return useQuery(queryKey, () => getPendingInvites(teamId), {
        enabled: !!teamId,
    });
};

const deletePendingInvite = (inviteKey: string): Promise<IOrgInvitedUser> =>
    axios
        .delete(`${API_BASE_URL}/${API_ROUTE_INVITE}/${inviteKey}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

export const useDeletePendingInvite = () => {
    const showToast = useToast();
    const { _key: teamId } = useAppSelector(selectActiveOrg);
    const queryKey = createQueryKey(pendingInvitesQueryKey, teamId);
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation((inviteKey: string) => deletePendingInvite(inviteKey), {
        onSuccess: res => {
            queryClient.setQueryData<IOrgInvitedUser[]>(
                queryKey,
                previousCache => {
                    if (!previousCache) {
                        return [];
                    }

                    return previousCache.filter(
                        invite => invite._id !== res._id
                    );
                }
            );
        },
        onError: err => {
            showToast({ type: 'error', message: t('general.toasts.general') });
        },
    });
};
