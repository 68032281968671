import { ThemeUIStyleObject } from 'theme-ui';

export const buttons: Record<string, ThemeUIStyleObject> = {
    primary: {
        variant: 'forms.labelMd',
        bg: 'actionPrimary',
        color: 'textInverse',
        px: 4,
        py: 2,
        borderRadius: 3,
        textAlign: 'center',

        '&:hover:enabled': {
            cursor: 'pointer',
            bg: 'actionPrimaryHighlight',
            color: 'textInverse',
        },

        '&:active:enabled': {
            bg: 'actionPrimaryIntense',
            color: 'textInverse',
        },

        '&:focus:enabled': {
            boxShadow: theme =>
                `0 0 0 2px ${theme.colors?.actionPrimarySubtle}`,
        },

        '&:disabled': {
            opacity: 0.3,
        },
    },
    secondary: {
        variant: 'forms.labelMd',
        bg: 'transparent',
        color: 'textTertiary',
        px: 4,
        py: 2,
        borderRadius: 3,
        cursor: 'pointer',
        textAlign: 'center',
        boxShadow: theme => `inset 0 0 0 2px ${theme.colors?.actionPrimary}`,

        '&:hover:enabled': {
            bg: 'transparent',
            boxShadow: theme =>
                `inset 0 0 0 2px ${theme.colors?.actionPrimaryHighlight}`,
        },

        '&:active:enabled': {
            bg: 'transparent',
            boxShadow: theme =>
                `inset 0 0 0 2px ${theme.colors?.actionPrimaryIntense}`,
        },

        '&:focus:enabled': {
            bg: 'actionPrimarySubtle',
            boxShadow: theme => `0 0 0 2px ${theme.colors?.actionPrimary}`,
        },

        '&:disabled': {
            opacity: 0.3,
        },
    },
    destructive: {
        variant: 'buttons.secondary',
        color: 'alarming',
        boxShadow: theme => `inset 0 0 0 2px ${theme.colors?.alarming}`,

        '&:hover:enabled': {
            bg: '#fcd0c9',
            color: '#eb2c0e',
            boxShadow: 'inset 0 0 0 2px #eb2c0e',
        },

        '&:active:enabled': {
            bg: '#fcd0c9',
            color: '#eb2c0e',
            boxShadow: 'inset 0 0 0 2px #eb2c0e',
        },

        '&:focus:enabled': {
            bg: '#fcd0c9',
            boxShadow: theme => `0 0 0 2px ${theme.colors?.alarming}`,
        },
    },
    tertiary: {
        variant: 'forms.labelMd',
        position: 'relative',
        color: 'textTertiary',
        bg: 'transparent',
        px: 0,
        py: 4,
        borderRadius: 0,
        cursor: 'pointer',
        textAlign: 'center',

        '&::after': {
            position: 'absolute',
            content: '""',
            height: '1px',
            left: 0,
            right: 0,
            bottom: 3,
            bg: 'actionPrimary',
        },

        '&:hover:enabled': {
            color: 'actionPrimaryHighlight',

            '&::after': {
                bg: 'actionPrimaryHighlight',
            },
        },

        '&:active:enabled': {
            color: 'actionPrimaryIntense',

            '&::after': {
                bg: 'actionPrimaryIntense',
            },
        },

        '&:focus:enabled': {
            outline: 'none',

            '&::after': {
                height: '2px',
            },
        },

        '&:disabled': {
            opacity: 0.3,
        },
    },
    subtle: {
        variant: 'forms.labelXs',
        color: 'textSecondary',
        bg: 'transparent',
        p: 0,
        borderRadius: 1,
        cursor: 'pointer',
        textAlign: 'center',

        '&:hover:enabled': {
            color: 'textPrimary',
        },

        '&:active:enabled': {
            color: 'actionPrimaryIntense',
        },

        '&:focus:enabled': {
            outline: '1px solid',
            outlineColor: 'backgroundPrimary',
        },

        '&:disabled': {
            opacity: 0.3,
        },
    },
    icon: {
        cursor: 'pointer',
        color: 'textPrimary',
        bg: 'transparent',
        borderRadius: 2,
        p: 0,

        '&:hover:enabled': {
            bg: 'bgPrimary',
        },

        '&:focus:enabled': {
            boxShadow: theme =>
                `inset 0 0 0 1px ${theme.colors?.actionSecondary}`,
        },

        '&:disabled': {
            opacity: 0.3,
        },
    },
    invisible: {
        p: 0,
        background: 'transparent',

        '&:hover:enabled': {
            cursor: 'pointer',
        },

        '&:disabled': {
            opacity: 0.25,
        },
    },
    dropdown: {
        px: 4,
        py: 3,
        borderRadius: 2,
        border: 'none',
        display: 'block',
        color: 'textPrimary',
        fontWeight: 'medium',
        textAlign: 'left',
        backgroundColor: 'transparent',
        '&:hover:enabled': {
            bg: 'bgPrimary',
            cursor: 'pointer',
        },
    },
    contextMenu: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 1,
        minWidth: '154px',
        bg: 'transparent',
        p: 2,
        color: 'textPrimary',
        cursor: 'pointer',
        whiteSpace: 'nowrap',

        '&:hover:enabled': {
            bg: 'actionSecondary',
        },
    },
    settingsSidebar: {
        variant: 'forms.labelSm',
        bg: 'transparent',
        color: 'textPrimary',
        textAlign: 'left',
        borderRadius: 2,

        '&:hover:enabled': {
            bg: 'transparent',
            boxShadow: theme => `0 0 0 2px ${theme.colors?.textSecondary}`,
        },

        '&:active:enabled': {
            bg: 'transparent',
            boxShadow: theme =>
                `0 0 0 2px ${theme.colors?.actionSecondaryHighlight}`,
        },

        '&:focus:enabled': {
            bg: 'transparent',
            boxShadow: theme => `0 0 0 2px ${theme.colors?.textSecondary}`,
            outline: 'none',
        },

        '&:disabled': {
            opacity: 0.3,
        },
    },
};
